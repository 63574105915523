import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { map, Observable, take, takeWhile, tap, timer } from 'rxjs';
import Player from '@vimeo/player';
import { DashboardService } from '../../../services/dashboard.service';

@Component({
    selector: 'app-dashboard-intro',
    templateUrl: './dashboard-intro.component.html',
    styleUrls: ['./dashboard-intro.component.css'],
})
export class DashboardIntroComponent implements OnInit {
    @Input('status') status;
    @ViewChild('modalPlayer') modalPlayer: ElementRef;
    @ViewChild('introPlayer') introPlayer: ElementRef;
    @ViewChild('playBtn') playBtn: ElementRef;

    @ViewChild('introModal', { static: false }) public introModal: ElementRef | undefined;
    isReadyForClose = false;
    countDown$: Observable<number>;
    counter: number;
    diff: number;
    url = 'https://player.vimeo.com/video/778127447?h=5981f0688f&autoplay=1&loop=1&background=1';

    constructor(private dashboardService: DashboardService) {}

    ngOnInit(): void {}
    ngAfterViewInit(): void {
        if (!this.status?.isIntroductionVideoPlayed) {
            this.startIntroTimer();
        } else {
            new Player(this.introPlayer.nativeElement, { url: this.url });
        }
    }
    startIntroTimer() {
        this.introModal.nativeElement.style.display = 'block';
        const player = new Player(this.modalPlayer.nativeElement, {
            url: this.url,
            autoplay: true,
        });
        new Player(this.introPlayer.nativeElement, { url: this.url });
        player.getDuration().then((data) => {
            this.counter = data * 1000;
            this.countDown$ = timer(0, 1000).pipe(
                tap((diff) => {}),
                take(this.counter),
                map(() => (this.counter = this.counter - 1000)),
                takeWhile((diff) => {
                    let isNotOver = diff >= 0;
                    if (!isNotOver) {
                        this.isIntroductionVideoPlayed();
                        this.isReadyForClose = true;
                    }
                    return isNotOver;
                })
            );
        });
        //  player.play();
    }

    isIntroductionVideoPlayed() {
        this.dashboardService.introductionVideoPlayed().pipe(take(1)).subscribe();
    }
}
