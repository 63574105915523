import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    ALTERNATIVE_HIGHEST,
    ALTERNATIVE_LOWEST,
    HIGHEST,
    LOWEST,
} from 'src/app/shared/constants/polling';

import { ICompetencyInfo } from 'src/app/shared/modules/polling/types/competencyInfo.interface';
import { IPromptAnswer } from 'src/app/shared/modules/polling/types/promptAnswer.interface';

@Component({
    selector: 'aihr-polling-colleague-mark-recall',
    templateUrl: './polling-colleague-mark-recall.component.html',
    styleUrls: ['./polling-colleague-mark-recall.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class PollingColleagueMarkRecallComponent implements OnInit {
    @Input('recalType') public recalTypeProps: string = 'best';
    @Input('competencies') public competencieProps: ICompetencyInfo[];
    @Input('answers') public answersProps: IPromptAnswer[];

    public recalMessages: string[] = [];
    constructor() {}

    public ngOnInit(): void {
        this.initializeValues();
    }

    private initializeValues(): void {
        var prompts: { [key: number]: string } = [];
        this.competencieProps.forEach((x) =>
            Object.entries(x.prompts).forEach(([key, value]) => (prompts[key] = value))
        );

        switch (this.recalTypeProps) {
            case 'best':
                var bestCount = this.answersProps.filter((x) => x.mark == HIGHEST).length;
                if (bestCount == 0) {
                    bestCount = this.answersProps.filter(
                        (x) => x.mark == ALTERNATIVE_HIGHEST
                    ).length;
                }
                this.recalMessages = this.answersProps
                    .slice(0, bestCount)
                    .map((x) => prompts[x.id]);
                break;

            case 'worst':
                var nullCount = this.answersProps.filter((x) => x.mark == null).length;
                var worstCount = this.answersProps.filter((x) => x.mark == LOWEST).length;
                if (worstCount == 0) {
                    worstCount = this.answersProps.filter(
                        (x) => x.mark == ALTERNATIVE_LOWEST
                    ).length;
                }
                if (worstCount == 0) {
                    break;
                }
                if (worstCount == 0) {
                    break;
                }
                if (nullCount == 0) {
                    this.recalMessages = this.answersProps
                        .slice(-worstCount)
                        .map((x) => prompts[x.id]);
                } else {
                    this.recalMessages = this.answersProps
                        .slice(this.answersProps.length - nullCount - worstCount, -nullCount)
                        .map((x) => prompts[x.id]);
                }
                break;

            default:
                break;
        }
    }
}
