<div class="style-item polling-table">
    <div class="rounded-top polling-table__title">
        <p class="text-center fw-semibold m-0">{{ competencyProps.name }}</p>
    </div>
    <div class="polling-table__body d-flex flex-column">
        <div class="scale d-flex mb-2">
            <div class="scale__item">
                <p class="scale__item-title text-start pb-2 m-0">
                    {{ 'Scale.Novice' | translate }}
                </p>
                <span class="scale__item-color scale__item-color_novice"></span>
            </div>
            <div class="scale__item">
                <p class="scale__item-title text-center pb-2 m-0">
                    {{ 'Scale.Proficient' | translate }}
                </p>
                <span class="scale__item-color scale__item-color_proficient"></span>
            </div>
            <div class="scale__item">
                <p class="scale__item-title text-end pb-2 m-0">{{ 'Scale.Expert' | translate }}</p>
                <span class="scale__item-color scale__item-color_expert"></span>
            </div>
        </div>
        <div class="polling-table__body-item">
            <div *ngFor="let prompt of competencyProps.prompts | keyvalue">
                <div
                    class="polling-table-row d-flex justify-content-start align-items-center pt-3 pb-3"
                >
                    <div class="polling-table-row__title">
                        <p
                            [class.polling-table-row__title_error]="
                                errorMessage != null && !promptAnswers[prompt.key].mark
                            "
                        >
                            {{ prompt.value }}
                        </p>
                    </div>
                    <div class="mark-wrap">
                        <input
                            type="radio"
                            class="btn-check"
                            [name]="'prompt_' + prompt.key"
                            [id]="'prompt_' + prompt.key + '_1'"
                            value="1"
                            [(ngModel)]="promptAnswers[prompt.key].mark"
                            autocomplete="off"
                        />
                        <label
                            class="btn polling-table-row__radio fw-bolder"
                            [for]="'prompt_' + prompt.key + '_1'"
                            >1</label
                        >

                        <input
                            type="radio"
                            class="btn-check"
                            [name]="'prompt_' + prompt.key"
                            [id]="'prompt_' + prompt.key + '_2'"
                            value="2"
                            [(ngModel)]="promptAnswers[prompt.key].mark"
                            autocomplete="off"
                        />
                        <label
                            class="btn polling-table-row__radio fw-bolder"
                            [for]="'prompt_' + prompt.key + '_2'"
                            >2</label
                        >

                        <input
                            type="radio"
                            class="btn-check"
                            [name]="'prompt_' + prompt.key"
                            [id]="'prompt_' + prompt.key + '_3'"
                            value="3"
                            [(ngModel)]="promptAnswers[prompt.key].mark"
                            autocomplete="off"
                        />
                        <label
                            class="btn polling-table-row__radio fw-bolder"
                            [for]="'prompt_' + prompt.key + '_3'"
                            >3</label
                        >

                        <input
                            type="radio"
                            class="btn-check"
                            [name]="'prompt_' + prompt.key"
                            [id]="'prompt_' + prompt.key + '_4'"
                            value="4"
                            [(ngModel)]="promptAnswers[prompt.key].mark"
                            autocomplete="off"
                        />
                        <label
                            class="btn polling-table-row__radio fw-bolder"
                            [for]="'prompt_' + prompt.key + '_4'"
                            >4</label
                        >

                        <input
                            type="radio"
                            class="btn-check"
                            [name]="'prompt_' + prompt.key"
                            [id]="'prompt_' + prompt.key + '_5'"
                            value="5"
                            [(ngModel)]="promptAnswers[prompt.key].mark"
                            autocomplete="off"
                        />
                        <label
                            class="btn polling-table-row__radio fw-bolder"
                            [for]="'prompt_' + prompt.key + '_5'"
                            >5</label
                        >

                        <input
                            type="radio"
                            class="btn-check"
                            [name]="'prompt_' + prompt.key"
                            [id]="'prompt_' + prompt.key + '_6'"
                            value="6"
                            [(ngModel)]="promptAnswers[prompt.key].mark"
                            autocomplete="off"
                        />
                        <label
                            class="btn polling-table-row__radio fw-bolder"
                            [for]="'prompt_' + prompt.key + '_6'"
                            >6</label
                        >

                        <input
                            type="radio"
                            class="btn-check"
                            [name]="'prompt_' + prompt.key"
                            [id]="'prompt_' + prompt.key + '_7'"
                            value="7"
                            [(ngModel)]="promptAnswers[prompt.key].mark"
                            autocomplete="off"
                        />
                        <label
                            class="btn polling-table-row__radio fw-bolder"
                            [for]="'prompt_' + prompt.key + '_7'"
                            >7</label
                        >
                    </div>
                    <div class="polling-table__btn">
                        <input
                            type="radio"
                            class="btn-check"
                            [name]="'prompt_' + prompt.key"
                            [id]="'prompt_' + prompt.key + '_8'"
                            value="null"
                            [(ngModel)]="promptAnswers[prompt.key].mark"
                            autocomplete="off"
                        />
                        <label
                            class="polling-table-row__btn btn fw-bolder"
                            [for]="'prompt_' + prompt.key + '_8'"
                            >Can't say</label
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between flex-wrap">
            <div class="col-6 error-message mb-4">
                <div *ngIf="errorMessage" class="error-message__item d-flex fw-bolder">
                    <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect y="0.5" width="20" height="20" rx="10" fill="#E32D34" />
                        <g clip-path="url(#clip0_163_254)">
                            <path
                                d="M10.0049 5.5C9.59106 5.5 9.25565 5.83541 9.25565 6.24921L9.38055 12.6171C9.38055 12.9619 9.66004 13.2414 10.0049 13.2414C10.3497 13.2414 10.6292 12.9619 10.6292 12.6171L10.7541 6.24921C10.7541 5.83541 10.4186 5.5 10.0048 5.5H10.0049ZM10.0049 13.7408C9.52219 13.7408 9.13086 14.1321 9.13086 14.6148C9.13086 15.0975 9.52219 15.4888 10.0049 15.4888C10.4875 15.4888 10.8789 15.0975 10.8789 14.6148C10.8789 14.1321 10.4875 13.7408 10.0049 13.7408Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_163_254">
                                <rect
                                    width="10"
                                    height="10"
                                    fill="white"
                                    transform="translate(5 5.5)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                    <p class="m-0 px-2">{{ errorMessage }}</p>
                </div>
            </div>
            <div class="scale d-flex align-self-start">
                <div class="scale__item">
                    <span class="scale__item-color scale__item-color_novice mb-2"></span>
                    <p class="scale__item-title text-start m-0">{{ 'Scale.Novice' | translate }}</p>
                </div>
                <div class="scale__item">
                    <span class="scale__item-color scale__item-color_proficient mb-2"></span>
                    <p class="scale__item-title text-center m-0">
                        {{ 'Scale.Proficient' | translate }}
                    </p>
                </div>
                <div class="scale__item">
                    <span class="scale__item-color scale__item-color_expert mb-2"></span>
                    <p class="scale__item-title text-end m-0">{{ 'Scale.Expert' | translate }}</p>
                </div>
            </div>
        </div>
        <div class="btn-wrap d-flex align-items-center justify-content-between gap-3">
            <div class="progress-wrap align-self-end">
                <div class="text-center progress-title">
                    <p class="text-center fw-bolder pb-2 m-0">
                        {{ 'Polling.Progress' | translate }}
                    </p>
                </div>
                <div class="progress rounded-pill aihr-progress">
                    <div
                        class="progress-bar aihr-ft-dark fw-semibold"
                        role="progressbar"
                        [style.width.%]="progress"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
            </div>
            <button
                class="btn-main btn btn-warning align-self-end"
                type="button"
                (click)="onNext()"
            >
                {{ 'Polling.Next' | translate }}
            </button>
        </div>
    </div>
</div>
