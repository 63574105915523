import { createAction, props } from '@ngrx/store';
import { IManagerStatusInfo } from '../../types/managerStatusInfo';

import { ActionTypes } from '../actionTypes';

export const getB2bStatusAction = createAction(ActionTypes.GET_B2B_STATUS);

export const getB2bStatusSuccessAction = createAction(
    ActionTypes.GET_B2B_STATUS_SUCCESS,
    props<{ b2bStatus: IManagerStatusInfo | null }>()
);
