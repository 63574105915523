import { createAction, props } from '@ngrx/store';

import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import { IPollingInfo } from '../../types/pollingInfo.interface';
import { ActionTypes } from '../actionTypes';

export const getPollingAction = createAction(
    ActionTypes.GET,
    props<{ invite: string | null }>()
);

export const getPollingSuccessAction = createAction(
    ActionTypes.GET_SUCCESS,
    props<{ info: IPollingInfo | null }>()
);
