<div
    class="offcanvas sidebar offcanvas-end sidebar-right"
    tabindex="-1"
    id="offcanvas"
    #showForm
    data-bs-keyboard="false"
    data-bs-backdrop="false"
>
    <div class="sidebar">
        <div class="sidebar__header d-grid justify-content-end">
            <button
                type="button"
                class="sidebar__btn"
                aria-label="Close"
                #closeButton
                data-bs-dismiss="offcanvas"
            >
                {{ 'Dashboard.Overlay.Close' | translate }}
            </button>
        </div>
        <aihr-dashboard-overlay [isB2b]="true"></aihr-dashboard-overlay>
    </div>
</div>

<div class="container-fluid">
    <aihr-global-error *ngIf="(errors$ | async)?.isServerAvalible == false"></aihr-global-error>
    <div *ngIf="(errors$ | async)?.isServerAvalible != false" class="container dashboard-page">
        <div class="container">
            <div class="main-banner" *ngIf="!status">
                <div class="row align-items-center flex-lg-nowrap flex-md-wrap">
                    <div
                        class="main-banner__icon d-flex justify-content-lg-start justify-content-sm-center justify-content-center col-lg-2 col-md-4 col-sm-12 mb-lg-0 mb-3 p-0"
                    >
                        <img src="/assets/images/main-banner-logo.webp" alt="" />
                    </div>
                    <div class="col-lg-6 col-md-7 col-sm-12 p-0">
                        <div class="main-banner__title-wrap">
                            <p class="main-banner__label fw-semibold">
                                <span class="main-banner__label_color fw-bolder">HRI</span
                                >ntelligence 360 Assessment
                            </p>
                            <p class="main-banner__title fw-semibold m-0">
                                {{ 'B2B.Banner.Start.Message1' | translate }}
                            </p>
                            <p class="main-banner__description fw-normal m-0">
                                {{ 'B2B.Banner.Start.Message2' | translate }}
                            </p>
                        </div>
                    </div>
                    <div
                        class="main-banner__wrap-btn d-flex justify-content-lg-end justify-content-md-center justify-content-center col-lg-4 col-md-12 mt-lg-0 mt-sm-3 mt-3"
                    >
                        <button
                            class="btn btn-lg btn-main"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvas"
                            role="button"
                        >
                            {{ 'Dashboard.Banner.Start.StartNow' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="main-banner main-banner-wait container" *ngIf="status">
                <div class="main-banner-wait__wrap">
                    <div
                        class="row align-items-center flex-lg-nowrap flex-md-wrap justify-content-lg-start justify-content-md-between justify-content-sm-center"
                    >
                        <div
                            class="main-banner__icon d-flex justify-content-lg-start justify-content-sm-center justify-content-center col-lg-5 col-md-4 col-sm-8 mb-lg-0 mb-3 p-0"
                        >
                            <img src="/assets/images/waiting-pana.webp" alt="" />
                        </div>
                        <div class="col-lg-7 col-md-7 col-sm-12 p-0 align-self-start">
                            <div class="main-banner__title-wrap">
                                <p class="main-banner__label fw-semibold">
                                    <span class="main-banner__label_color fw-bolder">HRI</span
                                    >ntelligence 360 Assessment
                                </p>
                                <p class="main-banner__title fw-semibold m-0">
                                    {{ 'B2B.Banner.Wait.Message1' | translate }}
                                </p>
                                <p class="main-banner__description fw-normal m-0">
                                    {{ 'B2B.Banner.Wait.Message2' | translate }}
                                </p>
                                <div class="main-banner-wait__countdown-wrap d-flex">
                                    <div class="answered d-flex flex-column text-center ms-10">
                                        <div class="answered__quantity">
                                            <span>{{ status.completedCount }}</span
                                            >/<span>{{ status.membersCount }}</span>
                                        </div>
                                        <p class="answered__title m-0">
                                            {{ 'B2B.Banner.Wait.Completed' | translate }}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="main-banner__wrap-btn d-flex justify-content-md-start justify-content-center col-lg- col-md-12 mt-lg-0 mt-sm-3 mt-3"
                                >
                                    <button
                                        class="btn btn-lg btn-main"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvas"
                                        role="button"
                                    >
                                        {{ 'Dashboard.Banner.Wait.AddInvite' | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-grid gap-5">
                <ng-container *ngIf="status?.isReady">
                    <div>
                        <div class="main-title-wrap">
                            <h1 class="main-title fw-bold">{{ 'B2B.Header' | translate }}</h1>
                        </div>
                        <div class="ankors d-flex flex-wrap">
                            <a
                                (click)="scrollAnchor('kpi')"
                                class="btn ankors__item section-label"
                                >{{ 'B2B.kPi.Header' | translate }}</a
                            >
                            <a
                                (click)="scrollAnchor('summary')"
                                class="btn ankors__item section-label"
                                >{{ 'Dashboard.Summary.Link' | translate }}</a
                            >
                            <!-- <a class="btn ankors__item section-label">{{
                                'Dashboard.ResultsOverview.Link' | translate
                            }}</a> -->
                            <!-- <a class="btn ankors__item section-label">{{
                                'Dashboard.BlindSpots.Link' | translate
                            }}</a> -->
                            <a
                                (click)="scrollAnchor('highest&LowestPrompts')"
                                class="btn ankors__item section-label"
                                >{{ 'Dashboard.Highest&LowestPrompts.Header' | translate }}</a
                            >
                            <a
                                (click)="scrollAnchor('strengthsWeaknesses')"
                                class="btn ankors__item section-label"
                                >{{ 'B2B.StrengthsWeaknesses.Link' | translate }}</a
                            >
                        </div>
                    </div>
                    <div class="section section-filter">
                        <div class="d-flex title-wrap">
                            <h4 class="title-section">{{ 'B2B.Filter.Header' | translate }}</h4>
                            <hr class="hr-dashboard" />
                        </div>
                        <div class="d-flex align-items-end section-share__wrap-item">
                            <div class="section-filter__select-wrap">
                                <ng-select
                                    [items]="specNames"
                                    bindLabel="name"
                                    bindValue="id"
                                    [(ngModel)]="b2bSpecificationId"
                                    placeholder="Select functional specialization"
                                    (change)="changeSpecialization()"
                                >
                                </ng-select>
                            </div>
                        </div>
                        <span id="kpi"></span>
                    </div>
                    <div class="section section-kpi">
                        <div class="d-flex title-wrap">
                            <h4 class="title-section">{{ 'B2B.kPi.Header' | translate }}</h4>
                            <button
                                (click)="isOpenKpiTooltip = !isOpenKpiTooltip"
                                class="tooltip-item d-flex align-items-center justify-content-center"
                            >
                                {{ 'Dashboard.TooltipLabel' | translate }}
                            </button>
                            <hr class="hr-dashboard" />
                            <div *ngIf="isOpenKpiTooltip" class="tooltip-message style-item">
                                <div (click)="isOpenKpiTooltip = false" class="tooltip-close"></div>
                                <div
                                    class="d-flex flex-column"
                                    [innerHtml]="'B2B.TooltipDetailsText.KPI' | translate"
                                ></div>
                            </div>
                        </div>
                        <ng-container *ngTemplateOutlet="kpiTemplate"></ng-container>
                    </div>
                    <div class="section">
                        <span id="summary"></span>
                        <div class="section section-filter">
                            <div class="d-flex title-wrap">
                                <h4 class="title-section">
                                    {{ 'Dashboard.Summary.Header' | translate }}
                                </h4>
                                <button
                                    (click)="isOpenSummaryTooltip = !isOpenSummaryTooltip"
                                    class="tooltip-item d-flex align-items-center justify-content-center"
                                >
                                    {{ 'Dashboard.TooltipLabel' | translate }}
                                </button>
                                <hr class="hr-dashboard" />
                                <div
                                    *ngIf="isOpenSummaryTooltip"
                                    class="tooltip-message style-item"
                                >
                                    <div
                                        (click)="isOpenSummaryTooltip = false"
                                        class="tooltip-close"
                                    ></div>
                                    <div
                                        class="d-flex flex-column"
                                        [innerHtml]="'B2B.TooltipDetailsText.Summary' | translate"
                                    ></div>
                                </div>
                            </div>
                            <span id="kpi"></span>
                        </div>
                        <aihr-dashboard-summary [isB2b]="true"></aihr-dashboard-summary>
                        <span id="skill-matrix"></span>
                    </div>
                    <div class="section">
                        <span id="highest&LowestPrompts"></span>
                        <div class="d-flex title-wrap">
                            <h4 class="title-section">
                                {{ 'Dashboard.Highest&LowestPrompts.Header' | translate }}
                            </h4>
                            <button
                                (click)="
                                    isOpenHighestAndLowestPromptsTooltip =
                                        !isOpenHighestAndLowestPromptsTooltip
                                "
                                class="tooltip-item d-flex align-items-center justify-content-center"
                            >
                                {{ 'Dashboard.TooltipLabel' | translate }}
                            </button>
                            <hr class="hr-dashboard" />
                            <div
                                *ngIf="isOpenHighestAndLowestPromptsTooltip"
                                class="tooltip-message style-item"
                            >
                                <div
                                    (click)="isOpenHighestAndLowestPromptsTooltip = false"
                                    class="tooltip-close"
                                ></div>
                                <div
                                    class="d-flex flex-column"
                                    [innerHtml]="
                                        'B2B.TooltipDetailsText.Highest&LowestPrompts' | translate
                                    "
                                ></div>
                            </div>
                        </div>
                        <aihr-dashboard-highest-and-lowest></aihr-dashboard-highest-and-lowest>
                    </div>
                    <div class="section">
                        <div class="d-flex title-wrap">
                            <h4 class="title-section">
                                {{ 'B2B.StrengthsWeaknesses.Header' | translate }}
                            </h4>
                            <button
                                (click)="
                                    isOpenStrengthsWeaknessesTooltip =
                                        !isOpenStrengthsWeaknessesTooltip
                                "
                                class="tooltip-item d-flex align-items-center justify-content-center"
                            >
                                {{ 'Dashboard.TooltipLabel' | translate }}
                            </button>
                            <hr class="hr-dashboard" />
                            <div
                                *ngIf="isOpenStrengthsWeaknessesTooltip"
                                class="tooltip-message style-item"
                            >
                                <div
                                    (click)="isOpenStrengthsWeaknessesTooltip = false"
                                    class="tooltip-close"
                                ></div>
                                <div
                                    class="d-flex flex-column"
                                    [innerHtml]="
                                        'B2B.TooltipDetailsText.StrengthsWeaknesses' | translate
                                    "
                                ></div>
                            </div>
                        </div>
                        <app-strengths-and-weaknesses></app-strengths-and-weaknesses>
                        <span id="strengthsWeaknesses"></span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!status?.isReady">
                    <div class="skeleton container">
                        <div class="skeleton_color" style="width: 330px; height: 49px"></div>
                        <div class="skeleton__ancors-wrap d-flex flex-row">
                            <div class="skeleton_color skeleton__ancors-item"></div>
                            <div class="skeleton_color skeleton__ancors-item"></div>
                            <div class="skeleton_color skeleton__ancors-item"></div>
                            <div class="skeleton_color skeleton__ancors-item"></div>
                        </div>
                        <ng-container *ngTemplateOutlet="kpiTemplate"></ng-container>
                        <div
                            class="skeleton_color skeleton__title-section"
                            style="margin-top: 50px"
                        ></div>
                        <div class="skeleton-section d-flex justify-content-between">
                            <div class="skeleton-section__block">
                                <div
                                    class="skeleton_color"
                                    style="width: 170px; height: 19px"
                                ></div>
                                <div
                                    class="skeleton-section__item d-flex flex-column align-items-end"
                                >
                                    <div
                                        class="skeleton-section__label-wrap d-flex justify-content-between mb-2"
                                    >
                                        <div
                                            class="skeleton_color skeleton-section__label"
                                            style="width: 100px"
                                        ></div>
                                        <div
                                            class="skeleton_color skeleton-section__label"
                                            style="width: 60px"
                                        ></div>
                                    </div>
                                    <div class="d-flex flex-row" style="width: 100%; height: 100%">
                                        <div
                                            class="d-flex flex-column skeleton-section__wrap-legend justify-content-between"
                                        >
                                            <div
                                                class="skeleton_color"
                                                style="width: 60px; height: 12px"
                                            ></div>
                                            <div
                                                class="skeleton_color"
                                                style="width: 60px; height: 12px"
                                            ></div>
                                            <div
                                                class="skeleton_color"
                                                style="width: 60px; height: 12px"
                                            ></div>
                                        </div>
                                        <div
                                            class="d-flex flex-column justify-content-between skeleton-section__wrap-legend"
                                        >
                                            <div
                                                class="skeleton_color"
                                                style="width: 4px; height: 116px"
                                            ></div>
                                            <div
                                                class="skeleton_color"
                                                style="width: 4px; height: 116px"
                                            ></div>
                                            <div
                                                class="skeleton_color"
                                                style="width: 4px; height: 116px"
                                            ></div>
                                        </div>
                                        <div
                                            class="d-flex flex-row align-items-end skeleton-section__wrap-bar"
                                        >
                                            <div
                                                class="d-flex flex-column align-items-center skeleton-section__bar"
                                            >
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 20px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 60px; height: 140px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                            </div>
                                            <div
                                                class="d-flex flex-column align-items-center skeleton-section__bar"
                                            >
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 20px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 60px; height: 190px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                            </div>
                                            <div
                                                class="d-flex flex-column align-items-center skeleton-section__bar"
                                            >
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 20px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 60px; height: 240px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                            </div>
                                            <div
                                                class="d-flex flex-column align-items-center skeleton-section__bar"
                                            >
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 20px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 60px; height: 268px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                            </div>
                                            <div
                                                class="d-flex flex-column align-items-center skeleton-section__bar"
                                            >
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 20px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 60px; height: 300px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                            </div>
                                            <div
                                                class="d-flex flex-column align-items-center skeleton-section__bar"
                                            >
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 20px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 60px; height: 230px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                                <div
                                                    class="skeleton_color"
                                                    style="width: 50px; height: 12px"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="skeleton-section__block">
                                <div
                                    class="skeleton_color"
                                    style="width: 170px; height: 19px"
                                ></div>
                                <div
                                    class="skeleton-section__item skeleton-section__item-small d-flex flex-column justify-content-between"
                                >
                                    <div class="skeleton-item-small__block">
                                        <div class="d-flex skeleton-item-small__row">
                                            <div
                                                class="skeleton_color skeleton-item-small__mark"
                                            ></div>
                                            <div class="d-flex flex-column justify-content-between">
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="d-flex skeleton-item-small__row">
                                            <div
                                                class="skeleton_color skeleton-item-small__mark"
                                            ></div>
                                            <div class="d-flex flex-column justify-content-between">
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="d-flex skeleton-item-small__row">
                                            <div
                                                class="skeleton_color skeleton-item-small__mark"
                                            ></div>
                                            <div class="d-flex flex-column justify-content-between">
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="skeleton-item-small__block">
                                        <div class="d-flex skeleton-item-small__row">
                                            <div
                                                class="skeleton_color skeleton-item-small__mark"
                                            ></div>
                                            <div class="d-flex flex-column justify-content-between">
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="d-flex skeleton-item-small__row">
                                            <div
                                                class="skeleton_color skeleton-item-small__mark"
                                            ></div>
                                            <div class="d-flex flex-column justify-content-between">
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="d-flex skeleton-item-small__row">
                                            <div
                                                class="skeleton_color skeleton-item-small__mark"
                                            ></div>
                                            <div class="d-flex flex-column justify-content-between">
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                                <div
                                                    class="skeleton_color skeleton-item-small__descriptions"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #deleteMember let-modal>
    <section class="pop-up-delete-user">
        <aihr-error-message *ngIf="errors$ | async" [errors]="errors$ | async"></aihr-error-message>
        <div
            class="pop-up-delete-user__close-btn"
            aria-label="Close"
            ngbAutofocus
            (click)="modal.dismiss()"
        ></div>
        <div class="delete">
            <h2 class="pop-up-delete-user__title">Delete this user?</h2>
            <p class="pop-up-delete-user__subtitle mt-3">
                Are you sure you want to delete this user?
            </p>
        </div>
        <div class="pop-up-delete-user__btn-wrap d-flex justify-content-between">
            <button class="btn-delete btn-delete_no" (click)="modal.dismiss()">No</button>
            <button class="btn-delete btn-delete_yes" (click)="modal.close(true)">Yes</button>
        </div>
    </section>
</ng-template>

<ng-template #kpiTemplate>
    <div *ngIf="kpiInfo">
        <div class="section-kpi__wrap d-flex flex-row justify-content-between gap-4">
            <ul class="mark-container d-flex flex-column justify-content-between style-item">
                <li class="d-flex mark-container__item align-items-center">
                    <span class="mark">{{ kpiInfo.invitedColleguesCount }}</span
                    ><span class="mark-legend">Invited Users</span>
                    <div class="section-kpi__btn d-flex" (click)="showStatusList = true">
                        <img src="/assets/images/svg/click-to-see.svg" alt="icon-click-to-see" />
                        <span>Click to see breakdown</span>
                    </div>
                </li>
                <li class="d-flex mark-container__item align-items-center">
                    <span class="mark">{{ kpiInfo.completedAssessmentsCount }}</span
                    ><span class="mark-legend">Completed 360 Assessments</span>
                </li>
                <li class="d-flex mark-container__item align-items-center" *ngIf="!status?.isReady">
                    <span
                        class="skeleton_color"
                        style="width: 40px; height: 32px; margin-right: 10px"
                    ></span
                    ><span class="skeleton_color" style="width: 200px; height: 20px"></span>
                </li>
                <li class="d-flex mark-container__item align-items-center" *ngIf="status?.isReady">
                    <span class="mark">{{
                        kpiInfo.averageColleguesFeedbacksCount | number : '1.1-1'
                    }}</span
                    ><span class="mark-legend"
                        >Average number of colleagues who provided feedback</span
                    >
                </li>
            </ul>
            <ul class="mark-container d-flex flex-column justify-content-between style-item">
                <li class="d-flex mark-container__item align-items-center" *ngIf="!status?.isReady">
                    <span
                        class="skeleton_color"
                        style="width: 40px; height: 32px; margin-right: 10px"
                    ></span
                    ><span class="skeleton_color" style="width: 200px; height: 20px"></span>
                </li>
                <li class="d-flex mark-container__item" *ngIf="status?.isReady">
                    <span class="mark mark_expert">{{
                        kpiInfo.averageHighScoredCompetenciesCount | number : '1.1-1'
                    }}</span>
                    <span class="mark-legend"
                        >Average number of competencies scored as high (incl specialization)</span
                    >
                </li>

                <li class="d-flex mark-container__item align-items-center" *ngIf="!status?.isReady">
                    <span
                        class="skeleton_color"
                        style="width: 40px; height: 32px; margin-right: 10px"
                    ></span
                    ><span class="skeleton_color" style="width: 200px; height: 20px"></span>
                </li>
                <li class="d-flex mark-container__item" *ngIf="status?.isReady">
                    <span class="mark mark_proficient">{{
                        kpiInfo.averageMiddleScoredCompetenciesCount | number : '1.1-1'
                    }}</span>
                    <span class="mark-legend"
                        >Average number of competencies scored as average (incl
                        specialization)</span
                    >
                </li>

                <li class="d-flex mark-container__item align-items-center" *ngIf="!status?.isReady">
                    <span
                        class="skeleton_color"
                        style="width: 40px; height: 32px; margin-right: 10px"
                    ></span
                    ><span class="skeleton_color" style="width: 200px; height: 20px"></span>
                </li>
                <li class="d-flex mark-container__item" *ngIf="status?.isReady">
                    <span class="mark mark_novice">{{
                        kpiInfo.averageLowScoredCompetenciesCount | number : '1.1-1'
                    }}</span
                    ><span class="mark-legend"
                        >Average number of competencies scored as low (incl specialization)</span
                    >
                </li>
            </ul>
        </div>
        <div class="section section-kpi" *ngIf="showStatusList">
            <div
                class="style-item kpi-table d-grid gap-4"
                *ngIf="showStatusList && kpiInfo?.invitedColleguesCount"
            >
                <div class="kpi-table__close-btn" (click)="showStatusList = false"></div>
                <div
                    class="kpi-table__item"
                    *ngIf="invitedList && invitedList[b2bStatus.notStarted]?.length"
                >
                    <div class="kpi-table__header d-flex">
                        <p class="kpi-table__header-item kpi-table__row-mail m-0">Email:</p>
                        <p class="kpi-table__header-item m-0">Status:</p>
                    </div>
                    <div
                        class="kpi-table__row d-flex"
                        *ngFor="let status of invitedList[b2bStatus.notStarted]"
                    >
                        <div class="kpi-table__row-mail">{{ status.email }}</div>
                        <div class="kpi-table__row-status_invited">Invited, not started</div>
                        <div
                            class="kpi-table__row-delete"
                            (click)="showDeleteMemberDialog(deleteMember, status.id)"
                        >
                            <img src="/assets/images/svg/delete-trash.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div
                    class="kpi-table__item"
                    *ngIf="invitedList && invitedList[b2bStatus.started]?.length"
                >
                    <div class="kpi-table__header d-flex">
                        <p class="kpi-table__header-item kpi-table__row-mail m-0">Email:</p>
                        <p class="kpi-table__header-item m-0">Status:</p>
                    </div>
                    <div
                        class="kpi-table__row d-flex"
                        *ngFor="let status of invitedList[b2bStatus.started]"
                    >
                        >
                        <div class="kpi-table__row-mail">{{ status.email }}</div>
                        <div class="kpi-table__row_started">Started, in progress</div>
                        <div
                            class="kpi-table__row-delete"
                            (click)="showDeleteMemberDialog(deleteMember, status.id)"
                        >
                            <img src="/assets/images/svg/delete-trash.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div
                    class="kpi-table__item"
                    *ngIf="invitedList && invitedList[b2bStatus.completed]?.length"
                >
                    <div class="kpi-table__header d-flex">
                        <p class="kpi-table__header-item kpi-table__row-mail m-0">Email:</p>
                        <p class="kpi-table__header-item m-0">Status:</p>
                    </div>
                    <div
                        class="kpi-table__row d-flex"
                        *ngFor="let status of invitedList[b2bStatus.completed]"
                    >
                        <div class="kpi-table__row-mail">{{ status.email }}</div>
                        <div class="">Completed</div>
                        <div
                            class="kpi-table__row-delete"
                            (click)="showDeleteMemberDialog(deleteMember, status.id)"
                        >
                            <img src="/assets/images/svg/delete-trash.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
