import { Component, OnInit, ViewEncapsulation } from '@angular/core';

declare let bootstrap: any;

@Component({
    selector: 'aihr-mobile-unsuported',
    templateUrl: './mobile-unsuported.component.html',
    styleUrls: ['./mobile-unsuported.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class MobileUnsuportedComponent implements OnInit {
    constructor() {}

    public ngOnInit(): void {
        let modal = new bootstrap.Modal(document.getElementById('unsuportedModal'));
        modal.show();
    }
}
