<aihr-error-message
    *ngIf="(errors$ | async) && !dataExists"
    [errors]="errors$ | async"
></aihr-error-message>
<aihr-loading *ngIf="(isLoading$ | async) && !dataExists"> </aihr-loading>
<div class="block-estimates" *ngIf="dataExists">
    <span id="highest&LowestPrompts"></span>
    <div
        class="d-flex justify-content-between flex-row highest-lowest-wrap gap-1"
    >
        <div class="p-1 mb-4 col-6">
            <p class="section-label">{{ 'Dashboard.Highest&LowestPrompts.Highest' | translate }}</p>
            <ul
                class="mark-container d-flex flex-column justify-content-between style-item"
            >
                <li
                    *ngFor="let mark of bestPromptMarks"
                    style="padding-bottom: 10px"
                    class="d-flex mark-container__item"
                >
                    <span class="mark">{{ mark.value | number : '1.1-1' }}</span
                    ><span class="mark-legend">{{ mark.name }}</span>
                </li>
            </ul>
        </div>
        <div class="p-1 col-6">
            <p class="section-label">{{ 'Dashboard.Highest&LowestPrompts.Lowest' | translate }}</p>
            <ul
                class="mark-container d-flex flex-column justify-content-between style-item"
            >
                <li
                    *ngFor="let mark of worstPromptMarks"
                    style="padding-bottom: 10px"
                    class="d-flex mark-container__item"
                >
                    <span class="mark negative">{{ mark.value | number : '1.1-1' }}</span
                    ><span class="mark-legend">{{ mark.name }}</span>
                </li>
            </ul>
        </div>
    </div>
</div>
