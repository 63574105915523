<div class="d-flex flex-row select-wrapper">
    <div class="select-item">
        <ng-select
            placeholder="Strengths"
            [items]="strengthsAndWeaknesses"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="strengthsAndWeaknessesValue"
            (change)="change()"
        ></ng-select>
    </div>
    <div class="select-item">
        <ng-select
            placeholder="Competencies"
            bindLabel="name"
            bindValue="id"
            [items]="competencies"
            (change)="change()"
            [(ngModel)]="competenciesValue"
        ></ng-select>
    </div>
</div>
<aihr-loading *ngIf="isLoading$ | async"></aihr-loading>
<aihr-error-message
    *ngIf="errors$ | async"
    [errors]="errors$ | async"
></aihr-error-message>
<div id="chart" class="style-item"></div>
