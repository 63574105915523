import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { LoadingModule } from '../loading/loading.module';
import { GetPollingEffect } from './store/effects/getPolling.effect';
import { SaveAnswerEffect } from './store/effects/saveAnswer.effect';
import { reducers } from './store/reducers';
import { PollingService } from './services/polling.service';
import { PollingComponent } from './components/polling/polling.component';
import { PollingCompetencyComponent } from './components/polling-competency/polling-competency.component';
import { SaveResultMemberEffect } from './store/effects/saveResultsMember.effect';
import { SaveResultColleagueEffect } from './store/effects/saveResultsColleague.effect';
import { POLLING } from '../../constants/storeNames';

@NgModule({
    declarations: [PollingComponent, PollingCompetencyComponent],
    imports: [
        CommonModule,
        FormsModule,
        EffectsModule.forFeature([
            GetPollingEffect,
            SaveAnswerEffect,
            SaveResultMemberEffect,
            SaveResultColleagueEffect,
        ]),
        StoreModule.forFeature(POLLING, reducers),
        LoadingModule,
        TranslateModule,
    ],
    providers: [PollingService],
    exports: [PollingComponent],
})
export class PollingModule {}
