import { createAction, props } from '@ngrx/store';

import { ISummaryRequest } from '../../types/summaryRequest.interface';
import { ActionTypes } from '../actionTypes';

export const getSummaryAction = createAction(ActionTypes.GET_SUMMARY, props<{ isB2b: boolean }>());

export const getSummarySuccessAction = createAction(
    ActionTypes.GET_SUMMARY_SUCCESS,
    props<{ summary: ISummaryRequest }>()
);
