import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'aihr-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class NavbarComponent implements OnInit {
    constructor(public service: AuthService, private cdRef: ChangeDetectorRef) {}

    public ngOnInit(): void {}

    public logOut(): void {
        this.service.logout();
        this.cdRef.detectChanges();
    }
}
