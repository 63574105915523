import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [AppComponent],
    imports: [AppRoutingModule, CoreModule, AuthModule.forRoot({
      ...{
        domain: environment.auth.domain,
        clientId: environment.auth.clientId,
        audience: environment.auth.audience,
        redirectUri: environment.auth.redirectUri,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiUrl}*`,
            allowAnonymous: true
          },
          `${environment.apiUrl}*`
        ]
      }
    })],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
