import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslateModule } from '@ngx-translate/core';

import { DashboardSummaryComponent } from './components/dashboard-summary/dashboard-summary.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardOverlayComponent } from './components/dashboard-overlay/dashboard-overlay.component';
import { GetColleaguesEffect } from './store/effects/getColleagues.effect';
import { GetSpecializationNamesEffect } from './store/effects/getSpecializations.effect';
import { GetStatusEffect } from './store/effects/getStatus.effect';
import { reducers } from './store/reducers';
import { CreateRequestEffect } from './store/effects/createRequest.effect';
import { DashboardService } from './services/dashboard.service';
import { ErrorMessagesModule } from 'src/app/shared/modules/error-messages/error-messages.module';
import { AddColleaguesEffect } from './store/effects/addColleagues.effect';
import { CreateTeamMemberEffect } from './store/effects/createTeamMember.effect';
import { DashboardSkillMatrixComponent } from './components/dashboard-skill-matrix/dashboard-skill-matrix.component';
import { GetSkillMatrixDataEffect } from './store/effects/getSkillMatrixData.effect';
import { LoadingModule } from 'src/app/shared/modules/loading/loading.module';
import { DashboardBlindSpotsComponent } from './components/dashboard-blind-spots/dashboard-blind-spots.component';
import { DashboardSkillGapsComponent } from './components/dashboard-skill-gaps/dashboard-skill-gaps.component';
import { DashboardFeedbackComponent } from './components/dashboard-feedback/dashboard-feedback.component';
import { GetBlindSpotsDataEffect } from './store/effects/getBlindSpotsData.effect';
import { GetRoleNamesEffect } from './store/effects/getRoles.effect';
import { GetSkillGapsDataEffect } from './store/effects/getSkillGapsData.effect';
import { GetSummaryDataEffect } from './store/effects/getSummaryData.effect';
import { GetFeedbackDataEffect } from './store/effects/getFeedbackData.effect';
import { DASHBOARD } from 'src/app/shared/constants/storeNames';
import { B2bDashboardComponent } from './components/b2b-dashboard/b2b-dashboard.component';
import { GetB2bStatusEffect } from './store/effects/getB2bStatus.effect';
import { GetStatusListEffect } from './store/effects/getStatusList.effect';
import { GetCurrentTeamListEffect } from './store/effects/getCurrentTeamList.effect';
import { RemoveTeamMemberEffect } from './store/effects/removeTeamMember.effect';
import { GetKPIEffect } from './store/effects/getKpi.effect';
import {
  StrengthsAndWeaknessesComponent
} from './components/strengths-and-weaknesses/strengths-and-weaknesses.component';
import { GetB2BSpecializationNamesEffect } from './store/effects/getB2BSpecializations.effect';
import { StrengthsAndWeaknessesEffect } from './store/effects/strengthsAndWeaknesses.effect';
import {
  DashboardHighestAndLowestComponent
} from './components/dashboard/dashboard-highest-and-lowest/dashboard-highest-and-lowest.component';
import { DashboardIntroComponent } from './components/dashboard/dashboard-intro/dashboard-intro.component';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from '../../../environments/environment';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardOverlayComponent,
    DashboardSummaryComponent,
    DashboardSkillMatrixComponent,
    DashboardBlindSpotsComponent,
    DashboardSkillGapsComponent,
    DashboardFeedbackComponent,
    B2bDashboardComponent,
    StrengthsAndWeaknessesComponent,
    DashboardHighestAndLowestComponent,
    DashboardIntroComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    NgSelectModule,
    EffectsModule.forFeature([
      GetColleaguesEffect,
      GetSpecializationNamesEffect,
      GetStatusEffect,
      CreateRequestEffect,
      AddColleaguesEffect,
      GetSummaryDataEffect,
      GetSkillMatrixDataEffect,
      GetBlindSpotsDataEffect,
      GetRoleNamesEffect,
      GetSkillGapsDataEffect,
      GetFeedbackDataEffect,
      CreateTeamMemberEffect,
      GetB2bStatusEffect,
      GetStatusListEffect,
      GetCurrentTeamListEffect,
      RemoveTeamMemberEffect,
      GetKPIEffect,
      GetB2BSpecializationNamesEffect,
      StrengthsAndWeaknessesEffect
    ]),
    StoreModule.forFeature(DASHBOARD, reducers),
    ErrorMessagesModule,
    BrowserAnimationsModule,
    NgxSliderModule,
    LoadingModule,
    TranslateModule,
    AuthModule.forRoot({
      ...{
        domain: 'aihr.eu.auth0.com',
        clientId: 'TEVxhX0XTTOwx0N0Kf2mAMWLxM1m7wAU',
        audience: 'https://app.aihr.com',
        redirectUri: 'https://360.aihr.com',
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiUrl}*`,
            allowAnonymous: true
          },
          `${environment.apiUrl}*`
        ]
      }
    })
  ],
  providers: [DashboardService]
})
export class DashboardModule {
}
