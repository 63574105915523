import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MobileUnsuportedComponent } from './components/mobile-unsuported/mobile-unsuported.component';

@NgModule({
    declarations: [MobileUnsuportedComponent],
    imports: [CommonModule, TranslateModule],
})
export class MobileUnsuportedModule {}
