import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PollingMemberComponent } from './components/polling-member/polling-member.component';
import { LoadingModule } from 'src/app/shared/modules/loading/loading.module';
import { PollingModule } from 'src/app/shared/modules/polling/polling.module';
import { ErrorMessagesModule } from 'src/app/shared/modules/error-messages/error-messages.module';

@NgModule({
    declarations: [PollingMemberComponent],
    imports: [
        CommonModule,
        FormsModule,
        ErrorMessagesModule,
        LoadingModule,
        PollingModule,
        TranslateModule,
    ],
})
export class PollingMemberModule {}
