import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { getPollingAction, getPollingSuccessAction } from '../actions/getPolling.action';
import { IPollingInfo } from '../../types/pollingInfo.interface';
import { PollingService } from '../../services/polling.service';
import { appErrorAction } from 'src/app/store/actions/sync.action';
import { ErrorHandlerService } from 'src/app/core/services/errorHandler.service';

@Injectable()
export class GetPollingEffect {
    getPolling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getPollingAction),
            switchMap(({ invite }) => {
                return this.pollingService.getPollingInfo(invite).pipe(
                    map((info: IPollingInfo) => {
                        return getPollingSuccessAction({ info });
                    }),
                    catchError((error: Error) => {
                        return of(appErrorAction({ error: this.errorHandler.covertError(error) }));
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private pollingService: PollingService,
        private errorHandler: ErrorHandlerService
    ) {}
}
