<aihr-error-message *ngIf="(errors$ | async) && !data"
                    [errors]="errors$ | async"></aihr-error-message>
<aihr-loading *ngIf="(isLoading$ | async) && !data"></aihr-loading>
<div *ngIf="data"
     class="row">
  <div class="col-8 matrix-box-wrap style-item d-flex flex-column justify-content-between align-items-center">
    <div class="matrix-box">
      <div class="matrix-box-part">
        <svg class="svg1"
             viewBox="-296 -296 296 296"
             height="296"
             width="296"
             (click)="setSelected(0)">
          <defs>
            <path id="sectorpath1"
                  stroke-width="0"
                  d="M0,0 L-296,0 A296,296 0 0 1 -275,-107 Z">
            </path>
            <mask id="themask1">
              <use xlink:href="#sectorpath1"
                   class="mask-use"></use>
            </mask>
            <use xlink:href="#sectorpath1"
                 id="sector1"
                 class="sector-1"></use>
          </defs>
          <g class="cursor"
             [attr.fill]="getCollor(data[0].dimentionMarks[0].value)">
            <use xlink:href="#sector1"></use>
            <foreignObject class="se"
                           x="-280"
                           y="-70"
                           width="80"
                           height="100">
              <div xmlns="http://www.w3.org/1999/xhtml">
                <p [style.color]="getTextCollor(data[0].dimentionMarks[0].value)"
                   class="text-center">{{ data[0].dimentionMarks[0].name }}</p>
              </div>
            </foreignObject>
          </g>
          <g class="cursor"
             [attr.fill]="getCollor(data[0].dimentionMarks[1].value)">
            <use xlink:href="#sector1"
                 transform="rotate(23)"></use>
            <foreignObject class="se"
                           x="-245"
                           y="-160"
                           width="80"
                           height="100">
              <div xmlns="http://www.w3.org/1999/xhtml">
                <p [style.color]="getTextCollor(data[0].dimentionMarks[1].value)"
                   class="text-center">{{ data[0].dimentionMarks[1].name }}</p>
              </div>
            </foreignObject>
          </g>
          <g class="cursor"
             [attr.fill]="getCollor(data[0].dimentionMarks[2].value)">
            <use xlink:href="#sector1"
                 transform="rotate(46)"></use>
            <foreignObject class="se"
                           x="-180"
                           y="-220"
                           width="90"
                           height="100">
              <div xmlns="http://www.w3.org/1999/xhtml">
                <p [style.color]="getTextCollor(data[0].dimentionMarks[2].value)"
                   class="text-center">{{ data[0].dimentionMarks[2].name }}</p>
              </div>
            </foreignObject>
          </g>
          <g class="cursor"
             [attr.fill]="getCollor(data[0].dimentionMarks[3].value)">
            <use xlink:href="#sector1"
                 transform="rotate(69)"></use>
            <foreignObject class="se"
                           x="-82"
                           y="-265"
                           width="70"
                           height="100">
              <div xmlns="http://www.w3.org/1999/xhtml">
                <p [style.color]="getTextCollor(data[0].dimentionMarks[3].value)"
                   class="text-center">{{ data[0].dimentionMarks[3].name }}</p>
              </div>
            </foreignObject>
          </g>
          <g>
            <svg x="-201"
                 y="-201"
                 height="201"
                 width="201">
              <circle cx="201"
                      cy="201"
                      r="100%"
                      fill="#FFFFFF" />
              <circle class="cursor"
                      cx="201"
                      cy="201"
                      r="191"
                      [attr.fill]="getCollor(data[0].value)" />
              <path id="curve1"
                    fill="none"
                    stroke-width="2"
                    d="M65,201 a150,150 0 0,1 140,-140" />
              <text width="300"
                    class="some"
                    textLength="170"
                    [style.color]="getTextCollor(data[0].value)"
                    x="25">
                <textPath xlink:href="#curve1"  textLength="170">
                  {{data[0].name}}
                </textPath>
              </text>
              <circle cx="201"
                      cy="201"
                      r="85"
                      fill="#FFFFFF" />
            </svg>
          </g>
        </svg>
      </div>
      <div class="matrix-box-part">
        <svg class="svg2"
             viewBox="0 -296 296 296"
             height="296"
             width="296"
             (click)="setSelected(1)">
          <defs>
            <path id="sectorpath2"
                  stroke-width="0"
                  d="M0,0 L0,-296 A296,296 0 0 1 206,-214 Z">
            </path>
            <mask id="themask2">
              <use xlink:href="#sectorpath2"
                   class="mask-use"></use>
            </mask>
            <use xlink:href="#sectorpath2"
                 id="sector2"
                 class="sector-2"></use>
          </defs>
          <g class="cursor"
             [attr.fill]="getCollor(data[1].dimentionMarks[0].value)">
            <use xlink:href="#sector2"></use>
            <foreignObject class="se"
                           x="50"
                           y="-250"
                           width="90"
                           height="100">
              <div xmlns="http://www.w3.org/1999/xhtml">
                <p [style.color]="getTextCollor(data[1].dimentionMarks[0].value)"
                   class="text-center">{{ data[1].dimentionMarks[0].name }}</p>
              </div>
            </foreignObject>
          </g>
          <g class="cursor"
             [attr.fill]="getCollor(data[1].dimentionMarks[1].value)">
            <use xlink:href="#sector2"
                 transform="rotate(46)"></use>
            <foreignObject class="se"
                           x="185"
                           y="-110"
                           width="70"
                           height="100">
              <div xmlns="http://www.w3.org/1999/xhtml">
                <p [style.color]="getTextCollor(data[1].dimentionMarks[1].value)"
                   class="text-center">{{ data[1].dimentionMarks[1].name }}</p>
              </div>
            </foreignObject>
          </g>
          <g>
            <svg x="0"
                 y="-201"
                 height="201"
                 width="201">
              <circle cx="0"
                      cy="201"
                      r="100%"
                      fill="#FFFFFF" />
              <circle class="cursor"
                      cx="0"
                      cy="201"
                      r="191"
                      [attr.fill]="getCollor(data[1].value)" />
              <path id="curve2"
                    fill="none"
                    stroke-width="2"
                    d="M0,65 a150,150 0 0,1 140,140" />
              <text width="300"
                    class="some"
                    textLength="160"
                    [style.color]="getTextCollor(data[0].value)"
                    x="25">
                <textPath xlink:href="#curve2" textLength="160">
                  {{ data[1].name }}
                </textPath>
              </text>
              <circle cx="0"
                      cy="201"
                      r="85"
                      fill="#FFFFFF" />
            </svg>
          </g>
        </svg>
      </div>

      <div class="matrix-box-part">
        <svg class="svg3"
             viewBox="-296 0 296 296"
             height="296"
             width="296"
             (click)="setSelected(2)">
          <defs>
            <path id="sectorpath3"
                  stroke-width="0"
                  d="M0,0 L0,296 A296,296 0 0 1 -206,214 Z">
            </path>
            <mask id="themask3">
              <use xlink:href="#sectorpath3"
                   class="mask-use"></use>
            </mask>
            <use xlink:href="#sectorpath3"
                 id="sector3"
                 class="sector-3"></use>
          </defs>
          <g class="cursor"
             [attr.fill]="getCollor(data[2].dimentionMarks[0].value)">
            <use xlink:href="#sector3"></use>
            <foreignObject class="se"
                           x="-170"
                           y="208"
                           width="150"
                           height="100">
              <div xmlns="http://www.w3.org/1999/xhtml">
                <p [style.color]="getTextCollor(data[2].dimentionMarks[0].value)"
                   class="text-center">{{ data[2].dimentionMarks[0].name }}</p>
              </div>
            </foreignObject>
          </g>
          <g class="cursor"
             [attr.fill]="getCollor(data[2].dimentionMarks[1].value)">
            <use xlink:href="#sector3"
                 transform="rotate(46)"></use>
            <foreignObject class="se"
                           x="-275"
                           y="70"
                           width="90"
                           height="100">
              <div xmlns="http://www.w3.org/1999/xhtml">
                <p [style.color]="getTextCollor(data[2].dimentionMarks[1].value)"
                   class="text-center">{{ data[2].dimentionMarks[1].name }}</p>
              </div>
            </foreignObject>
          </g>
          <g>
            <svg x="-201"
                 y="0"
                 height="201"
                 width="201">
              <circle cx="201"
                      cy="0"
                      r="100%"
                      fill="#FFFFFF" />
              <circle class="cursor"
                      cx="201"
                      cy="0"
                      r="191"
                      [attr.fill]="getCollor(data[2].value)" />
              <path id="curve3"
                    fill="none"
                    stroke-width="2"
                    d="M55,0 a150,150 0 0,0 140,140" />
              <text width="300"
                    class="some"
                    textLength="170"
                    [style.color]="getTextCollor(data[0].value)"
                    x="25">
                <textPath xlink:href="#curve3" textLength="170">
                  {{ data[2].name }}
                </textPath>
              </text>
              <circle cx="201"
                      cy="0"
                      r="85"
                      fill="#FFFFFF" />
            </svg>
          </g>
        </svg>
      </div>

      <div class="matrix-box-part">
        <svg class="svg4"
             viewBox="0 0 296 296"
             height="296"
             width="296"
             (click)="setSelected(3)">
          <defs>
            <path id="sectorpath4"
                  stroke-width="0"
                  d="M0,0 L296,0 A296,296 0 0 1 260,142 Z">
            </path>
            <mask id="themask4">
              <use xlink:href="#sectorpath4"
                   class="mask-use"></use>
            </mask>
            <use xlink:href="#sectorpath4"
                 id="sector4"
                 class="sector-4"></use>
          </defs>
          <g class="cursor"
             [attr.fill]="getCollor(data[3].dimentionMarks[0].value)">
            <use xlink:href="#sector4"></use>
            <foreignObject class="se"
                           x="195"
                           y="38"
                           width="90"
                           height="100">
              <div xmlns="http://www.w3.org/1999/xhtml">
                <p [style.color]="getTextCollor(data[3].dimentionMarks[0].value)"
                   class="text-center">{{ data[3].dimentionMarks[0].name }}</p>
              </div>
            </foreignObject>
          </g>
          <g class="cursor"
             [attr.fill]="getCollor(data[3].dimentionMarks[1].value)">
            <use xlink:href="#sector4"
                 transform="rotate(30.5)"></use>
            <foreignObject class="se"
                           x="125"
                           y="145"
                           width="105"
                           height="100">
              <div xmlns="http://www.w3.org/1999/xhtml">
                <p [style.color]="getTextCollor(data[3].dimentionMarks[1].value)"
                   class="text-center">{{ data[3].dimentionMarks[1].name }}</p>
              </div>
            </foreignObject>
          </g>
          <g class="cursor"
             [attr.fill]="getCollor(data[3].dimentionMarks[2].value)">
            <use xlink:href="#sector4"
                 transform="rotate(61)"></use>
            <foreignObject class="se"
                           x="20"
                           y="215"
                           width="90"
                           height="100">
              <div xmlns="http://www.w3.org/1999/xhtml">
                <p [style.color]="getTextCollor(data[3].dimentionMarks[2].value)"
                   class="text-center">{{ data[3].dimentionMarks[2].name }}</p>
              </div>
            </foreignObject>
          </g>
          <g>
            <svg height="201"
                 width="201">
              <circle cx="0"
                      cy="0"
                      r="100%"
                      fill="#FFFFFF" />
              <circle class="cursor"
                      cx="0"
                      cy="0"
                      r="191"
                      [attr.fill]="getCollor(data[3].value)" />
              <path id="curve4"
                    fill="none"
                    stroke-width="2"
                    d="M0,146 a150,150 0 0,0 140,-140" />
              <text width="300"
                    class="some"
                    textLength="170"
                    [style.color]="getTextCollor(data[0].value)"
                    x="25">
                <textPath xlink:href="#curve4" textLength="170">
                  {{ data[3].name }}
                </textPath>
              </text>
              <circle cx="0"
                      cy="0"
                      r="85"
                      fill="#FFFFFF" />
            </svg>
          </g>
        </svg>

      </div>
    </div>
    <div class="scale d-flex flex-row justify-content-between align-self-baseline">
      <div class="scale__item">
        <span class="scale__item-color scale__item-color_novice mb-2"></span>
        <p class="scale__item-title text-start">1</p>
      </div>
      <div class="scale__item">
        <span class="scale__item-color scale__item-color_proficient mb-2"></span>
        <p class="scale__item-title text-center">4</p>
      </div>
      <div class="scale__item">
        <span class="scale__item-color scale__item-color_expert mb-2"></span>
        <p class="scale__item-title text-end">7</p>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="dimention-box style-item">
      <div class="dimention-box__title-wrap">
        <h4 class="mb-2">{{ data[selectedDimentionId].name }}</h4>
        <p class="dimention-box__description">{{data[selectedDimentionId].definition }}</p>
      </div>
      <div class="progress-wrap d-flex flex-column justify-content-top">
        <div *ngFor="let dimention of data[selectedDimentionId].dimentionMarks"
             class="progress">
          <p class="progress__label"> <span>{{ dimention.value.toFixed(1) }}</span>- <span>{{ dimention.name }}</span>
          </p>
          <div class="progress-bar"
               role="progressbar"
               [style.width.%]="dimention.value * 100 / 7"
               aria-valuenow="25"
               aria-valuemin="0"
               aria-valuemax="100"></div>
        </div>
        <div class="scale d-flex flex-row justify-content-between">
          <div class="scale__item">
            <span class="scale__item-color scale__item-color_novice mb-2"></span>
            <p class="scale__item-title text-start">1</p>
          </div>
          <div class="scale__item">
            <span class="scale__item-color scale__item-color_proficient mb-2"></span>
            <p class="scale__item-title text-center">4</p>
          </div>
          <div class="scale__item">
            <span class="scale__item-color scale__item-color_expert mb-2"></span>
            <p class="scale__item-title text-end">7</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
