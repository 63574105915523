export enum ActionTypes {
    GET = '[Polling] Get',
    GET_SUCCESS = '[Polling] Get success',

    SAVE_ANSWERS = '[Answers] Save',

    SAVE_POLLING_MEMBER = '[Polling] Save for member',
    SAVE_POLLING_MEMBER_SUCCESS = '[Polling] Save for member success',

    SAVE_POLLING_COLLEAGUE = '[Polling] Save for colleague',
    SAVE_POLLING_COLLEAGUE_SUCCESS = '[Polling] Save for colleague success',
}
