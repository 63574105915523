import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { saveAnswersAction } from '../actions/sync.action';

@Injectable()
export class SaveAnswerEffect {
    saveAnswer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveAnswersAction),
            map(() => { })
        ),
        {dispatch: false}
    );

    constructor(private actions$: Actions) {}
}
