import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'aihr-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class LoadingComponent implements OnInit {
    constructor() {}

    public ngOnInit(): void {}
}
