import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { IPollingInfo } from '../types/pollingInfo.interface';
import { ISaveRequestMember } from '../types/saveRequestMember.interface';
import { ISaveRequestColleague } from '../types/saveRequestColleague.interface';

@Injectable({
    providedIn: 'root'
})
export class PollingService {
    memberSubject: BehaviorSubject<IPollingInfo> = new BehaviorSubject<IPollingInfo>(null);
    constructor(private http: HttpClient) { }

    public getPollingInfo(invite: string | null): Observable<IPollingInfo> {
        const fullUrl = invite != null ?
            `${environment.apiUrl}Polling/info/${invite}` :
            `${environment.apiUrl}Polling/info`;
        return this.http.get<IPollingInfo>(fullUrl);
    }

    public savePollingResultsMember(request: ISaveRequestMember): Observable<{}> {
        const fullUrl = `${environment.apiUrl}Polling/results-member`;
        return this.http.post(fullUrl, request);
    }

    public savePollingResultsColleague(invite: string, request: ISaveRequestColleague): Observable<{}> {
        const fullUrl = `${environment.apiUrl}Polling/results-colleague/${invite}`;
        return this.http.post(fullUrl, request);
    }
}
