import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import {
    LIGHT,
    LIGHT_LIMIT,
    NORMAL_TEXT,
    ON_SATURATED_TEXT,
    PALE,
    SATURATED,
    SATURATED_LIMIT,
} from '../../constants/skill-matrix';
import { errorSelector, isLoadingSelector, skillMatrixSelector } from '../../store/selectors';
import { ISkillMatrixCompetencyMark } from '../../types/skillMatrixCompetency.interface';

@Component({
    selector: 'aihr-dashboard-skill-matrix',
    templateUrl: './dashboard-skill-matrix.component.html',
    styleUrls: ['./dashboard-skill-matrix.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardSkillMatrixComponent implements OnInit, OnDestroy {
    public isLoading$: Observable<boolean>;
    public dataSubscription: Subscription;
    public data: ISkillMatrixCompetencyMark[] | null;
    public errors$: Observable<IBackendErrors | null>;

    public selectedDimentionId: number = 0;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.isLoading$ = this.store.pipe(select(isLoadingSelector));
        this.dataSubscription = this.store
            .pipe(select(skillMatrixSelector))
            .subscribe((data: ISkillMatrixCompetencyMark[]) => {
                this.data = data;
            });
        this.errors$ = this.store.pipe(select(errorSelector));
    }

    public ngOnDestroy(): void {
        this.dataSubscription.unsubscribe();
    }

    public getCollor(value: number): string {
        if (value >= SATURATED_LIMIT) {
            return SATURATED;
        } else if (value >= LIGHT_LIMIT && value < SATURATED_LIMIT) {
            return LIGHT;
        } else {
            return PALE;
        }
    }

    public getTextCollor(value: number): string {
        if (value >= SATURATED_LIMIT) {
            return ON_SATURATED_TEXT;
        } else {
            return NORMAL_TEXT;
        }
    }

    public setSelected(id: number): void {
        this.selectedDimentionId = id;
    }
}
