import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { getPollingAction } from 'src/app/shared/modules/polling/store/actions/getPolling.action';
import { saveResultsMemberAction } from 'src/app/shared/modules/polling/store/actions/saveResults.action';
import { IPollingInfo } from 'src/app/shared/modules/polling/types/pollingInfo.interface';
import { IPromptAnswer } from 'src/app/shared/modules/polling/types/promptAnswer.interface';
import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import {
    errorSelector,
    isLoadingSelector,
    isSavedSelector,
    pollingInfoSelector,
    promptAnswersSelector,
} from 'src/app/shared/modules/polling/store/selectors';

@Component({
    selector: 'aihr-polling-member',
    templateUrl: './polling-member.component.html',
    styleUrls: ['./polling-member.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class PollingMemberComponent implements OnInit, OnDestroy {
    public data$: Observable<IPollingInfo | null>;
    public errors$: Observable<IBackendErrors | null>;
    public isLoading$: Observable<boolean>;
    public isSavedSubscription: Subscription;
    public promptAnswersSubscription: Subscription;
    public promptAnswers: IPromptAnswer[];
    public scrollTriggerButton: any;

    constructor(private store: Store, private router: Router) {}

    public ngOnInit(): void {
        this.initializeData();
        this.setupModal();
    }

    private initializeData(): void {
        this.data$ = this.store.pipe(select(pollingInfoSelector));
        this.errors$ = this.store.pipe(select(errorSelector));
        this.isLoading$ = this.store.pipe(select(isLoadingSelector));
        this.promptAnswersSubscription = this.store
            .pipe(select(promptAnswersSelector))
            .subscribe((prompts: IPromptAnswer[]) => {
                this.promptAnswers = prompts;
            });
        this.store.dispatch(
            getPollingAction({
                invite: null,
            })
        );
        this.isSavedSubscription = this.store
            .pipe(select(isSavedSelector))
            .subscribe((result: boolean) => {
                if (result) {
                    document.getElementById('modalButton').click();
                }
            });
    }

    private setupModal(): void {
        let router = this.router;
        document
            .getElementById('congratulationsModal')
            .addEventListener('hidden.bs.modal', function (event) {
                router.navigateByUrl('/');
            });
    }

    public ngOnDestroy(): void {
        this.promptAnswersSubscription.unsubscribe();
        this.isSavedSubscription.unsubscribe();
    }

    public send(): void {
        this.store.dispatch(saveResultsMemberAction({ request: { answers: this.promptAnswers } }));
    }
}
