import { createAction, props } from '@ngrx/store';

import { IAddTeamMemberRequest } from '../../types/addTeamMemberRequest.interface';
import { ActionTypes } from '../actionTypes';

export const createUpdateTeamMemberAction = createAction(
    ActionTypes.CREATE_TEAM_MEMBER,
    props<{ createRequest: IAddTeamMemberRequest; isNew: boolean }>()
);

export const createTeamMemberSuccessAction = createAction(ActionTypes.CREATE_TEAM_MEMBER_SUCCESS);
