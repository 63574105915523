import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { DashboardService } from '../../services/dashboard.service';
import { ErrorHandlerService } from 'src/app/core/services/errorHandler.service';
import { appErrorAction } from 'src/app/store/actions/sync.action';
import {
    getCurrentTeamListAction,
    getCurrentTeamListSuccessAction,
} from '../actions/getCurrentTeamList.action';
import { ITeamMember } from '../../types/teamMember.interface';

@Injectable()
export class GetCurrentTeamListEffect {
    public getColleagues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getCurrentTeamListAction),
            switchMap(() => {
                return this.dashboardService.getCurrentTeamList().pipe(
                    map((teamMembersList: ITeamMember[]) => {
                        return getCurrentTeamListSuccessAction({ teamMembersList });
                    }),
                    catchError((error: Error) => {
                        return this.errorHandler.checkStatus(error, 404)
                            ? of(getCurrentTeamListSuccessAction({ teamMembersList: null }))
                            : of(appErrorAction({ error: this.errorHandler.covertError(error) }));
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private dashboardService: DashboardService,
        private errorHandler: ErrorHandlerService
    ) {}
}
