import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { DashboardService } from '../../services/dashboard.service';
import { getColleaguesAction, getColleaguesSuccessAction } from '../actions/getColleagues.action';
import { IColleagueInfo } from '../../types/colleagueInfo.interface';
import { ErrorHandlerService } from 'src/app/core/services/errorHandler.service';
import { appErrorAction } from 'src/app/store/actions/sync.action';

@Injectable()
export class GetColleaguesEffect {
    public getColleagues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getColleaguesAction),
            switchMap(() => {
                return this.dashboardService.getColleagues().pipe(
                    map((colleagues: IColleagueInfo[]) => {
                        return getColleaguesSuccessAction({ colleagues: colleagues });
                    }),
                    catchError((error: Error) => {
                        return this.errorHandler.checkStatus(error, 404)
                            ? of(getColleaguesSuccessAction({ colleagues: null }))
                            : of(appErrorAction({ error: this.errorHandler.covertError(error) }));
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private dashboardService: DashboardService,
        private errorHandler: ErrorHandlerService
    ) {}
}
