import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { DashboardService } from '../../services/dashboard.service';
import { ErrorHandlerService } from 'src/app/core/services/errorHandler.service';
import { appErrorAction } from 'src/app/store/actions/sync.action';
import { getB2bStatusAction, getB2bStatusSuccessAction } from '../actions/getB2bStatus.action';
import { IManagerStatusInfo } from '../../types/managerStatusInfo';

@Injectable()
export class GetB2bStatusEffect {
    public getStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getB2bStatusAction),
            switchMap(() => {
                return this.dashboardService.getB2bStatusInfo().pipe(
                    map((info: IManagerStatusInfo) => {
                        return getB2bStatusSuccessAction({ b2bStatus: info });
                    }),
                    catchError((error: Error) => {
                        return this.errorHandler.checkStatus(error, 404)
                            ? of(getB2bStatusSuccessAction({ b2bStatus: null }))
                            : of(appErrorAction({ error: this.errorHandler.covertError(error) }));
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private dashboardService: DashboardService,
        private errorHandler: ErrorHandlerService
    ) {}
}
