<aihr-error-message *ngIf="(errors$ | async) && !data"
                    [errors]="errors$ | async"></aihr-error-message>
<aihr-loading *ngIf="(isLoading$ | async) && !data"></aihr-loading>
<div *ngIf="data"
     class="blind-spots-section d-flex flex-md-row flex-wrap justify-content-between">
    <div class="blind-spots-section__wrap-block">
        <p class="section-label">{{ 'Dashboard.BlindSpots.Overconfident' | translate }}</p>
        <div *ngFor="let promptData of data.overconfident"
             class="blind-spots-section__item style-item overconfident-slider">
            <div class="blind-spots-section__item-header d-flex justify-content-between">
                <p class="col-10">{{ promptData.prompt }}</p>
                <p class="col-2 text-end">
                    {{ 'Dashboard.BlindSpots.Gap' | translate }} {{ (promptData.selfMark - promptData.mark).toFixed(1) }}
                </p>
            </div>
            <ngx-slider [(value)]="promptData.mark"
                        [(highValue)]="promptData.selfMark"
                        [options]="options"></ngx-slider>
        </div>
    </div>
    <div class="blind-spots-section__wrap-block">
        <p class="section-label">{{ 'Dashboard.BlindSpots.Underconfident' | translate }}</p>
        <div *ngFor="let promptData of data.underconfident"
             class="blind-spots-section__item style-item underconfident-slider">
            <div class="blind-spots-section__item-header d-flex justify-content-between">
                <p class="col-10">{{ promptData.prompt }}</p>
                <p class="col-2 text-end">
                    {{ 'Dashboard.BlindSpots.Gap' | translate }} {{ (promptData.mark - promptData.selfMark).toFixed(1) }}
                </p>
            </div>
            <ngx-slider [(value)]="promptData.selfMark"
                        [(highValue)]="promptData.mark"
                        [options]="options"></ngx-slider>
        </div>
    </div>
</div>
