import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, tap, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

import { PollingService } from '../../services/polling.service';
import {
    saveResultsColleagueAction,
    saveResultsColleagueSuccessAction,
} from '../actions/saveResults.action';
import { ErrorHandlerService } from 'src/app/core/services/errorHandler.service';
import { appErrorAction } from 'src/app/store/actions/sync.action';

@Injectable()
export class SaveResultColleagueEffect {
    saveResult$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveResultsColleagueAction),
            switchMap(({ invite, request }) => {
                return this.pollingService.savePollingResultsColleague(invite, request).pipe(
                    map(() => {
                        return saveResultsColleagueSuccessAction();
                    }),
                    catchError((error: Error) => {
                        return of(appErrorAction({ error: this.errorHandler.covertError(error) }));
                    })
                );
            })
        )
    );

    redirectAfterDelete$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(saveResultsColleagueSuccessAction),
                tap(() => {
                    this.router.navigate(['/polling/done']);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private pollingService: PollingService,
        private router: Router,
        private errorHandler: ErrorHandlerService
    ) {}
}
