import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ThanksColleagueComponent } from './components/thanks-colleague/thanks-colleague.component';

@NgModule({
    declarations: [ThanksColleagueComponent],
    imports: [CommonModule, TranslateModule],
})
export class ThanksColleagueModule {}
