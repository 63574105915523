import { createReducer, on, Action } from '@ngrx/store';
import { routerNavigationAction } from '@ngrx/router-store';

import { IPollingState } from '../types/pollingState.interface';
import {
    getPollingAction,
    getPollingSuccessAction,
} from './actions/getPolling.action';
import {
    saveResultsMemberAction,
    saveResultsColleagueAction,
    saveResultsColleagueSuccessAction,
    saveResultsMemberSuccessAction,
} from './actions/saveResults.action';
import { saveAnswersAction } from './actions/sync.action';
import { appErrorAction } from 'src/app/store/actions/sync.action';

const initialState: IPollingState = {
    data: null,
    isLoading: false,
    errors: null,
    promptAnswers: [],
    isSaved: false,
};

export const pollingReducer = createReducer(
    initialState,
    on(
        getPollingAction,
        (state): IPollingState => ({
            ...state,
            isLoading: true,
            errors: null,
        })
    ),
    on(
        getPollingSuccessAction,
        (state, action): IPollingState => ({
            ...state,
            isLoading: false,
            data: action.info,
        })
    ),
    on(
        saveAnswersAction,
        (state, action): IPollingState => ({
            ...state,
            promptAnswers: action.promptAnswers,
        })
    ),
    on(
        saveResultsMemberAction,
        (state): IPollingState => ({
            ...state,
            isLoading: true,
            errors: null,
        })
    ),
    on(
        saveResultsMemberSuccessAction,
        (state): IPollingState => ({
            ...state,
            isLoading: false,
            isSaved: true,
        })
    ),
    on(
        saveResultsColleagueAction,
        (state): IPollingState => ({
            ...state,
            isLoading: true,
            errors: null,
        })
    ),
    on(
        saveResultsColleagueSuccessAction,
        (state): IPollingState => ({
            ...state,
            isLoading: false,
            isSaved: true,
        })
    ),
    on(
        appErrorAction,
        (state, action): IPollingState => ({
            ...state,
            isLoading: false,
            errors: action.error,
        })
    ),
    on(routerNavigationAction, (): IPollingState => initialState)
);

export function reducers(state: IPollingState, action: Action) {
    return pollingReducer(state, action);
}
