import { createAction, props } from '@ngrx/store';

import { IEntityName } from 'src/app/shared/modules/polling/types/entityName.interface';
import { ActionTypes } from '../actionTypes';

export const getB2BSpecializationsAction = createAction(ActionTypes.GET_B2B_SPECIALIZATIONS);

export const getB2BSpecializationsSuccessAction = createAction(
    ActionTypes.GET_B2B_SPECIALIZATIONS_SUCCESS,
    props<{ b2bSpecializationNames: IEntityName[] | null }>()
);
