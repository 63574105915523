import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';

@Injectable()
export class ErrorHandlerService {
    constructor() {}

    public checkStatus(error: Error, statusCode: number): boolean {
        if (error instanceof HttpErrorResponse) {
            return (error as HttpErrorResponse).status == statusCode;
        }

        return false;
    }

    public covertError(error: Error): IBackendErrors {
        if (error instanceof HttpErrorResponse) {
            return this.covertBackendError(error);
        }

        var frontError = { isServerAvalible: true, errorsList: {} };
        frontError.errorsList['error'] = [error.message];
        return frontError;
    }

    private covertBackendError(response: HttpErrorResponse): IBackendErrors {
        return {
            isServerAvalible: response.status != 0 && response.status != 502,
            errorsList: response.error.errors as { [key: string]: string[] } | null,
        };
    }
}
