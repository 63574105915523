import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { blindSpotsSelector, errorSelector, isLoadingSelector } from '../../store/selectors';
import { IBlindSpotsResponse } from '../../types/blindSpotsResponse.interface';
import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import { SLIDER_OPTIONS } from '../../constants/blind-spots';

@Component({
    selector: 'aihr-dashboard-blind-spots',
    templateUrl: './dashboard-blind-spots.component.html',
    styleUrls: ['./dashboard-blind-spots.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardBlindSpotsComponent implements OnInit, OnDestroy {
    public isLoading$: Observable<boolean>;
    public dataSubscription: Subscription;
    public data: IBlindSpotsResponse | null;
    public errors$: Observable<IBackendErrors | null>;

    public selectedDimentionId: number = 0;
    public holdedSectorId: number | null;
    public options: Options = SLIDER_OPTIONS;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.initializeData();
    }

    private initializeData(): void {
        this.isLoading$ = this.store.pipe(select(isLoadingSelector));
        this.dataSubscription = this.store
            .pipe(select(blindSpotsSelector))
            .subscribe((data: IBlindSpotsResponse) => {
                if (data != null) {
                    this.data = { overconfident: [], underconfident: [] };

                    data.overconfident.forEach((val) =>
                        this.data.overconfident.push(Object.assign({}, val))
                    );

                    data.underconfident.forEach((val) =>
                        this.data.underconfident.push(Object.assign({}, val))
                    );
                } else {
                    this.data = null;
                }
            });
        this.errors$ = this.store.pipe(select(errorSelector));
    }

    public ngOnDestroy(): void {
        this.dataSubscription.unsubscribe();
    }
}
