import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { IFeedback } from 'src/app/shared/modules/polling/types/feedback.interface';
import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import { errorSelector, feedbackSelector, isLoadingSelector } from '../../store/selectors';

@Component({
    selector: 'aihr-dashboard-feedback',
    templateUrl: './dashboard-feedback.component.html',
    styleUrls: ['./dashboard-feedback.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardFeedbackComponent implements OnInit, OnDestroy {
    public isLoading$: Observable<boolean>;
    public dataSubscription: Subscription;
    public data: IFeedback | null;
    public nameParam: any;
    public errors$: Observable<IBackendErrors | null>;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.isLoading$ = this.store.pipe(select(isLoadingSelector));
        this.dataSubscription = this.store
            .pipe(select(feedbackSelector))
            .subscribe((data: IFeedback) => {
                this.data = data;
                this.nameParam = { firstName: data?.memberName };
            });
        this.errors$ = this.store.pipe(select(errorSelector));
    }

    public ngOnDestroy(): void {
        this.dataSubscription.unsubscribe();
    }
}
