import { createAction, props } from '@ngrx/store';

import { IBlindSpotsResponse } from '../../types/blindSpotsResponse.interface';
import { ActionTypes } from '../actionTypes';

export const getBlindSpotsAction = createAction(ActionTypes.GET_BLINDSPOTS);

export const getBlindSpotsSuccessAction = createAction(
    ActionTypes.GET_BLINDSPOTS_SUCCESS,
    props<{ blindSpots: IBlindSpotsResponse | null }>()
);
