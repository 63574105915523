import { createAction, props } from '@ngrx/store';
import { SectionType } from '../../types/sectionType.enum';

import { ISkillGapsDimentionMark } from '../../types/skillGapsDimention.interface';
import { ActionTypes } from '../actionTypes';

export const getSkillGapsAction = createAction(
    ActionTypes.GET_SKILLGAPS,
    props<{ section: SectionType; roleId: number }>()
);

export const getSkillGapsSuccessAction = createAction(
    ActionTypes.GET_SKILLGAPS_SUCCESS,
    props<{ section: SectionType; skillGaps: ISkillGapsDimentionMark[] | null }>()
);
