<div class="container-fluid">
    <aihr-loading *ngIf="isLoading$ | async"></aihr-loading>
    <aihr-global-error *ngIf="(errors$ | async)?.isServerAvalible == false"></aihr-global-error>
    <aihr-error-message *ngIf="errors$ | async"
                        [errors]="errors$ | async"></aihr-error-message>

    <div *ngIf="data && !(isLoading$ | async)"
         class="container">
        <div class="d-grid gap-5">
            <div class="main-title-wrap text-center">
                <h1 class="main-title fw-bold">{{ 'Polling.Colleague.Header' | translate }}</h1>
                <p class="title-description fw-semibold p-0 m-0">{{ 'Polling.Colleague.Message' | translate:nameParam }}</p>
            </div>
            <div class="p-3 style-item polling-instruction">
                <div class="d-grid">
                    <div class="p-3">
                        <h5 class="fw-bolder polling-instruction__title">
                            {{ 'Polling.Colleague.Instruction.Header' | translate }}
                        </h5>
                    </div>
                    <div class="p-3 pt-0">
                        <ol type="1"
                            class="lh-lg px-3 m-0 polling-instruction__list">
                            <li>{{ 'Polling.Colleague.Instruction.li1' | translate:nameParam }}</li>
                            <li>{{ 'Polling.Colleague.Instruction.li2' | translate:nameParam }}</li>
                            <li>{{ 'Polling.Colleague.Instruction.li3' | translate:nameParam }}</li>
                        </ol>
                    </div>
                    <div class="p-3 pt-0">
                        <p id="polling-scroll-anchor"
                           class="lh-lg"
                           [innerHtml]="'Polling.Colleague.Instruction.Anonymous' | translate:nameParam"></p>
                    </div>
                </div>
            </div>
            <div *ngIf="isAllPromptsAnswered">
                <div class="style-item polling-table">
                    <div class="polling-table__title rounded-top comment-form__title">
                        <p class="text-center fw-semibold m-0">{{ 'Polling.Colleague.Comments.Header' | translate }}</p>
                    </div>
                    <form [formGroup]="form"
                          class="comment-form__body polling-table__body rounded-bottom"
                          (ngSubmit)="onSubmit()">
                        <fieldset class="form-group-wrap">
                            <p class="comment-form__description"
                                [innerHtml]="'Polling.Colleague.Comments.Description1' | translate:nameParam"></p>
                            <p 
                                class="comment-form__description">
                                {{ 'Polling.Colleague.Comments.Description2' | translate }}</p>
                            <p 
                            class="comment-form__description">
                            {{ 'Polling.Colleague.Comments.Description3' | translate }}</p>
                            <p 
                                class="comment-form__description"
                                [innerHtml]="'Polling.Colleague.Comments.Description4' | translate"></p>
                            <hr>
                            <fieldset class="form-group">
                                <div class="row justify-content-between">
                                    <div class="comment-form comment-form-item">
                                        <span>{{ 'Polling.Colleague.Comments.Feedback1.Title' | translate }}</span>
                                        <label for="stopComment"
                                               class="comment-form__label">
                                               {{ 'Polling.Colleague.Comments.Feedback1.Message' | translate }}</label>
                                    </div>
                                    <div class="comment-form-item">
                                        <textarea type="text"
                                                  class="form-control form-control__textarea"
                                                  formControlName="stopComment"
                                                  placeholder="Start typing..."
                                                  [maxlength]="maxLength" 
                                                  id="stopComment"></textarea>
                                                  <div class="textarea_counter-letters">
                                                    <span>{{form.get('stopComment')?.value?.length || 0}}</span> /
                                                    <span>{{maxLength}}</span>
                                                  </div>
                                    </div>
                                </div>
                            </fieldset>
                            <hr>
                            <fieldset class="form-group">
                                <div class="row justify-content-between">
                                    <div class="comment-form comment-form-item">
                                        <span>{{ 'Polling.Colleague.Comments.Feedback2.Title' | translate }}</span>
                                        <label for="startComment"
                                               class="comment-form__label">
                                               {{ 'Polling.Colleague.Comments.Feedback2.Message' | translate }}</label>
                                    </div>
                                    <div class="comment-form-item">
                                        <textarea type="text"
                                                  class="form-control form-control__textarea"
                                                  formControlName="startComment"
                                                  placeholder="Start typing..."
                                                  [maxlength]="maxLength" 
                                                  id="startComment"></textarea>
                                                  <div class="textarea_counter-letters">
                                                    <span>{{form.get('startComment')?.value?.length || 0}}</span> /
                                                    <span>{{maxLength}}</span>
                                                </div>
                                    </div>
                                </div>
                            </fieldset>
                            <hr>
                            <fieldset class="form-group">
                                <div class="row justify-content-between">
                                    <div class="comment-form comment-form-item">
                                        <span> {{ 'Polling.Colleague.Comments.Feedback3.Title' | translate }}</span>
                                        <label for="withstandComment"
                                               class="comment-form__label">
                                               {{ 'Polling.Colleague.Comments.Feedback3.Message' | translate }}</label>
                                    </div>
                                    <div class="comment-form-item">
                                        <textarea type="text"
                                                  class="form-control form-control__textarea"
                                                  formControlName="continueComment"
                                                  placeholder="Start typing..."
                                                  [maxlength]="maxLength" 
                                                  id="withstandComment"></textarea>
                                                  <div class="textarea_counter-letters">
                                                    <span>{{form.get('continueComment')?.value?.length || 0}}</span> /
                                                    <span>{{maxLength}}</span>
                                                </div>
                                    </div>
                                </div>
                            </fieldset>
                            <div class="btn-wrap d-flex align-items-center justify-content-between gap-3">
                                <div class="progress-wrap align-self-end">
                                    <div class="text-center progress-title">
                                        <p class="text-center fw-bolder pb-2 m-0">{{ 'Polling.Progress' | translate }}</p>
                                    </div>
                                    <div class="progress rounded-pill aihr-progress">
                                        <div class="progress-bar aihr-ft-dark fw-semibold" 
                                             role="progressbar"
                                             [style.width.%]="progress"
                                             aria-valuenow="20"
                                             aria-valuemin="0"
                                             aria-valuemax="100">{{progress}}%
                                           </div>
                                    </div>
                                </div>
                                <button class="btn-main btn btn-warning align-self-end"
                                        type="submit"
                                        [disabled]="!form.valid || (isLoading$ | async)">
                                    {{ 'Polling.Colleague.Submit' | translate }}    
                                </button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
            <aihr-polling [competencies]="data.competencies"
                          (whenDone)="showForm()"></aihr-polling>
        </div>
    </div>
</div>