import {
    AfterViewChecked,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { IEntityName } from 'src/app/shared/modules/polling/types/entityName.interface';
import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import { NAME_REGEX, RELATION_TYPES } from '../../constants/overlay';
import { addColleaguesAction } from '../../store/actions/addColleagues.action';
import { createPollingAction } from '../../store/actions/createRequest.action';
import { createUpdateTeamMemberAction } from '../../store/actions/createUpdateTeamMember.action';
import { getColleaguesAction } from '../../store/actions/getColleagues.action';
import { getCurrentTeamListAction } from '../../store/actions/getCurrentTeamList.action';
import { getSpecilizationsAction } from '../../store/actions/getSpecializations.action';
import {
    errorSelector,
    isLoadingSelector,
    colleaguesSelector,
    specializationNamesSelector,
    statusSelector,
    teamSelector,
    currentTeamListSelector,
    b2bStatusSelector,
} from '../../store/selectors';
import { IColleagueInfo } from '../../types/colleagueInfo.interface';
import { ITeamMember } from '../../types/teamMember.interface';

declare var bootstrap: any;

@Component({
    selector: 'aihr-dashboard-overlay',
    templateUrl: './dashboard-overlay.component.html',
    styleUrls: ['./dashboard-overlay.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardOverlayComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Input('isB2b') isB2b: boolean;
    team: FormArray;
    oldTeamMembers: ITeamMember[];
    oldTeamMembersSubscription: Subscription;
    public get relationTypes() {
        return RELATION_TYPES;
    }

    public isLoading$: Observable<boolean>;
    public oldColleaguesSubscription: Subscription;
    public statusSubscription: Subscription;
    public oldColleagues: IColleagueInfo[] | null;
    public specializationNames$: Observable<IEntityName[] | null>;
    public errorsSubscription: Subscription;
    public backendErrors: IBackendErrors | null;

    public form: FormGroup;
    public colleagues: FormArray;

    public addColleguesRestrictionTooltip: any;

    constructor(private store: Store, private fb: FormBuilder) {}

    public ngOnInit(): void {
        if (!this.isB2b) {
            this.initializeForm();
        } else {
            this.initializeTeamForm();
        }
        this.initializeData();
    }

    public ngAfterViewChecked(): void {
        this.initializeColleguesRestrictionTooltip();
    }

    private initializeColleguesRestrictionTooltip() {
        if (!this.addColleguesRestrictionTooltip) {
            let element = document.getElementById('addCollegueTrigger');
            if (element) {
                this.addColleguesRestrictionTooltip = new bootstrap.Tooltip(element, {});
            }
        }
    }

    private initializeData(): void {
        this.isLoading$ = this.store.pipe(select(isLoadingSelector));
        if (!this.isB2b) {
            this.oldColleaguesSubscription = this.store
                .pipe(select(colleaguesSelector))
                .subscribe((data) => {
                    this.oldColleagues = data;
                    if (this.oldColleagues != null) {
                        this.colleagues.clear();
                        this.form.controls['specializationId'].disable();
                        this.oldColleagues.forEach((element) => {
                            this.colleagues.push(
                                this.fb.group({
                                    firstName: { value: element.firstName, disabled: true },
                                    email: { value: element.email, disabled: true },
                                    relation: { value: element.relation, disabled: true },
                                })
                            );
                        });
                    }
                });
            this.store.dispatch(getColleaguesAction());
        } else {
            this.oldTeamMembersSubscription = this.store
                .pipe(select(currentTeamListSelector))
                .subscribe((data) => {
                    this.oldTeamMembers = data;
                    if (this.oldTeamMembers?.length) {
                        this.team.clear();
                        this.oldTeamMembers.forEach((element) => {
                            this.team.push(
                                this.fb.group({
                                    firstName: { value: element.firstName, disabled: true },
                                    email: { value: element.email, disabled: true },
                                    specializationId: {
                                        value: element.specializationId,
                                        disabled: true,
                                    },
                                    id: { value: element.id, disabled: true },
                                })
                            );
                        });
                    }
                });
        }
        if (!this.isB2b) {
            this.statusSubscription = this.store.pipe(select(statusSelector)).subscribe((data) => {
                if (data?.specializationId != undefined) {
                    this.form.controls['specializationId'].patchValue(data?.specializationId);
                }
                if (data?.managerEmail) {
                    this.form.controls['managerEmail'].patchValue(data?.managerEmail);
                }
            });
        } else {
            this.statusSubscription = this.store
                .pipe(select(b2bStatusSelector))
                .subscribe((data) => {
                    if (data) {
                        this.store.dispatch(getCurrentTeamListAction());
                    }
                });
        }
        this.specializationNames$ = this.store.pipe(select(specializationNamesSelector));
        this.errorsSubscription = this.store
            .pipe(select(errorSelector))
            .subscribe((errors: IBackendErrors) => {
                this.backendErrors = errors;
            });

        this.store.dispatch(getSpecilizationsAction());
    }

    private initializeForm(): void {
        this.colleagues = new FormArray([
            this.fb.group({
                firstName: ['', [Validators.required, Validators.pattern(NAME_REGEX)]],
                email: ['', [Validators.required, Validators.email]],
                relation: [null, Validators.required],
            }),
            this.fb.group({
                firstName: ['', [Validators.required, Validators.pattern(NAME_REGEX)]],
                email: ['', [Validators.required, Validators.email]],
                relation: [null, Validators.required],
            }),
            this.fb.group({
                firstName: ['', [Validators.required, Validators.pattern(NAME_REGEX)]],
                email: ['', [Validators.required, Validators.email]],
                relation: [null, Validators.required],
            }),
        ]);
        this.form = this.fb.group({
            colleagues: this.colleagues,
            managerEmail: null,
        });
        this.form.addControl('specializationId', new FormControl(null, Validators.required));
    }

    private initializeTeamForm(): void {
        this.team = new FormArray([
            this.fb.group({
                firstName: ['', [Validators.required, Validators.pattern(NAME_REGEX)]],
                email: ['', [Validators.required, Validators.email]],
                specializationId: [null, Validators.required],
                id: [0, Validators.required],
            }),
            this.fb.group({
                firstName: ['', [Validators.required, Validators.pattern(NAME_REGEX)]],
                email: ['', [Validators.required, Validators.email]],
                specializationId: [null, Validators.required],
                id: [0, Validators.required],
            }),
            this.fb.group({
                firstName: ['', [Validators.required, Validators.pattern(NAME_REGEX)]],
                email: ['', [Validators.required, Validators.email]],
                specializationId: [null, Validators.required],
                id: [0, Validators.required],
            }),
        ]);
        this.form = this.fb.group({
            team: this.team,
        });
    }

    public toggleAddCollegueRestrictionPopup() {
        this.addColleguesRestrictionTooltip?.toggle();
    }

    public clearAddCollegueRestrictionPopup() {
        this.addColleguesRestrictionTooltip = null;
    }

    public addColleague(): void {
        const colleagueForm = this.fb.group({
            firstName: ['', [Validators.required, Validators.pattern(NAME_REGEX)]],
            email: ['', [Validators.required, Validators.email]],
            relation: [null, Validators.required],
        });
        this.colleagues.push(colleagueForm);
    }

    addTeamMember(): void {
        const form = this.fb.group({
            firstName: ['', [Validators.required, Validators.pattern(NAME_REGEX)]],
            email: ['', [Validators.required, Validators.email]],
            specializationId: [null, Validators.required],
            id: [0, [Validators.required]],
        });
        this.team.push(form);
    }

    public deleteColleague(index: number) {
        this.colleagues.removeAt(index);
    }

    public deleteMember(index: number) {
        this.team.removeAt(index);
    }

    public isDisabled(index: number): any {
        const controls = this.isB2b ? this.team.controls : this.colleagues.controls;
        return (controls[index] as any).controls.firstName.disabled;
    }

    public isFieldFirstnameEmpty(index: number): any {
        const controls = this.isB2b ? this.team.controls : this.colleagues.controls;
        return (
            (controls[index] as any).controls.firstName.errors?.['required'] &&
            (controls[index] as any).controls.firstName.touched
        );
    }

    public isFieldFirstnameInvalid(index: number): any {
        const controls = this.isB2b ? this.team.controls : this.colleagues.controls;
        return (
            (controls[index] as any).controls.firstName.errors?.['pattern'] &&
            (controls[index] as any).controls.firstName.touched
        );
    }

    public isFieldEmailInvalid(index: number): any {
        const controls = this.isB2b ? this.team.controls : this.colleagues.controls;
        return (
            (controls[index] as any).controls.email.invalid &&
            (controls[index] as any).controls.email.touched
        );
    }

    public isFieldRelationInvalid(index: number): any {
        const controls = this.colleagues.controls;
        return (
            (controls[index] as any).controls.relation.invalid &&
            (controls[index] as any).controls.relation.touched
        );
    }

    isFieldSpecializationInvalid(index: number): any {
        const controls = this.team.controls;
        return (
            (controls[index] as any).controls.specializationId.invalid &&
            (controls[index] as any).controls.specializationId.touched
        );
    }

    public checkCollegueErrorField(index: number, fieldName: string): boolean | null {
        return this.backendErrors?.errorsList.hasOwnProperty(
            'Colleagues[' +
                (index - (this.oldColleagues != null ? this.oldColleagues.length : 0)) +
                `].${fieldName}`
        );
    }

    public getCollegueErrorField(index: number, fieldName: string): string {
        return this.backendErrors.errorsList[
            'Colleagues[' +
                (index - (this.oldColleagues != null ? this.oldColleagues.length : 0)) +
                `].${fieldName}`
        ][0];
    }

    public send(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        if (!this.isB2b) {
            if (this.oldColleagues == null) {
                this.store.dispatch(
                    createPollingAction({
                        pollingRequest: this.form.value,
                    })
                );
            } else {
                this.store.dispatch(
                    addColleaguesAction({
                        addRequest: this.form.value,
                    })
                );
            }
        } else {
            this.store.dispatch(
                createUpdateTeamMemberAction({
                    createRequest: this.form.value?.team,
                    isNew: this.team?.length ? false : true,
                })
            );
        }
    }

    public ngOnDestroy(): void {
        this.oldColleaguesSubscription?.unsubscribe();
        this.oldTeamMembersSubscription?.unsubscribe();
        this.errorsSubscription.unsubscribe();
        this.statusSubscription.unsubscribe();
    }
}
