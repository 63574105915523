import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as d3 from 'd3';
import { event as d3event } from 'd3';

import { Observable, Subject, takeUntil } from 'rxjs';
import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import {
    b2bStatusSelector,
    b2bStrengthOrWeaknessSelector,
    errorSelector,
    isLoadingSelector,
} from '../../store/selectors';
import { TranslateService } from '@ngx-translate/core';
import { getStrengthsAndWeaknessesAction } from '../../store/actions/getStrengthsAndWeaknesses.action';
import { DashboardService } from '../../services/dashboard.service';

enum StrengthsAndWeaknesses {
    Strengths = 0,
    Weaknesses = 1,
}

@Component({
    selector: 'app-strengths-and-weaknesses',
    templateUrl: './strengths-and-weaknesses.component.html',
    styleUrls: ['./strengths-and-weaknesses.component.css'],
})
export class StrengthsAndWeaknessesComponent implements OnInit, OnDestroy {
    dataset = {
        children: [],
    };
    isLoading$: Observable<boolean>;
    errors$: Observable<IBackendErrors | null>;
    strengthsAndWeaknesses: { name: string; id: number }[];
    competencies: { name: any; id: number }[];
    competenciesValue: number = 0;
    strengthsAndWeaknessesValue: number = StrengthsAndWeaknesses.Strengths;
    private readonly destroy$ = new Subject();
    tooltip: any;

    constructor(
        private store: Store,
        private translateService: TranslateService,
        private dashboardService: DashboardService
    ) {}
    ngOnInit(): void {
        this.tooltip = d3
            .select('body')
            .append('div')
            .attr('class', 'circle-tooltip-wrap__message')
            .style('opacity', 0);

        this.store
            .pipe(takeUntil(this.destroy$), select(b2bStrengthOrWeaknessSelector))
            .subscribe((info) => {
                this.dataset.children = info?.filter((child) => child.value);
                this.renderChart();
            });
        this.buildDropdowns();
        this.isLoading$ = this.store.pipe(select(isLoadingSelector));
        this.errors$ = this.store.pipe(select(errorSelector));
        this.store.pipe(takeUntil(this.destroy$), select(b2bStatusSelector)).subscribe(() => {
            this.change();
        });
        this.dashboardService.b2bSpecializationIdChanged
            .pipe(takeUntil(this.destroy$))
            .subscribe((isChanged) => {
                if (isChanged) {
                    this.change();
                }
            });
    }

    change() {
        const data = {
            request: {
                strengthOrWeakness: this.strengthsAndWeaknessesValue,
                competencyOrDimension: this.competenciesValue,
            },
        };
        this.store.dispatch(getStrengthsAndWeaknessesAction(data));
    }

    buildDropdowns() {
        this.strengthsAndWeaknesses = [
            {
                name: this.translateService.instant('B2B.StrengthsWeaknesses.0'),
                id: StrengthsAndWeaknesses.Strengths,
            },
            {
                name: this.translateService.instant('B2B.StrengthsWeaknesses.1'),
                id: StrengthsAndWeaknesses.Weaknesses,
            },
        ];
        this.competencies = [
            { name: this.translateService.instant('B2B.CompetencyOrDimension.0'), id: 0 },
            { name: this.translateService.instant('B2B.CompetencyOrDimension.1'), id: 1 },
        ];
    }

    renderChart() {
        let height = 500;
        let width = 800;
        let bubble = d3.pack().size([width, height]).padding(2.5);
        let that = this;
        that.tooltip.style('opacity', 0);
        d3.select('#chart').html(null);

        let svg = d3
            .select('#chart')
            .append('svg')
            .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')

            .attr('width', width)
            .attr('height', height)
            .attr('class', 'bubble');

        if (!this.dataset.children?.length) {
            let emptyMessage = this.translateService.instant(
                'B2B.StrengthsWeaknesses.StrengthEmptyMessage'
            );
            if (this.strengthsAndWeaknessesValue == StrengthsAndWeaknesses.Weaknesses) {
                emptyMessage = this.translateService.instant(
                    'B2B.StrengthsWeaknesses.WeaknessesEmptyMessage'
                );
            }
            d3.select('#chart')
                .html(`<span>${emptyMessage}</span>`)
                .attr('style', `max-width: 100%; height: ${height}px; align-items: center;`);
            return;
        }
        let nodes = d3.hierarchy(this.dataset).sum(function (d: any) {
            return d.value;
        });
        const max = Math.max(...this.dataset.children.map((o) => o.value));
        const setColor = (isSpecialization: boolean, value: number) => {
            const opacity = value / max;
            let color =
                this.strengthsAndWeaknessesValue == StrengthsAndWeaknesses.Strengths
                    ? '42, 182, 36'
                    : '227, 45, 52';
            if (isSpecialization) {
                color = '49, 33, 107';
            }
            return `rgba(${color}, ${opacity})`;
        };
        let node = svg
            .selectAll('.node')
            .data(bubble(nodes))
            .enter()
            .filter(function (d: { children: any }) {
                return !d.children;
            })
            .append('g')
            .attr('class', 'node')
            // .attr('font-size', 12)
            // .attr('font-family', 'sans-serif')
            .attr('text-anchor', 'middle')
            .attr('transform', function (d: { x: string; y: string }) {
                return 'translate(' + d.x + ',' + d.y + ')';
            })
            .style(
                'fill',
                function (
                    d: {
                        value: any;
                        data: { isSpecialization: boolean };
                    },
                    i: any
                ) {
                    return setColor(d.data.isSpecialization, d.value);
                }
            );

        node.append('circle')
            .attr('x', function (d: { x: any }) {
                return d.x;
            })
            .attr('y', function (d: { y: any }) {
                return d.y;
            })
            .attr('r', function (d: { r: any }) {
                return d.r;
            })
            .on('mouseover', function (event, data) {
                that.tooltip.transition().duration(200).style('opacity', 1);

                that.tooltip.style('position', 'absolute').style('pointer-events', 'none');
                const definition = data.data?.definition || '';
                that.tooltip
                    .html(`<strong>${data.data?.name}</strong><br>${definition}`)
                    .style('left', event.pageX + 'px')
                    .style('top', event.pageY + 'px');
            })
            .on('mouseout', function (d) {
                that.tooltip.transition().duration(500).style('opacity', 0);
            });
        node.append('text')
            .attr('dy', '-1em')
            .style('text-anchor', 'middle')
            .text(function (d: any) {
                return d.data.value;
            })
            .attr('font-family', 'Nunito')
            .attr('font-size', function (d) {
                return d.r / 5;
            })
            .attr('fill', 'white');

        node.append('text')
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .text(function (d: any) {
                if (d.data.name?.length > 30) {
                    return d.data.name.substring(0, 30).concat('...');
                }
                return d.data.name;
            })
            .attr('font-family', 'Nunito')
            .attr('font-size', function (d) {
                return d.r / 10;
            })
            .attr('fill', 'white');
        svg.append('g');

        // node.append('text')
        //     .style('text-anchor', 'middle')
        //     .selectAll('tspan')
        //     .data((d) => {
        //         return (d.data.value + ' ' + d.data.name).split(' ');
        //     })
        //     .join('tspan')
        //     .attr('x', '0')
        //     .attr('fill', 'white')
        //     .attr('y', (d, i, D) => `${i - D.length / 2 + 0.85}em`)
        //     .text((d) => {
        //         return d;
        //     });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
