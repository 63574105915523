import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PollingColleagueComponent } from './components/polling-colleague/polling-colleague.component';
import { ErrorMessagesModule } from 'src/app/shared/modules/error-messages/error-messages.module';
import { LoadingModule } from 'src/app/shared/modules/loading/loading.module';
import { PollingModule } from 'src/app/shared/modules/polling/polling.module';
import { PollingColleagueMarkRecallComponent } from './components/polling-colleague-mark-recall/polling-colleague-mark-recall.component';

@NgModule({
    declarations: [PollingColleagueComponent, PollingColleagueMarkRecallComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ErrorMessagesModule,
        LoadingModule,
        PollingModule,
        TranslateModule,
    ],
})
export class PollingColleagueModule {}
