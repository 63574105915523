import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { PollingService } from 'src/app/shared/modules/polling/services/polling.service';

@Component({
    selector: 'app-thanks-colleague',
    templateUrl: './thanks-colleague.component.html',
    styleUrls: ['./thanks-colleague.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class ThanksColleagueComponent implements OnInit {
    dataSubscription: Subscription;
    nameParam: { firstName: String };
    constructor(private pollingService: PollingService) { }

    ngOnInit(): void {
        this.nameParam = { firstName: this.pollingService.memberSubject.value.memberFirstName };
    }
}
