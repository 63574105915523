import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { DashboardService } from '../../services/dashboard.service';
import { ErrorHandlerService } from 'src/app/core/services/errorHandler.service';
import { appErrorAction } from 'src/app/store/actions/sync.action';
import {
    createUpdateTeamMemberAction,
    createTeamMemberSuccessAction,
} from '../actions/createUpdateTeamMember.action';

@Injectable()
export class CreateTeamMemberEffect {
    addTeamMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createUpdateTeamMemberAction),
            switchMap(({ createRequest, isNew }) => {
                return this.dashboardService.createUpdateTeamMemberRequest(createRequest, isNew).pipe(
                    map(() => {
                        this.dashboardService.subject.next(true);
                        return createTeamMemberSuccessAction();
                    }),
                    catchError((error: Error) => {
                        return of(appErrorAction({ error: this.errorHandler.covertError(error) }));
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private dashboardService: DashboardService,
        private errorHandler: ErrorHandlerService
    ) {}
}
