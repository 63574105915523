export const environment = {
  production: false,
  apiUrl: 'https://360-api.dev.burnwood.aihr.com/',
  auth: {
    domain: 'dev-k5b1pa5k.us.auth0.com',
    clientId: 'zpCjxXJaVMPsw0oEEBwnHxLnDdPqM00e',
    audience: 'https://dev.app.aihr.com',
    redirectUri: 'https://360.dev.burnwood.aihr.com',
  },
};
