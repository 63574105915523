import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { GlobalErrorComponent } from './components/global-error/global-error.component';

@NgModule({
    declarations: [ErrorMessageComponent, GlobalErrorComponent],
    imports: [CommonModule, TranslateModule],
    exports: [ErrorMessageComponent, GlobalErrorComponent],
})
export class ErrorMessagesModule {}
