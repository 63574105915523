import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {
    public title = 'aihr-360-spa';

    constructor(private translateService: TranslateService) {}

    public ngOnInit(): void {
        this.translateService.use('en');
    }
}
