import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { errorSelector, isLoadingSelector, summarySelector } from '../../../store/selectors';

import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import { IPromptMark } from '../../../types/promptMark.interface';
import { ISummaryRequest } from '../../../types/summaryRequest.interface';

@Component({
    selector: 'aihr-dashboard-highest-and-lowest',
    templateUrl: './dashboard-highest-and-lowest.component.html',
    styleUrls: ['./dashboard-highest-and-lowest.component.scss'],
})
export class DashboardHighestAndLowestComponent implements OnInit, OnDestroy {
    public isLoading$: Observable<boolean>;
    public dataSubscription: Subscription;
    public dataExists: boolean = false;
    public errors$: Observable<IBackendErrors | null>;

    public competencyMarks: IPromptMark[];
    public bestPromptMarks: IPromptMark[];
    public worstPromptMarks: IPromptMark[];
    public summary = { core: [], notCore: [] };

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.initializeData();
    }

    initializeData(): void {
        this.isLoading$ = this.store.pipe(select(isLoadingSelector));
        this.dataSubscription = this.store
            .pipe(select(summarySelector))
            .subscribe((data: ISummaryRequest) => {
                if (!data) {
                    this.dataExists = false;
                    return;
                }
                this.handleData(data);
                this.dataExists = true;
            });
        this.errors$ = this.store.pipe(select(errorSelector));
    }

    handleData(data: ISummaryRequest): void {
        this.bestPromptMarks = data.bestPromptMarks?.slice()?.sort((a, b) => b.value - a.value);
        this.worstPromptMarks = data.worstPromptMarks?.slice()?.sort((a, b) => b.value - a.value);
    }

    ngOnDestroy(): void {
        this.dataSubscription.unsubscribe();
    }
}
