import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'aihr-global-error',
    templateUrl: './global-error.component.html',
    styleUrls: ['./global-error.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class GlobalErrorComponent implements OnInit {
    constructor() {}

    public ngOnInit(): void {}

    public refresh(): void {
        window.location.reload();
    }
}
