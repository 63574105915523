import { createAction, props } from '@ngrx/store';

import { IEntityName } from 'src/app/shared/modules/polling/types/entityName.interface';
import { ActionTypes } from '../actionTypes';

export const getRolesAction = createAction(ActionTypes.GET_ROLES);

export const getRolesSuccessAction = createAction(
    ActionTypes.GET_ROLES_SUCCESS,
    props<{ names: IEntityName[] | null }>()
);
