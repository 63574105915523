import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { DashboardService } from '../../services/dashboard.service';
import {
    getSpecilizationsAction,
    getSpecilizationsSuccessAction,
} from '../actions/getSpecializations.action';
import { IEntityName } from 'src/app/shared/modules/polling/types/entityName.interface';
import { ErrorHandlerService } from 'src/app/core/services/errorHandler.service';
import { appErrorAction } from 'src/app/store/actions/sync.action';

@Injectable()
export class GetSpecializationNamesEffect {
    public getSpecializationNames$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSpecilizationsAction),
            switchMap(() => {
                return this.dashboardService.getSpecilizationNames().pipe(
                    map((names: IEntityName[]) => {
                        return getSpecilizationsSuccessAction({ names: names });
                    }),
                    catchError((error: Error) => {
                        return of(appErrorAction({ error: this.errorHandler.covertError(error) }));
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private dashboardService: DashboardService,
        private errorHandler: ErrorHandlerService
    ) {}
}
