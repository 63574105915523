import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../../environments/environment';
import { NavbarModule } from '../shared/modules/navbar/navbar.module';
import { PollingColleagueModule } from '../pages/polling-colleague/polling-colleague.module';
import { PollingMemberModule } from '../pages/polling-member/polling-member.module';
import { DashboardModule } from '../pages/dashboard/dashboard.module';
import { ThanksColleagueModule } from '../pages/thanks-colleague/thanks-colleague.module';
import { ErrorHandlerService } from './services/errorHandler.service';
import { MobileUnsuportedModule } from '../pages/mobile-unsuported/mobile-unsuported.module';
import { B2bModule } from '../pages/b2b/b2b.module';

@NgModule({
    declarations: [],
    imports: [
        BrowserModule,
        CommonModule,
        NgbModule,
        HttpClientModule,
        AuthModule.forRoot({
            ...{
              domain: 'aihr.eu.auth0.com',
              clientId: 'TEVxhX0XTTOwx0N0Kf2mAMWLxM1m7wAU',
              audience: 'https://app.aihr.com',
              redirectUri: 'https://360.aihr.com',
            },
            httpInterceptor: {
                allowedList: [
                    {
                        uri: `${environment.apiUrl}*`,
                        allowAnonymous: true,
                    },
                    `${environment.apiUrl}*`,
                ],
            },
        }),
        StoreModule.forRoot({ router: routerReducer }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        NavbarModule,
        DashboardModule,
        PollingMemberModule,
        PollingColleagueModule,
        NgxSliderModule,
        ThanksColleagueModule,
        MobileUnsuportedModule,
        B2bModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        ErrorHandlerService,
    ],
    exports: [NavbarModule],
})
export class CoreModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
