import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { errorSelector, isLoadingSelector, summarySelector } from '../../store/selectors';

import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import { IPromptMark } from '../../types/promptMark.interface';
import { ISummaryRequest } from '../../types/summaryRequest.interface';

@Component({
    selector: 'aihr-dashboard-summary',
    templateUrl: './dashboard-summary.component.html',
    styleUrls: ['./dashboard-summary.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardSummaryComponent implements OnInit, OnDestroy {
    @Input() isB2b = false;
    isLoading$: Observable<boolean>;
    dataSubscription: Subscription;
    dataExists: boolean = false;
    errors$: Observable<IBackendErrors | null>;

    competencyMarks: IPromptMark[];
    bestPromptMarks: IPromptMark[];
    worstPromptMarks: IPromptMark[];
    summary = { core: [], notCore: [], impact: [] };
    dimentionMarks: any[];
    backButtonTitle: string;
    breakdown: {
        name: string;
        definition: string;
        dimentionMarks: any[];
        isMark: boolean;
        index: number;
        competencyMarks: IPromptMark[];
    };

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.initializeData();
    }

    private initializeData(): void {
        this.isLoading$ = this.store.pipe(select(isLoadingSelector));
        this.dataSubscription = this.store
            .pipe(select(summarySelector))
            .subscribe((data: ISummaryRequest) => {
                if (!data) {
                    this.dataExists = false;
                    return;
                }
                this.competencyMarks = data.competencyMarks;
                this.handleSummary();
                if (this.competencyMarks?.length) {
                    this.handlePrompts(0, false, null, this.summary.core);
                }
                this.dataExists = true;
            });
        this.errors$ = this.store.pipe(select(errorSelector));
    }

    private handleSummary(): void {
        this.summary = { core: [], notCore: [], impact: [] };
        this.competencyMarks?.forEach((mark) => {
            if (mark.isCore && !mark.isImpact) {
                this.summary.core.push(mark);
            } else {
                if (mark.isImpact) {
                    this.summary.impact.push(mark);
                } else {
                    this.summary.notCore.push(mark);
                }
            }
        });
    }

    handlePrompts(i: number, isMark: boolean = false, marks: any = null, competencyMarks): void {
        const value = marks ? marks : competencyMarks[i];
        this.breakdown = {
            name: value?.name,
            definition: value?.definition,
            dimentionMarks: marks ? value.marks : value?.dimentionMarks,
            isMark,
            index: isMark ? this.breakdown?.index : i,
            competencyMarks,
        };
        if (!isMark) {
            this.backButtonTitle = `${competencyMarks[i].name}`;
        }
    }

    onDimentionClick(i: number, dimention: any, breakdown) {
        this.handlePrompts(i, true, dimention, breakdown.competencyMarks);
    }

    onBackClick(value) {
        this.handlePrompts(value.index, false, null, value.competencyMarks);
    }

    getBreakdownClassNames(breakdown) {
        return `open open-${breakdown?.dimentionMarks?.length}`;
    }

    ngOnDestroy(): void {
        this.dataSubscription.unsubscribe();
    }
}
