import { createSelector } from '@ngrx/store';

import { IAppState } from 'src/app/shared/types/appState.interface';
import { IDashboardState } from '../types/dashboardState.interface';

export const dashboardFeatureSelector = (state: IAppState): IDashboardState => state.dashboard;

export const isLoadingSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.loadingCount > 0
);

export const errorSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.errors
);

export const statusSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.status
);

export const specializationNamesSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.specializationNames
);

export const colleaguesSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.colleagues
);

export const teamSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.teamMembers
);

export const currentTeamListSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.teamMembersList
);
export const summarySelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.summaryData
);

export const skillMatrixSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.skillMatrixData
);

export const blindSpotsSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.blindSpotsData
);

export const roleNamesSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.roleNames
);

export const firstSkillGapsSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.skillGapsDataFirst
);

export const secondSkillGapselector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.skillGapsDataSecond
);

export const feedbackSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.feedbackData
);

export const b2bStatusSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.b2bStatus
);

export const b2bStatusListSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.b2bStatusList
);

export const b2bKPIInfoSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.b2bKpiInfo
);

export const b2bSpecializationsSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.b2bSpecializationNames
);

export const b2bStrengthOrWeaknessSelector = createSelector(
    dashboardFeatureSelector,
    (dashboardState: IDashboardState) => dashboardState.b2bStrengthOrWeaknessInfo
);
