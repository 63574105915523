<div *ngIf="!isB2b">
    <div class="sidebar__body d-grid gap-3">
        <div class="main-title-wrap">
            <h1 class="main-title fw-bold">{{ 'Dashboard.Overlay.Header' | translate }}</h1>
            <p class="title-description fw-semibold p-0 m-0">
                {{ 'Dashboard.Overlay.Message' | translate }}
            </p>
        </div>
        <div class="step-instruction row justify-content-center">
            <div class="step-instruction__item col-md-4 col-sm-6">
                <div class="step-instruction__icon">
                    <div class="step-instruction__icon-step">1</div>
                    <img src="/assets/images/sidebar-email_invitation.png" alt="" />
                </div>
                <div class="step-instruction__description">
                    <p class="step-instruction__description-title">
                        {{ 'Dashboard.Overlay.Instruction.Step1' | translate }}
                    </p>
                    <p>{{ 'Dashboard.Overlay.Instruction.Message1' | translate }}</p>
                </div>
            </div>
            <div class="step-instruction__item col-md-4 col-sm-6">
                <div class="step-instruction__icon">
                    <div class="step-instruction__icon-step">2</div>
                    <img src="/assets/images/sidebar-feedback_collection.png" alt="" />
                </div>
                <div class="step-instruction__description">
                    <p class="step-instruction__description-title">
                        {{ 'Dashboard.Overlay.Instruction.Step2' | translate }}
                    </p>
                    <p>{{ 'Dashboard.Overlay.Instruction.Message2' | translate }}</p>
                </div>
            </div>
            <div class="step-instruction__item col-md-4 col-sm-6">
                <div class="step-instruction__icon">
                    <div class="step-instruction__icon-step">3</div>
                    <img src="/assets/images/sidebar-dashboard_insights.png" alt="" />
                </div>
                <div class="step-instruction__description">
                    <p class="step-instruction__description-title">
                        {{ 'Dashboard.Overlay.Instruction.Step3' | translate }}
                    </p>
                    <p>{{ 'Dashboard.Overlay.Instruction.Message3' | translate }}</p>
                </div>
            </div>
        </div>
        <form class="sidebar__form" [formGroup]="form" (ngSubmit)="send()">
            <fieldset>
                <div class="mb-4">
                    <label for="inputSpecialization" class="sidebar__form-label">
                        {{ 'Dashboard.Overlay.Specialization' | translate }}
                    </label>
                    <fieldset class="sidebar__form-item input-specialization">
                        <ng-select
                            [items]="specializationNames$ | async"
                            bindLabel="name"
                            bindValue="id"
                            formControlName="specializationId"
                            [class.ng-select_error]="
                                (form.controls['specializationId'].invalid &&
                                    form.controls['specializationId'].touched) ||
                                backendErrors?.errorsList.hasOwnProperty('SpecializationId')
                            "
                            [placeholder]="
                                'Dashboard.Overlay.PlaceHolder.Specialization' | translate
                            "
                        >
                        </ng-select>
                        <p
                            *ngIf="
                                form.controls['specializationId'].invalid &&
                                form.controls['specializationId'].touched
                            "
                            class="sidebar__form-eror m-0"
                        >
                            {{ 'Dashboard.Overlay.Errors.NoSpecialization' | translate }}
                        </p>
                        <p
                            *ngIf="backendErrors?.errorsList.hasOwnProperty('SpecializationId')"
                            class="sidebar__form-eror m-0"
                        >
                            {{ backendErrors.errorsList['SpecializationId'] }}
                        </p>
                    </fieldset>
                </div>
                <p class="sidebar__form-label">{{ 'Dashboard.Overlay.Colleagues' | translate }}</p>
                <div
                    formArrayName="colleagues"
                    *ngFor="let colleague of colleagues.controls; let i = index"
                    class="d-flex justify-content-lg-start justify-content-sm-between flex-md-nowrap flex-sm-wrap sidebar__form-row-wrap"
                >
                    <ng-container [formGroupName]="i">
                        <fieldset class="sidebar__form-item firstname">
                            <input
                                [id]="'firstname_' + i"
                                type="text"
                                [placeholder]="
                                    'Dashboard.Overlay.PlaceHolder.Firstname' | translate
                                "
                                class="sidebar__form-field"
                                [class.sidebar__form-field_error]="
                                    isFieldFirstnameEmpty(i) ||
                                    isFieldFirstnameInvalid(i) ||
                                    checkCollegueErrorField(i, 'FirstName')
                                "
                                formControlName="firstName"
                            />
                            <p *ngIf="isFieldFirstnameEmpty(i)" class="sidebar__form-eror m-0">
                                {{ 'Dashboard.Overlay.Errors.Required' | translate }}
                            </p>
                            <p *ngIf="isFieldFirstnameInvalid(i)" class="sidebar__form-eror m-0">
                                {{ 'Dashboard.Overlay.Errors.OnlyLatinSymbols' | translate }}
                            </p>
                            <p
                                *ngIf="checkCollegueErrorField(i, 'FirstName')"
                                class="sidebar__form-eror m-0"
                            >
                                {{ getCollegueErrorField(i, 'FirstName') }}
                            </p>
                        </fieldset>
                        <fieldset class="sidebar__form-item inputEmail">
                            <input
                                [id]="'inputEmail_' + i"
                                type="email"
                                [placeholder]="'Dashboard.Overlay.PlaceHolder.Email' | translate"
                                class="sidebar__form-field"
                                [class.sidebar__form-field_error]="
                                    isFieldEmailInvalid(i) || checkCollegueErrorField(i, 'Email')
                                "
                                formControlName="email"
                            />
                            <p *ngIf="isFieldEmailInvalid(i)" class="sidebar__form-eror m-0">
                                {{ 'Dashboard.Overlay.Errors.Email' | translate }}
                            </p>
                            <p
                                *ngIf="checkCollegueErrorField(i, 'Email')"
                                class="sidebar__form-eror m-0"
                            >
                                {{ getCollegueErrorField(i, 'Email') }}
                            </p>
                        </fieldset>
                        <fieldset class="sidebar__form-item inputRelationType">
                            <ng-select
                                [items]="relationTypes | keyvalue"
                                [id]="'inputRelationType_' + i"
                                [bindValue]="'value'"
                                [bindLabel]="'key'"
                                formControlName="relation"
                                [class.ng-select_error]="
                                    isFieldRelationInvalid(i) ||
                                    checkCollegueErrorField(i, 'Relation')
                                "
                                [placeholder]="'Dashboard.Overlay.PlaceHolder.Relation' | translate"
                            >
                            </ng-select>
                            <p *ngIf="isFieldRelationInvalid(i)" class="sidebar__form-eror m-0">
                                {{ 'Dashboard.Overlay.Errors.NoRelation' | translate }}
                            </p>
                            <p
                                *ngIf="checkCollegueErrorField(i, 'Relation')"
                                class="sidebar__form-eror m-0"
                            >
                                {{ getCollegueErrorField(i, 'Relation') }}
                            </p>
                        </fieldset>
                        <button
                            *ngIf="!isDisabled(i) && i >= 3"
                            class="close-field p-0"
                            type="button"
                            (click)="deleteColleague(i)"
                        ></button>
                    </ng-container>
                </div>
                <div *ngIf="colleagues.length < 7" class="row">
                    <p
                        class="sidebar__add-row"
                        (click)="clearAddCollegueRestrictionPopup(); addColleague()"
                    >
                        {{ 'Dashboard.Overlay.AddColleague' | translate }}
                    </p>
                </div>
                <div *ngIf="colleagues.length >= 7" class="row">
                    <p
                        id="addCollegueTrigger"
                        class="sidebar__add-row-disabled"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="You have already invited 7 colleagues"
                        (click)="toggleAddCollegueRestrictionPopup()"
                    >
                        {{ 'Dashboard.Overlay.AddColleague' | translate }}
                    </p>
                </div>
                <aihr-error-message
                    *ngIf="backendErrors"
                    [errors]="backendErrors"
                    [exceptions]="['Colleagues', 'SpecializationId']"
                >
                </aihr-error-message>
                <fieldset id="section-share__input-field" class="sidebar__form-item inputEmail">
                    <label class="sidebar__form-label" for="emailManager"
                        >Optionally: Share my results dashboard with manager</label
                    >
                    <input
                        id="emailManager"
                        type="email"
                        [placeholder]="'Dashboard.Overlay.PlaceHolder.Email' | translate"
                        class="sidebar__form-field"
                        formControlName="managerEmail"
                    />
                </fieldset>
                <div class="sidebar__wrap-btn mt-4">
                    <button
                        class="btn-main btn btn-warning m-left"
                        type="submit"
                        [disabled]="isLoading$ | async"
                        *ngIf="colleagues?.length <= 7 && oldColleagues?.length < 7"
                    >
                        {{ 'Dashboard.Overlay.Invite' | translate }}
                    </button>
                </div>
            </fieldset>
        </form>
    </div>
</div>
<div *ngIf="isB2b">
    <div class="sidebar__body d-grid gap-3">
        <div class="main-title-wrap">
            <h1 class="main-title fw-bold">{{ 'B2B.Overlay.Header' | translate }}</h1>
            <p class="title-description fw-semibold p-0 m-0">
                {{ 'B2B.Overlay.Message' | translate }}
            </p>
        </div>
        <div class="step-instruction row justify-content-center">
            <div class="step-instruction__item col-md-4 col-sm-6">
                <div class="step-instruction__icon">
                    <div class="step-instruction__icon-step">1</div>
                    <img src="/assets/images/sidebar-email_invitation.png" alt="" />
                </div>
                <div class="step-instruction__description">
                    <p class="step-instruction__description-title">
                        {{ 'Dashboard.Overlay.Instruction.Step1' | translate }}
                    </p>
                    <p>{{ 'B2B.Overlay.Instruction.Message1' | translate }}</p>
                </div>
            </div>
            <div class="step-instruction__item col-md-4 col-sm-6">
                <div class="step-instruction__icon">
                    <div class="step-instruction__icon-step">2</div>
                    <img src="/assets/images/sidebar-feedback_collection.png" alt="" />
                </div>
                <div class="step-instruction__description">
                    <p class="step-instruction__description-title">
                        {{ 'Dashboard.Overlay.Instruction.Step2' | translate }}
                    </p>
                    <p>{{ 'B2B.Overlay.Instruction.Message2' | translate }}</p>
                </div>
            </div>
            <div class="step-instruction__item col-md-4 col-sm-6">
                <div class="step-instruction__icon">
                    <div class="step-instruction__icon-step">3</div>
                    <img src="/assets/images/sidebar-dashboard_insights.png" alt="" />
                </div>
                <div class="step-instruction__description">
                    <p class="step-instruction__description-title">
                        {{ 'Dashboard.Overlay.Instruction.Step3' | translate }}
                    </p>
                    <p>{{ 'B2B.Overlay.Instruction.Message3' | translate }}</p>
                </div>
            </div>
        </div>

        <form class="sidebar__form sidebar__form-b2b" [formGroup]="form" (ngSubmit)="send()">
            <p class="sidebar__form-label">{{ 'Dashboard.Overlay.Colleagues' | translate }}</p>

            <fieldset>
                <div
                    formArrayName="team"
                    *ngFor="let colleague of team.controls; let i = index"
                    class="d-flex justify-content-lg-start justify-content-sm-between flex-md-nowrap flex-sm-wrap sidebar__form-row-wrap"
                >
                    <ng-container [formGroupName]="i">
                        <fieldset class="sidebar__form-item firstname">
                            <input
                                [id]="'firstname_' + i"
                                type="text"
                                [placeholder]="
                                    'Dashboard.Overlay.PlaceHolder.Firstname' | translate
                                "
                                class="sidebar__form-field"
                                [class.sidebar__form-field_error]="
                                    isFieldFirstnameEmpty(i) ||
                                    isFieldFirstnameInvalid(i) ||
                                    checkCollegueErrorField(i, 'FirstName')
                                "
                                formControlName="firstName"
                            />
                            <p *ngIf="isFieldFirstnameEmpty(i)" class="sidebar__form-eror m-0">
                                {{ 'Dashboard.Overlay.Errors.Required' | translate }}
                            </p>
                            <p *ngIf="isFieldFirstnameInvalid(i)" class="sidebar__form-eror m-0">
                                {{ 'Dashboard.Overlay.Errors.OnlyLatinSymbols' | translate }}
                            </p>
                            <p
                                *ngIf="checkCollegueErrorField(i, 'FirstName')"
                                class="sidebar__form-eror m-0"
                            >
                                {{ getCollegueErrorField(i, 'FirstName') }}
                            </p>
                        </fieldset>
                        <fieldset class="sidebar__form-item inputEmail">
                            <input
                                [id]="'inputEmail_' + i"
                                type="email"
                                [placeholder]="'Dashboard.Overlay.PlaceHolder.Email' | translate"
                                class="sidebar__form-field"
                                [class.sidebar__form-field_error]="
                                    isFieldEmailInvalid(i) || checkCollegueErrorField(i, 'Email')
                                "
                                formControlName="email"
                            />
                            <p *ngIf="isFieldEmailInvalid(i)" class="sidebar__form-eror m-0">
                                {{ 'Dashboard.Overlay.Errors.Email' | translate }}
                            </p>
                            <p
                                *ngIf="checkCollegueErrorField(i, 'Email')"
                                class="sidebar__form-eror m-0"
                            >
                                {{ getCollegueErrorField(i, 'Email') }}
                            </p>
                        </fieldset>
                        <fieldset class="sidebar__form-item inputRelationType">
                            <ng-select
                                [items]="specializationNames$ | async"
                                bindLabel="name"
                                bindValue="id"
                                formControlName="specializationId"
                                [class.ng-select_error]="isFieldSpecializationInvalid(i)"
                                [placeholder]="'B2B.Overlay.PlaceHolder.Specialization' | translate"
                            >
                            </ng-select>
                            <p
                                *ngIf="isFieldSpecializationInvalid(i)"
                                class="sidebar__form-eror m-0"
                            >
                                {{ 'Dashboard.Overlay.Errors.NoSpecialization' | translate }}
                            </p>
                            <p
                                *ngIf="checkCollegueErrorField(i, 'Relation')"
                                class="sidebar__form-eror m-0"
                            >
                                {{ getCollegueErrorField(i, 'Relation') }}
                            </p>
                        </fieldset>
                        <button
                            class="close-field p-0"
                            type="button"
                            (click)="deleteMember(i)"
                            *ngIf="!isDisabled(i) && i >= 3"
                        ></button>
                    </ng-container>
                </div>
                <aihr-error-message
                    *ngIf="backendErrors"
                    [errors]="backendErrors"
                    [exceptions]="['TEAM', 'SpecializationId']"
                >
                </aihr-error-message>
                <div class="row">
                    <p class="sidebar__add-row" (click)="addTeamMember()">
                        {{ 'Dashboard.Overlay.AddColleague' | translate }}
                    </p>
                </div>
                <div class="sidebar__wrap-btn mt-4 d-flex justify-content-end">
                    <button class="btn-main btn btn-warning m-left" type="button" (click)="send()">
                        {{ 'B2B.Overlay.Submit' | translate }}
                    </button>
                </div>
            </fieldset>
        </form>
    </div>
</div>
