<div class="container-fluid">
    <div class="container dashboard-page d-grid gap-5">
        <div
           class="main-banner">
        <div class="row align-items-center flex-lg-nowrap flex-md-wrap">
          <div
               class="main-banner__icon d-flex justify-content-lg-start justify-content-sm-center justify-content-center col-lg-2 col-md-4 col-sm-12 mb-lg-0 mb-3 p-0">
            <img src="/assets/images/main-banner-logo.png"
                 alt="" />
          </div>
          <div class="col-lg-6 col-md-7 col-sm-12 p-0">
            <div class="main-banner__title-wrap">
              <p class="main-banner__label fw-semibold">
                <span class="main-banner__label_color fw-bolder">HRI</span>ntelligence 360 Assessment
              </p>
              <p class="main-banner__title fw-semibold m-0">
                {{ 'B2B.Banner.Start.Message1' | translate }}
              </p>
              <p class="main-banner__description fw-normal m-0">
                {{ 'B2B.Banner.Start.Message2' | translate }}
              </p>
            </div>
          </div>
          <div
               class="main-banner__wrap-btn d-flex justify-content-lg-end justify-content-md-center justify-content-center col-lg-4 col-md-12 mt-lg-0 mt-sm-3 mt-3">
            <button class="btn btn-lg btn-main"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvas"
                    role="button">
              {{ 'Dashboard.Banner.Start.StartNow' | translate }}
            </button>
          </div>
        </div>
        </div>
        <div 
           class="main-banner main-banner-wait container">
            <div class="main-banner-wait__wrap">
            <div
                class="row align-items-center flex-lg-nowrap flex-md-wrap justify-content-lg-start justify-content-md-between justify-content-sm-center">
                <div
                    class="main-banner__icon d-flex justify-content-lg-start justify-content-sm-center justify-content-center col-lg-5 col-md-4 col-sm-8 mb-lg-0 mb-3 p-0">
                <img src="/assets/images/main-banner-wait.png"
                    alt="" />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-12 p-0 align-self-start">
                <div class="main-banner__title-wrap">
                    <p class="main-banner__label fw-semibold">
                    <span class="main-banner__label_color fw-bolder">HRI</span>ntelligence 360 Assessment
                    </p>
                    <p class="main-banner__title fw-semibold m-0">
                    {{ 'B2B.Banner.Wait.Message1' | translate }}
                    </p>
                    <p class="main-banner__description fw-normal m-0">
                    {{ 'B2B.Banner.Wait.Message2' | translate }}
                    </p>
                    <div class="main-banner-wait__countdown-wrap d-flex">
                    <div class="answered d-flex flex-column text-center ms-10">
                        <div class="answered__quantity">
                        <span>2</span>/<span>15</span>
                        </div>
                        <p class="answered__title m-0">{{ 'B2B.Banner.Wait.Completed' | translate }}</p>
                    </div>
                    </div>
                    <div
                        class="main-banner__wrap-btn d-flex justify-content-md-start justify-content-center col-lg- col-md-12 mt-lg-0 mt-sm-3 mt-3">
                    <button class="btn btn-lg btn-main"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvas"
                            role="button">
                        {{ 'Dashboard.Banner.Wait.AddInvite' | translate }}
                    </button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div>
            <div class="main-title-wrap">
              <h1 class="main-title fw-bold">{{ 'B2B.Header' | translate }}</h1>
            </div>
            <div class="ankors d-flex flex-wrap">
              <a 
                 class="btn ankors__item section-label">{{ 'Dashboard.Summary.Link' | translate }}</a>
              <a 
                 class="btn ankors__item section-label">{{ 'Dashboard.ResultsOverview.Link' | translate }}</a>
              <a 
                 class="btn ankors__item section-label">{{ 'Dashboard.BlindSpots.Link' | translate }}</a>
              <a 
                 class="btn ankors__item section-label">{{ 'B2B.StrengthsWeaknesses.Link' | translate }}</a>
            </div>
            <span id="summary"></span>
        </div>
        <div class="section section-filter">
            <div class="d-flex align-items-baseline">
                <h4 class="title-section">{{ 'B2B.Filter.Header' | translate }}</h4>
                <div class="tooltip-wrap">
                    <div
                      class="tooltip-wrap__item d-flex align-items-center justify-content-center"
                    >
                      <img src="/assets/images/svg/questionMark.svg" alt="" />
                    </div>
                    <div class="tooltip-wrap__message">
                        Only shows roles with 3+ completed assessments
                    </div>
                  </div>
            </div>
            <hr>
            <div class="d-flex align-items-end section-share__wrap-item">
                <div class="section-filter__select-wrap">
                    <ng-select 
                               placeholder="Select functional specialization">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="section section-kpi">
            <div class="section-kpi__wrap d-flex flex-row justify-content-between gap-4">
                <ul class="mark-container d-flex flex-column justify-content-between style-item">
                    <li class="d-flex mark-container__item align-items-center"><span class="mark">15</span><span class="mark-legend">Invited Colleagues</span>
                         <div class="section-kpi__btn d-flex">
                             <img src="/assets/images/svg/click-to-see.svg" alt="icon-click-to-see">
                             <span>Click to see breakdown</span>
                         </div>
                    </li>
                    <li class="d-flex mark-container__item align-items-center"><span class="mark">8</span><span class="mark-legend">Completed 360 Assessments</span></li>
                    <li class="d-flex mark-container__item align-items-center"><span class="mark">3.5</span><span class="mark-legend">Average number of colleagues who provided feedback</span></li>
                </ul>
                <ul class="mark-container d-flex flex-column justify-content-between style-item">
                    <li class="d-flex mark-container__item"><span class="mark mark_expert">1.3</span><span class="mark-legend">Average number of competencies scored as high (incl specialization)</span></li>
                    <li class="d-flex mark-container__item"><span class="mark mark_proficient">2.4</span><span class="mark-legend">Average number of competencies scored as average (incl specialization)</span></li>
                    <li class="d-flex mark-container__item"><span class="mark mark_novice">1.2</span><span class="mark-legend">Average number of competencies scored as low (incl specialization)</span></li>
                </ul>
            </div>
            <div class="style-item kpi-table d-grid gap-4">
                <div class="kpi-table__close-btn"></div>
                <div class="kpi-table__item">
                    <div class="kpi-table__header d-flex">
                        <p class="kpi-table__header-item kpi-table__row-mail m-0">Email: </p>
                        <p class="kpi-table__header-item m-0">Status:</p>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row-status_invited">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row-status_invited">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row-status_invited">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row_started">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row_started">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row_started">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                </div>
                <div class="kpi-table__item">
                    <div class="kpi-table__header d-flex">
                        <p class="kpi-table__header-item kpi-table__row-mail m-0">Email: </p>
                        <p class="kpi-table__header-item m-0">Status:</p>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="section section-kpi">
            <div class="section-kpi__wrap d-flex flex-row justify-content-between gap-4">
                <ul class="mark-container d-flex flex-column justify-content-between style-item">
                    <li class="d-flex mark-container__item align-items-center"><span class="mark">15</span><span class="mark-legend">Invited Colleagues</span>
                         <div class="section-kpi__btn d-flex">
                             <img src="/assets/images/svg/click-to-see.svg" alt="icon-click-to-see">
                             <span>Click to see breakdown</span>
                         </div>
                    </li>
                    <li class="d-flex mark-container__item align-items-center"><span class="mark">8</span><span class="mark-legend">Completed 360 Assessments</span></li>
                    <li class="d-flex mark-container__item align-items-center"><span class="skeleton_color" style="width:40px; height:32px; margin-right: 10px;"></span><span class="skeleton_color" style="width:200px; height:20px;"></span></li>
                </ul>
                <ul class="mark-container d-flex flex-column justify-content-between style-item">
                    <li class="d-flex mark-container__item align-items-center"><span class="skeleton_color" style="width:40px; height:32px; margin-right: 10px;"></span><span class="skeleton_color" style="width:200px; height:20px;"></span></li>
                    <li class="d-flex mark-container__item align-items-center"><span class="skeleton_color" style="width:40px; height:32px; margin-right: 10px;"></span><span class="skeleton_color" style="width:200px; height:20px;"></span></li>
                    <li class="d-flex mark-container__item align-items-center"><span class="skeleton_color" style="width:40px; height:32px; margin-right: 10px;"></span><span class="skeleton_color" style="width:200px; height:20px;"></span></li>
                </ul>
            </div>
            <div class="style-item kpi-table d-grid gap-4">
                <div class="kpi-table__close-btn"></div>
                <div class="kpi-table__item">
                    <div class="kpi-table__header d-flex">
                        <p class="kpi-table__header-item kpi-table__row-mail m-0">Email: </p>
                        <p class="kpi-table__header-item m-0">Status:</p>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row-status_invited">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row-status_invited">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row-status_invited">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row_started">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row_started">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="kpi-table__row_started">Invited, not started</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                </div>
                <div class="kpi-table__item">
                    <div class="kpi-table__header d-flex">
                        <p class="kpi-table__header-item kpi-table__row-mail m-0">Email: </p>
                        <p class="kpi-table__header-item m-0">Status:</p>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                    <div class="kpi-table__row d-flex">
                        <div class="kpi-table__row-mail">email@aihr.com</div>
                        <div class="">Completed</div>
                        <div class="kpi-table__row-delete"><img src="/assets/images/svg/delete-trash.svg" alt=""></div>
                    </div>
                </div>
            </div>
        </div> -->
        
        <!--  pop-up-delete-user -->
        <section class="pop-up-delete-user">
            <div class="pop-up-delete-user__close-btn"></div>
            <div class="delete">
                <h2 class="pop-up-delete-user__title">Delete this user?</h2>
                <p class="pop-up-delete-user__subtitle mt-3">Are you sure you want to delete this user?</p>
            </div>
            <div class="confirmation">
                <p class="pop-up-delete-user__subtitle mb-3">Confirmation </p>
                <h2 class="pop-up-delete-user__title mb-4">Are you sure you want to delete this user?</h2>
            </div>
            <div class="pop-up-delete-user__btn-wrap d-flex justify-content-between">
                <button class="btn-delete btn-delete_no">No</button>
                <button class="btn-delete btn-delete_yes">Yes</button>
            </div>
        </section>
    </div>
</div>