import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { IPromptAnswer } from '../../types/promptAnswer.interface';
import { saveAnswersAction } from '../../store/actions/sync.action';
import { promptAnswersSelector } from '../../store/selectors';
import { ICompetencyInfo } from '../../types/competencyInfo.interface';
import { PROGRESS_STEP } from 'src/app/shared/constants/polling';

@Component({
    selector: 'aihr-polling',
    templateUrl: './polling.component.html',
    styleUrls: ['./polling.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class PollingComponent implements OnInit, OnDestroy {
    @Input('competencies') public competenciesProps: ICompetencyInfo[];
    public promptAnswersSubscription: Subscription;
    public promptAnswers: IPromptAnswer[];

    @Output('whenDone') whenDoneEvent = new EventEmitter();

    public currentCompetency: ICompetencyInfo | null;
    public progress: number = 0;
    progressStep: number;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.progressStep = 100 / this.competenciesProps?.length;
        this.currentCompetency = this.competenciesProps[0];
        this.promptAnswersSubscription = this.store
            .pipe(select(promptAnswersSelector))
            .subscribe((prompts: IPromptAnswer[]) => {
                this.promptAnswers = prompts;
            });
    }

    public ngOnDestroy(): void {
        this.promptAnswersSubscription.unsubscribe();
    }

    public onNext(newAnswers: { [key: string]: { mark: string | null } }): void {
        let promptAnswers: IPromptAnswer[] = [];
        this.promptAnswers.forEach((val) => promptAnswers.push(Object.assign({}, val)));
        Object.entries(newAnswers).forEach(([key, value]) =>
            promptAnswers.push({
                id: +key,
                mark: value.mark != 'null' ? +value.mark : null,
            } as IPromptAnswer)
        );
        promptAnswers.sort((x, y) => y.mark - x.mark);
        this.store.dispatch(saveAnswersAction({ promptAnswers: promptAnswers }));

        this.progress += this.progressStep;
        let nextIndex = this.competenciesProps.indexOf(this.currentCompetency) + 1;
        this.currentCompetency = this.competenciesProps[nextIndex];
        if (!this.currentCompetency) {
            this.whenDoneEvent.emit();
        }
    }
}
