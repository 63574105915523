<aihr-error-message
    *ngIf="(errors$ | async) && !dataExists"
    [errors]="errors$ | async"
></aihr-error-message>
<aihr-loading *ngIf="(isLoading$ | async) && !dataExists"> </aihr-loading>
<div
    *ngIf="dataExists"
    class="row justify-content-between"
    [ngClass]="{ 'summary-wrap_b2b': isB2b }"
>
    <div class="block-competency">
        <p class="section-label">{{ 'Dashboard.Summary.Scores' | translate }}</p>
        <div class="style-item summary-wrap scors-wrap">
            <div class="summary-wrap__block row d-flex">
                <div class="scale d-flex flex-column justify-content-start align-self-end p-0">
                    <div class="scale__item d-flex flex-row mt-2 align-items-center">
                        <p class="scale__item-title mb-0">{{ 'Scale.Expert' | translate }} - 7</p>
                        <span class="scale__item-color scale__item-color_expert"></span>
                    </div>
                    <div class="scale__item d-flex flex-row align-items-center">
                        <p class="scale__item-title mb-0">
                            {{ 'Scale.Proficient' | translate }} - 4
                        </p>
                        <span class="scale__item-color scale__item-color_proficient"></span>
                    </div>
                    <div class="scale__item d-flex flex-row align-items-center">
                        <p class="scale__item-title mb-0">{{ 'Scale.Novice' | translate }} - 1</p>
                        <span class="scale__item-color scale__item-color_novice"></span>
                    </div>
                </div>
                <div class="summary-chart d-flex justify-content-between">
                    <div class="competencies summary-chart__wrap-progress d-flex">
                        <p class="summary-chart__title">
                            {{ 'Dashboard.Summary.Core' | translate }}
                        </p>
                        <div
                            class="summary-chart__progress progress"
                            *ngFor="let mark of summary?.core; let i = index"
                        >
                            <div
                                class="summary-chart__progress-bar progress-bar"
                                role="progressbar"
                                [style.height.%]="(mark.value * 100) / 7"
                                (click)="handlePrompts(i, false, false, summary?.core)"
                            >
                                <p class="summary-chart__progress-label">
                                    {{ mark.value | number : '1.1-1' }}
                                </p>
                                <p class="summary-chart__progress-title">{{ mark.name }}</p>

                                <!-- tooltip for summary_b2b -->
                                <div
                                    class="summary-chart-tooltip tooltip-wrap__message"
                                    *ngIf="isB2b && mark.distribution?.length > 0"
                                >
                                    <p class="m-0 summary-chart-tooltip__title">{{ mark.name }}</p>
                                    <p class="mb-2">
                                        {{ mark.definition }}
                                    </p>
                                    <p class="m-0 summary-chart-tooltip__title">Distribution</p>
                                    <div class="tooltip-wrap-grogress">
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[5] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_expert"
                                                    [style.width.%]="mark.distribution[5]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[4] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_expert"
                                                    [style.width.%]="mark.distribution[4]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[3] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_proficient"
                                                    [style.width.%]="mark.distribution[3]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[2] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_proficient"
                                                    [style.width.%]="mark.distribution[2]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[1] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_novice"
                                                    [style.width.%]="mark.distribution[1]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[0] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_novice"
                                                    [style.width.%]="mark.distribution[0]"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- tooltip for summary b2C -->
                                <div
                                    class="summary-chart-tooltip tooltip-wrap__message summary-chart-tooltip_b2c"
                                    *ngIf="!isB2b"
                                >
                                    {{ mark.definition }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="specialization summary-chart__wrap-progress d-flex justify-content-end"
                    >
                        <p class="summary-chart__title">
                            {{ 'Dashboard.Summary.Specialization' | translate }}
                        </p>
                        <div
                            class="summary-chart__progress progress"
                            *ngFor="let mark of summary?.notCore; let i = index"
                        >
                            <div
                                class="summary-chart__progress-bar summary-chart__progress-bar_specialization progress-bar"
                                role="progressbar"
                                [style.height.%]="(mark.value * 100) / 7"
                                (click)="handlePrompts(i, false, false, summary?.notCore)"
                            >
                                <p class="summary-chart__progress-label">
                                    {{ mark.value | number : '1.1-1' }}
                                </p>
                                <p class="summary-chart__progress-title">{{ mark.name }}</p>
                                <!-- tooltip for summary b2C -->
                                   <div
                                    class="summary-chart-tooltip tooltip-wrap__message"
                                    *ngIf="isB2b && mark.distribution?.length > 0"
                                >
                                    <p class="mb-2">
                                        {{ mark.definition }}
                                    </p>
                                    <p class="m-0 summary-chart-tooltip__title">Distribution</p>
                                    <div class="tooltip-wrap-grogress">
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[5] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_expert"
                                                    [style.width.%]="mark.distribution[5]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[4] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_expert"
                                                    [style.width.%]="mark.distribution[4]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[3] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_proficient"
                                                    [style.width.%]="mark.distribution[3]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[2] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_proficient"
                                                    [style.width.%]="mark.distribution[2]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[1] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_novice"
                                                    [style.width.%]="mark.distribution[1]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[0] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_novice"
                                                    [style.width.%]="mark.distribution[0]"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="summary-chart-tooltip tooltip-wrap__message summary-chart-tooltip_b2c"
                                >
                                    {{ mark.definition }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="hr summary-chart__wrap-progress d-flex justify-content-end"
                        *ngIf="summary?.impact?.length"
                    >
                        <p class="summary-chart__title">
                            {{ 'Dashboard.Summary.HR' | translate }}
                        </p>
                        <div
                            class="summary-chart__progress progress"
                            *ngFor="let mark of summary?.impact; let i = index"
                        >
                            <div
                                class="summary-chart__progress-bar summary-chart__progress-bar_hr progress-bar"
                                [style.height.%]="(mark.value * 100) / 7"
                                (click)="handlePrompts(i, false, false, summary?.impact)"
                            >
                                <p class="summary-chart__progress-label">
                                    {{ mark.value | number : '1.1-1' }}
                                </p>
                                <p class="summary-chart__progress-title">{{ mark.name }}</p>
                                <!-- tooltip for summary b2b -->
                                <div
                                    class="summary-chart-tooltip tooltip-wrap__message"
                                    *ngIf="isB2b && mark.distribution?.length > 0"
                                >
                                    <p class="m-0 summary-chart-tooltip__title">{{ mark.name }}</p>
                                    <p class="mb-2">
                                        {{ mark.definition }}
                                    </p>
                                    <p class="m-0 summary-chart-tooltip__title">Distribution</p>
                                    <div class="tooltip-wrap-grogress">
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[5] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_expert"
                                                    [style.width.%]="mark.distribution[5]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[4] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_expert"
                                                    [style.width.%]="mark.distribution[4]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[3] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_proficient"
                                                    [style.width.%]="mark.distribution[3]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[2] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_proficient"
                                                    [style.width.%]="mark.distribution[2]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[1] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_novice"
                                                    [style.width.%]="mark.distribution[1]"
                                                ></div>
                                            </div>
                                        </div>
                                        <div class="tooltip-wrap-grogress__item d-flex">
                                            <p class="tooltip-wrap-grogress__label m-0">
                                                {{ mark.distribution[0] | number : '1.1-1' }}%
                                            </p>
                                            <div class="progress-bar">
                                                <div
                                                    class="progress progress_novice"
                                                    [style.width.%]="mark.distribution[0]"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- tooltip for summary b2C -->
                                <div
                                    class="summary-chart-tooltip tooltip-wrap__message summary-chart-tooltip_b2c"
                                >
                                    {{ 'Dashboard.Summary.tooltipHr' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Breakdown block  -->
    <div class="block-breakdown">
        <p class="section-label">{{ 'Dashboard.Summary.Prompts' | translate }}</p>
        <div
            class="dimention-box style-item"
            *ngIf="breakdown"
            [ngClass]="getBreakdownClassNames(breakdown)"
        >
            <button class="btn-back" (click)="onBackClick(breakdown)" [hidden]="!breakdown.isMark">
                Back to {{ backButtonTitle }}
            </button>
            <div class="dimention-box__title-wrap">
                <p class="section-label">{{ breakdown?.name }}</p>
                <p class="dimention-box__description">
                    {{ breakdown?.definition }}
                </p>
            </div>
            <div class="progress-wrap d-flex flex-column justify-content-top">
                <div
                    class="progress"
                    *ngFor="let dimention of breakdown?.dimentionMarks; let i = index"
                    (click)="breakdown.isMark ? null : onDimentionClick(i, dimention, breakdown)"
                >
                    <p class="progress__label">
                        <span>
                            {{ dimention.value | number : '1.1-1' }} - {{ dimention.name }}</span
                        >
                    </p>
                    <div
                        class="progress-bar"
                        role="progressbar"
                        [style.width.%]="(dimention.value * 100) / 7"
                    >
                        <div class="tooltip-wrap__message" *ngIf="dimention.definition">
                            {{ dimention.definition }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="scale d-flex flex-row justify-content-between">
                <div class="scale__item">
                    <span class="scale__item-color scale__item-color_novice mb-2"></span>
                    <p class="scale__item-title text-start">1</p>
                </div>
                <div class="scale__item">
                    <span class="scale__item-color scale__item-color_proficient mb-2"></span>
                    <p class="scale__item-title text-center">4</p>
                </div>
                <div class="scale__item">
                    <span class="scale__item-color scale__item-color_expert mb-2"></span>
                    <p class="scale__item-title text-end">7</p>
                </div>
            </div>
        </div>
    </div>
    <div></div>
</div>
