import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IEntityName } from 'src/app/shared/modules/polling/types/entityName.interface';

import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import { DEFAULT_ID } from '../../constants/skill-gaps';
import { getRolesAction } from '../../store/actions/getRoles.action';
import { getSkillGapsAction } from '../../store/actions/getSkillGapsData.action';
import {
    errorSelector,
    firstSkillGapsSelector,
    isLoadingSelector,
    roleNamesSelector,
    secondSkillGapselector,
} from '../../store/selectors';
import { SectionType } from '../../types/sectionType.enum';
import { ISkillGapsDimentionMark } from '../../types/skillGapsDimention.interface';

@Component({
    selector: 'aihr-dashboard-skill-gaps',
    templateUrl: './dashboard-skill-gaps.component.html',
    styleUrls: ['./dashboard-skill-gaps.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardSkillGapsComponent implements OnInit, OnDestroy {
    public isLoading$: Observable<boolean>;
    public roleNames$: Observable<IEntityName[] | null>;
    public dataFirstSubscription: Subscription;
    public dataSecondSubscription: Subscription;
    public firstSkillGap: ISkillGapsDimentionMark[] | null;
    public secondSkillGap: ISkillGapsDimentionMark[] | null;
    public errors$: Observable<IBackendErrors | null>;

    public firstRoleId: number = DEFAULT_ID;
    public secondRoleId: number = DEFAULT_ID;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.initializeData();
    }

    private initializeData(): void {
        this.isLoading$ = this.store.pipe(select(isLoadingSelector));
        this.roleNames$ = this.store.pipe(select(roleNamesSelector));
        this.dataFirstSubscription = this.store
            .pipe(select(firstSkillGapsSelector))
            .subscribe((data: ISkillGapsDimentionMark[]) => {
                this.firstSkillGap = data;
            });
        this.dataSecondSubscription = this.store
            .pipe(select(secondSkillGapselector))
            .subscribe((data: ISkillGapsDimentionMark[]) => {
                this.secondSkillGap = data;
            });
        this.errors$ = this.store.pipe(select(errorSelector));

        this.store.dispatch(getRolesAction());
    }

    public ngOnDestroy(): void {
        this.dataFirstSubscription.unsubscribe();
        this.dataSecondSubscription.unsubscribe();
    }

    public changeFirstSkillGap(): void {
        this.store.dispatch(
            getSkillGapsAction({ section: SectionType.First, roleId: this.firstRoleId })
        );
    }

    public changeSecondSkillGap(): void {
        this.store.dispatch(
            getSkillGapsAction({ section: SectionType.Second, roleId: this.secondRoleId })
        );
    }

    public countGap(dimension: ISkillGapsDimentionMark): number {
        return dimension.memberMark - dimension.roleBenchmark;
    }
}
