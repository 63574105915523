import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartComponent } from './components/start/start.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';




@NgModule({
  declarations: [
  
    StartComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgSelectModule
  ]
})
export class B2bModule { }
