<header>
    <nav class="aihr-navbar aihr-navbar_fixed d-flex justify-content-end align-items-center">
        <div class="">
            <div class="aihr-navbar__logo">
                <img src="/assets/images/svg/AIHR_Horizontal_Logo.svg" width="144px" height="40px" alt="logo" />
            </div>
            <div *ngIf="this.service.isAuthenticated$ | async" class="d-flex align-items-center">
                <p class="text-light m-0" role="button" (click)="logOut()">
                    {{ 'Navbar.Logout' | translate }}
                </p>
            </div>
            <div *ngIf="!(this.service.isAuthenticated$ | async)" class="d-flex align-items-center">
                <p class="hidden m-0">Logout</p>
            </div>
        </div>
    </nav>
</header>
