import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { DashboardService } from '../../services/dashboard.service';
import { getSummaryAction, getSummarySuccessAction } from '../actions/getSummaryData.action';
import { ISummaryRequest } from '../../types/summaryRequest.interface';
import { ErrorHandlerService } from 'src/app/core/services/errorHandler.service';
import { appErrorAction } from 'src/app/store/actions/sync.action';

@Injectable()
export class GetSummaryDataEffect {
    public getSummaryData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSummaryAction),
            switchMap((action) => {
                return this.dashboardService.getSummary(action.isB2b).pipe(
                    map((data: ISummaryRequest) => {
                        return getSummarySuccessAction({ summary: data });
                    }),
                    catchError((error: Error) => {
                        return of(appErrorAction({ error: this.errorHandler.covertError(error) }));
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private dashboardService: DashboardService,
        private errorHandler: ErrorHandlerService
    ) {}
}
