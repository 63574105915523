import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';

@Component({
    selector: 'aihr-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class ErrorMessageComponent implements OnInit {
    @Input('errors') public errorsProps: IBackendErrors;
    @Input('exceptions') public exceptionProps: string[] = [];

    public errorMessages: string[] = [];

    public ngOnInit(): void {
        if (this.errorsProps.errorsList != null) {
            Object.entries(this.errorsProps.errorsList).forEach(([key, value]) => {
                if (this.exceptionProps.filter((x) => key.includes(x)).length == 0) {
                    this.errorMessages.push(`${key}: ${value.join(' ')}`);
                }
            });
        }
    }
}
