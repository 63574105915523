import { createAction, props } from '@ngrx/store';

import { IColleagueInfo } from '../../types/colleagueInfo.interface';
import { ActionTypes } from '../actionTypes';

export const getColleaguesAction = createAction(
    ActionTypes.GET_COLLEAGUES
);

export const getColleaguesSuccessAction = createAction(
    ActionTypes.GET_COLLEAGUES_SUCCESS,
    props<{ colleagues: IColleagueInfo[] | null }>()
);
