import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { interval, Observable, Subject, take, takeUntil, timer, tap, takeWhile, map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

import { getStatusAction } from '../../store/actions/getStatus.action';
import { errorSelector, statusSelector } from '../../store/selectors';
import { IStatusInfo } from '../../types/statusInfo.interface';
import { DashboardService } from '../../services/dashboard.service';
import { getSummaryAction } from '../../store/actions/getSummaryData.action';
import { getSkillMatrixAction } from '../../store/actions/getSkillMatrixData.action';
import { getBlindSpotsAction } from '../../store/actions/getBlindSpotsData.action';
import { getSkillGapsAction } from '../../store/actions/getSkillGapsData.action';
import { getFeedbackAction } from '../../store/actions/getFeedbackData.action';
import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import {
    HOUR_IN_DAY,
    MILLISEC_IN_SEC,
    REFRESH_STATUS,
    SEC_IN_DAY,
    SEC_IN_HOUR,
    SEC_IN_MIN,
    TIMER_REFRESH,
} from 'src/app/shared/constants/intervals';
import { SectionType } from '../../types/sectionType.enum';
import { DEFAULT_ID } from '../../constants/skill-gaps';
import { B2B_URL } from '../../../../shared/constants/application';
import { IManagerStatusInfo } from '../../types/managerStatusInfo';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardComponent implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();
    public errors$: Observable<IBackendErrors | null>;

    @ViewChild('closeButton', { static: false }) private closeButton: ElementRef | undefined;

    url = 'https://player.vimeo.com/video/76979871?h=8272103f6e';
    url2 = 'https://player.vimeo.com/video/778127447?h=5981f0688f';
    public status: IStatusInfo;
    b2Status: IManagerStatusInfo;
    public stopTimer$: Subject<boolean> = new Subject();
    public difference: {
        days: { value: number; title: string };
        hours: { value: number; title: string };
        minutes: { value: number; title: string };
        seconds: { value: number; title: string };
    };
    public startTimer: boolean;
    public showTimeLeft: boolean;
    sharedEmail: string;
    isLoggedIn = false;
    isLoaded: boolean;
    isB2b: boolean;
    isOpenBlindSpotsTooltip = false;
    isOpenFeedbackTooltip = false;
    isOpenHighestAndLowestPromptsTooltip = false;
    isOpenSummaryTooltip = false;

    constructor(
        private auth: AuthService,
        private route: ActivatedRoute,
        private dashboardService: DashboardService,
        private store: Store,
        private service: DashboardService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.isB2b = this.route.snapshot?.routeConfig?.path === B2B_URL;
        this.auth.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
            this.isLoaded = true;
            this.isLoggedIn = response ? true : false;
        });
        this.dashboardService.tokenValue = this.route.snapshot.paramMap.get('invite');
        this.store.pipe(takeUntil(this.destroy$), select(statusSelector)).subscribe((status) => {
            if (status?.availableAt) {
                this.status = status;
                this.handleStatus();
            }
        });
        this.store.dispatch(getStatusAction());
        this.service.subject.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.closeButton.nativeElement.click();
        });
        this.errors$ = this.store.pipe(select(errorSelector));
    }

    public handleStatus(): void {
        if (!this.status.isMemberVoted) {
            this.router.navigate(['/polling']);
        }

        if (new Date(this.status.availableAt) < new Date()) {
            this.showTimeLeft = false;
            this.stopTimerSubject();
            if (this.status.isReady) {
                this.loadData();
            }
            return;
        }

        if (!this.startTimer) {
            this.getTimeLeft();
        }

        if (!this.startTimer) {
            this.startTimer = true;
            interval(TIMER_REFRESH)
                .pipe(takeUntil(this.stopTimer$))
                .subscribe(() => this.getTimeLeft());
            interval(REFRESH_STATUS)
                .pipe(takeUntil(this.stopTimer$))
                .subscribe(() => this.store.dispatch(getStatusAction()));
        }
    }

    private loadData(): void {
        this.store.dispatch(getSummaryAction({ isB2b: false }));
        this.store.dispatch(getSkillMatrixAction());
        this.store.dispatch(getBlindSpotsAction());
        this.store.dispatch(getSkillGapsAction({ section: SectionType.First, roleId: DEFAULT_ID }));
        this.store.dispatch(
            getSkillGapsAction({ section: SectionType.Second, roleId: DEFAULT_ID })
        );
        this.store.dispatch(getFeedbackAction());
    }

    public getTimeLeft(): void {
        let diffMSeconds =
            Math.abs((new Date(this.status.availableAt) as any) - (new Date() as any)) /
            MILLISEC_IN_SEC;
        const days = Math.floor(diffMSeconds / SEC_IN_DAY);
        diffMSeconds -= days * SEC_IN_DAY;
        const hours = Math.floor(diffMSeconds / SEC_IN_HOUR) % HOUR_IN_DAY;
        diffMSeconds -= hours * SEC_IN_HOUR;
        const minutes = Math.floor(diffMSeconds / SEC_IN_MIN) % SEC_IN_MIN;
        diffMSeconds -= minutes * SEC_IN_MIN;
        const seconds = Math.floor(diffMSeconds);
        this.difference = {
            days: { value: days, title: 'days' },
            hours: { value: hours, title: 'hours' },
            minutes: { value: minutes, title: 'minutes' },
            seconds: { value: seconds, title: 'seconds' },
        };
        if (days === 1) {
            this.difference.days.title = 'day';
        }

        this.difference.hours.title = hours === 1 ? 'hour' : this.difference.hours.title;

        this.difference.minutes.title = minutes === 1 ? 'minute' : this.difference.minutes.title;
        this.difference.seconds.title = seconds === 1 ? 'second' : this.difference.seconds.title;
        this.showTimeLeft = true;
    }

    public stopTimerSubject(): void {
        this.stopTimer$.next(true);
        this.stopTimer$.complete();
    }

    public scrollAnchor(id: string): void {
        document.getElementById(id)?.scrollIntoView();
    }

    shareEmail() {
        this.dashboardService
            .share(this.sharedEmail)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response) => {
                this.sharedEmail = '';
            });
    }

    handleStartButton() {
        this.auth.user$.subscribe((response) => {
            if (!response) {
                this.auth.loginWithRedirect({
                  responseType: 'token',
                  scope: 'openId profile email',
                  redirect_uri: environment.auth.redirectUri,
                  audience: environment.auth.audience,
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
        this.stopTimerSubject();
    }
}
