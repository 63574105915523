import { createSelector } from '@ngrx/store';

import { IAppState } from 'src/app/shared/types/appState.interface';
import { IPollingState } from '../types/pollingState.interface';

export const pollingFeatureSelector = (state: IAppState): IPollingState =>
    state.polling;

export const isLoadingSelector = createSelector(
    pollingFeatureSelector,
    (pollingState: IPollingState) => pollingState.isLoading
);

export const errorSelector = createSelector(
    pollingFeatureSelector,
    (pollingState: IPollingState) => pollingState.errors
);

export const pollingInfoSelector = createSelector(
    pollingFeatureSelector,
    (pollingState: IPollingState) => pollingState.data
);

export const promptAnswersSelector = createSelector(
    pollingFeatureSelector,
    (pollingState: IPollingState) => pollingState.promptAnswers
);

export const isSavedSelector = createSelector(
    pollingFeatureSelector,
    (pollingState: IPollingState) => pollingState.isSaved
);

