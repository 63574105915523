import { createReducer, on, Action } from '@ngrx/store';
import { routerNavigationAction } from '@ngrx/router-store';

import { getStatusAction, getStatusSuccessAction } from './actions/getStatus.action';
import {
    getSpecilizationsAction,
    getSpecilizationsSuccessAction,
} from './actions/getSpecializations.action';
import { getColleaguesAction, getColleaguesSuccessAction } from './actions/getColleagues.action';
import { createPollingAction } from './actions/createRequest.action';
import { addColleaguesAction, addColleaguesSuccessAction } from './actions/addColleagues.action';
import { IDashboardState } from '../types/dashboardState.interface';
import {
    getSkillMatrixAction,
    getSkillMatrixSuccessAction,
} from './actions/getSkillMatrixData.action';
import {
    getBlindSpotsAction,
    getBlindSpotsSuccessAction,
} from './actions/getBlindSpotsData.action';
import { getRolesAction, getRolesSuccessAction } from './actions/getRoles.action';
import { getSkillGapsAction, getSkillGapsSuccessAction } from './actions/getSkillGapsData.action';
import { getSummaryAction, getSummarySuccessAction } from './actions/getSummaryData.action';
import { getFeedbackAction, getFeedbackSuccessAction } from './actions/getFeedbackData.action';
import { appErrorAction } from 'src/app/store/actions/sync.action';
import { SectionType } from '../types/sectionType.enum';
import {
    createUpdateTeamMemberAction,
    createTeamMemberSuccessAction,
} from './actions/createUpdateTeamMember.action';
import { getB2bStatusAction, getB2bStatusSuccessAction } from './actions/getB2bStatus.action';
import {
    getB2bStatusListAction,
    getB2bStatusListSuccessAction,
} from './actions/getB2bStatusList.action';
import {
    getCurrentTeamListAction,
    getCurrentTeamListSuccessAction,
} from './actions/getCurrentTeamList.action';
import {
    removeTeamMemberAction,
    removeTeamMemberSuccessAction,
} from './actions/removeTeamMember.action';
import { getB2bKPIAction, getB2bKPISuccessAction } from './actions/getB2bKpi.action';
import {
    getB2BSpecializationsAction,
    getB2BSpecializationsSuccessAction,
} from './actions/getB2BSpecializations.action';
import {
    getStrengthsAndWeaknessesAction,
    getStrengthsAndWeaknessesSuccessAction,
} from './actions/getStrengthsAndWeaknesses.action';

const initialState: IDashboardState = {
    loadingCount: 0,
    status: null,
    specializationNames: null,
    colleagues: null,
    summaryData: {
        competencyMarks: [
            { name: 'Digital Dexterity', value: 2.6, isCore: true },
            { name: 'Data-driven', value: 3.9, isCore: true },
            { name: 'Business Acumen', value: 4.1, isCore: true },
            { name: 'People Advocacy', value: 5.3, isCore: true },
            { name: 'Compensation & Benefits', value: 5.0, isCore: false },
        ],
        bestPromptMarks: [
            { name: 'Has a deep understanding of the business', value: 6.6, isCore: false },
            { name: 'Collaborates with cross-functional partners', value: 6.1, isCore: false },
            {
                name: 'Creates a compelling vision and mission for others',
                value: 6.1,
                isCore: false,
            },
            { name: 'Communicates data in an impactful manner', value: 5.9, isCore: false },
        ],
        worstPromptMarks: [
            { name: 'Has a deep understanding of the business', value: 2.5, isCore: false },
            { name: 'Collaborates with cross-functional partners', value: 2.4, isCore: false },
            {
                name: 'Creates a compelling vision and mission for others',
                value: 2.2,
                isCore: false,
            },
            { name: 'Communicates data in an impactful manner', value: 1.8, isCore: false },
        ],
    },
    skillMatrixData: [
        {
            id: 1,
            name: 'Data literacy',
            value: 4,
            definition:
                'The ability to understand external trends, apply insights to business strategy and align HR in an impactful, customer-focused way.',
            dimentionMarks: [
                { name: 'HR Mastery', value: 5.1 },
                { name: 'Strategy Co-Creation', value: 4.2 },
                { name: 'Customer Focus', value: 3.0 },
                { name: 'Context Interpretation', value: 2.9 },
            ],
        },
        {
            id: 2,
            name: 'Digital Dexterity',
            value: 4,
            definition: null,
            dimentionMarks: [
                { name: 'Data-Driven', value: 2.1 },
                { name: 'Analytics Translation', value: 6.2 },
            ],
        },
        {
            id: 3,
            name: 'Business Acumen',
            value: 4,
            definition: null,
            dimentionMarks: [
                { name: 'Technology Steward', value: 1.1 },
                { name: 'Digital Culture Architect', value: 1.2 },
            ],
        },
        {
            id: 4,
            name: 'Business Acumen',
            value: 4,
            definition: null,
            dimentionMarks: [
                { name: 'Ethics & Risk Custodian', value: 2.1 },
                { name: 'Comms & Change Navigator', value: 2.2 },
                { name: 'Culture & Workplace Champion', value: 7.0 },
            ],
        },
    ],
    blindSpotsData: {
        overconfident: [
            {
                prompt: 'Has a deep understanding of the business',
                mark: 4.2,
                selfMark: 6.6,
            },
            {
                prompt: 'Has a deep understanding of the business',
                mark: 4.7,
                selfMark: 5.8,
            },
            {
                prompt: 'Has a deep understanding of the business',
                mark: 4.2,
                selfMark: 5.8,
            },
            {
                prompt: 'Has a deep understanding of the business',
                mark: 4.2,
                selfMark: 5.8,
            },
        ],
        underconfident: [
            {
                prompt: 'Has a deep understanding of the business',
                mark: 6.2,
                selfMark: 1.7,
            },
            {
                prompt: 'Has a deep understanding of the business',
                mark: 6.2,
                selfMark: 2.6,
            },
            {
                prompt: 'Has a deep understanding of the business',
                mark: 6.2,
                selfMark: 2.6,
            },
            {
                prompt: 'Has a deep understanding of the business',
                mark: 6.2,
                selfMark: 2.6,
            },
        ],
    },
    roleNames: null,
    skillGapsDataFirst: [
        {
            dimensionName: 'HR Mastery',
            memberMark: 10,
            roleBenchmark: 8,
        },
        {
            dimensionName: 'Strategy Co-Creation',
            memberMark: 5,
            roleBenchmark: 9,
        },
        {
            dimensionName: 'Strategy Co-Creation',
            memberMark: 8,
            roleBenchmark: 10,
        },
    ],
    skillGapsDataSecond: [
        {
            dimensionName: 'HR Mastery',
            memberMark: 10,
            roleBenchmark: 8,
        },
        {
            dimensionName: 'Strategy Co-Creation',
            memberMark: 5,
            roleBenchmark: 9,
        },
        {
            dimensionName: 'Strategy Co-Creation',
            memberMark: 8,
            roleBenchmark: 10,
        },
    ],
    feedbackData: {
        memberName: 'Hans',
        startComments: [
            'Phasellus in ex feugiat, maximus tortor a, ornare nisi. Cras nec tincidunt nulla, quis pretium leo.',
            'Phasellus eget iaculis urna. Proin ut neque urna. Sed vel aliquam tortor, ut venenatis urna.',
            '“Ut suscipit pulvinar eros eget euismod. Sed felis nulla, eleifend in neque id, feugiat finibus lacus.',
        ],
        stopComments: [
            'Phasellus in ex feugiat, maximus tortor a, ornare nisi. Cras nec tincidunt nulla, quis pretium leo.',
            'Phasellus eget iaculis urna. Proin ut neque urna. Sed vel aliquam tortor, ut venenatis urna.',
            '“Ut suscipit pulvinar eros eget euismod. Sed felis nulla, eleifend in neque id, feugiat finibus lacus.',
        ],
        continueComments: [
            'Phasellus in ex feugiat, maximus tortor a, ornare nisi. Cras nec tincidunt nulla, quis pretium leo.',
            'Phasellus eget iaculis urna. Proin ut neque urna. Sed vel aliquam tortor, ut venenatis urna.',
            '“Ut suscipit pulvinar eros eget euismod. Sed felis nulla, eleifend in neque id, feugiat finibus lacus.',
        ],
    },
    errors: null,
    b2bStatus: null,
    b2bStatusList: null,
    teamMembers: [],
    teamMembersList: [],
    b2bKpiInfo: null,
    b2bSpecializationNames: null,
    b2bStrengthOrWeaknessInfo: null,
};

export const dashboardReducer = createReducer(
    initialState,
    on(
        getStatusAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        getB2bStatusAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        getStatusSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            status: action.status,
        })
    ),
    on(
        getStrengthsAndWeaknessesAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        getStrengthsAndWeaknessesSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            b2bStrengthOrWeaknessInfo: action.strengthsOrWeaknessesInfo,
        })
    ),
    on(
        getB2bStatusSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            b2bStatus: action.b2bStatus,
        })
    ),
    on(
        getB2bStatusListAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        getB2bStatusListSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            b2bStatusList: action.b2bStatusList,
        })
    ),
    on(
        getB2bKPIAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        getB2bKPISuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            b2bKpiInfo: action.b2bKpiInfo,
        })
    ),
    on(
        getSpecilizationsAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        getSpecilizationsSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            specializationNames: action.names,
        })
    ),
    on(
        getB2BSpecializationsAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        getB2BSpecializationsSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            b2bSpecializationNames: action.b2bSpecializationNames,
        })
    ),
    on(
        getColleaguesAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        getColleaguesSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            colleagues: action.colleagues,
        })
    ),
    on(
        createPollingAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        addColleaguesAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        addColleaguesSuccessAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
        })
    ),
    on(
        createUpdateTeamMemberAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        createTeamMemberSuccessAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
        })
    ),
    on(
        removeTeamMemberAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        removeTeamMemberSuccessAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
        })
    ),
    on(
        getSkillMatrixAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
            skillMatrixData: null,
        })
    ),
    on(
        getSkillMatrixSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            skillMatrixData: action.marks,
        })
    ),
    on(
        getSummaryAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
            summaryData: null,
        })
    ),
    on(
        getSummarySuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            summaryData: action.summary,
        })
    ),
    on(
        getBlindSpotsAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
            blindSpotsData: null,
        })
    ),
    on(
        getBlindSpotsSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            blindSpotsData: action.blindSpots,
        })
    ),
    on(
        getRolesAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
        })
    ),
    on(
        getRolesSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            roleNames: action.names,
        })
    ),
    on(getSkillGapsAction, (state, action): IDashboardState => {
        return action.section == SectionType.First
            ? {
                  ...state,
                  loadingCount: state.loadingCount + 1,
                  errors: null,
                  skillGapsDataFirst: null,
              }
            : {
                  ...state,
                  loadingCount: state.loadingCount + 1,
                  errors: null,
                  skillGapsDataSecond: null,
              };
    }),
    on(getSkillGapsSuccessAction, (state, action): IDashboardState => {
        return action.section == SectionType.First
            ? {
                  ...state,
                  loadingCount: state.loadingCount - 1,
                  skillGapsDataFirst: action.skillGaps,
              }
            : {
                  ...state,
                  loadingCount: state.loadingCount - 1,
                  skillGapsDataSecond: action.skillGaps,
              };
    }),
    on(
        getFeedbackAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
            feedbackData: null,
        })
    ),
    on(
        getFeedbackSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            feedbackData: action.feedback,
        })
    ),
    on(
        getCurrentTeamListAction,
        (state): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount + 1,
            errors: null,
            teamMembersList: null,
        })
    ),
    on(
        getCurrentTeamListSuccessAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            teamMembersList: action.teamMembersList,
        })
    ),
    on(
        appErrorAction,
        (state, action): IDashboardState => ({
            ...state,
            loadingCount: state.loadingCount - 1,
            errors: action.error,
        })
    ),
    on(routerNavigationAction, (): IDashboardState => initialState)
);

export function reducers(state: IDashboardState, action: Action) {
    return dashboardReducer(state, action);
}
