import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { NavbarComponent } from './components/navbar/navbar.component';

@NgModule({
    declarations: [NavbarComponent],
    imports: [CommonModule, TranslateModule],
    exports: [NavbarComponent],
})
export class NavbarModule {}
