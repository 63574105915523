import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { StartComponent } from './pages/b2b/components/start/start.component';
import { B2bDashboardComponent } from './pages/dashboard/components/b2b-dashboard/b2b-dashboard.component';

import { DashboardComponent } from './pages/dashboard/components/dashboard/dashboard.component';
import { MobileUnsuportedComponent } from './pages/mobile-unsuported/components/mobile-unsuported/mobile-unsuported.component';
import { PollingColleagueComponent } from './pages/polling-colleague/components/polling-colleague/polling-colleague.component';
import { PollingMemberComponent } from './pages/polling-member/components/polling-member/polling-member.component';
import { ThanksColleagueComponent } from './pages/thanks-colleague/components/thanks-colleague/thanks-colleague.component';
import { ScreenSizeGuard } from './shared/guards/screenSize.guard';

const routes: Routes = [
    {
        path: 'mobile-unsuported',
        component: MobileUnsuportedComponent,
    },
    {
        path: '',
        children: [
            {
                path: 'polling/done',
                component: ThanksColleagueComponent,
            },
            {
                path: 'polling/:invite',
                component: PollingColleagueComponent,
            },
            {
                path: 'share/:invite',
                component: DashboardComponent,
            },
            {
                path: 'b2b',
                canActivate: [AuthGuard],
                component: B2bDashboardComponent,
            },
            {
                path: 'status',
                component: StartComponent,
            },
            {
                path: '',
                canActivate: [],
                children: [
                    {
                        path: '',
                        canActivate: [ScreenSizeGuard],
                        component: DashboardComponent,
                    },
                    {
                        path: 'polling',
                        component: PollingMemberComponent,
                    },
                    {
                        path: '**',
                        canActivate: [ScreenSizeGuard],
                        component: DashboardComponent,
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [ScreenSizeGuard],
})
export class AppRoutingModule {}
