import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ICompetencyInfo } from '../../types/competencyInfo.interface';

@Component({
    selector: 'aihr-polling-competency',
    templateUrl: './polling-competency.component.html',
    styleUrls: ['./polling-competency.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class PollingCompetencyComponent implements OnInit {
    @Input('competency') public competencyProps: ICompetencyInfo;
    @Input('progress') public progress: number;

    @Output('competencyNext') public competencyNextEvent = new EventEmitter<{
        [key: string]: { mark: string | null };
    }>();

    public promptAnswers: { [key: number]: { mark: string | null } } = {};
    public errorMessage: string;

    constructor(private translate: TranslateService) {}

    public ngOnInit(): void {
        Object.entries(this.competencyProps.prompts).forEach(
            ([key]) => (this.promptAnswers[key] = { value: null })
        );
    }

    public onNext(): void {
        if (!Object.entries(this.promptAnswers).find((element) => element[1].mark == null)) {
            this.competencyNextEvent.emit(this.promptAnswers);
            document.getElementById('polling-scroll-anchor')?.scrollIntoView();
        } else {
            this.errorMessage = this.translate.instant('Polling.Error');
        }
    }
}
