<div class="row skills-gap">
    <div class="col-6">
        <p class="section-label">{{ 'Dashboard.SkillsGap.Section1' | translate }}</p>
        <div class="skills-gap__block style-item">
            <div class="skills-gap__select-wrap">
                <ng-select [items]="roleNames$ | async"
                           bindLabel="name"
                           bindValue="id"
                           [(ngModel)]="firstRoleId"
                           placeholder="Select role"
                           (change)="changeFirstSkillGap()">
                </ng-select>
            </div>
            <aihr-error-message *ngIf="(errors$ | async) && !firstSkillGap"
                                [errors]="errors$ | async"></aihr-error-message>
            <aihr-loading *ngIf="(isLoading$ | async) && !firstSkillGap"></aihr-loading>
            <div *ngIf="firstSkillGap"
                 class="skills-gap__item-wrap">
                <div *ngFor="let dimension of firstSkillGap; let i = index"
                     class="skills-gap__item d-flex ">
                    <div class="col-3 d-flex align-items-center mb-3">
                        <p class="skills-gap__progress-title m-0">{{ i + 1 }}. {{ dimension.dimensionName }}</p>
                    </div>
                    <div class="skills-gap__wrap-progress">
                        <div class="progress skills-gap__progress">
                            <div class="progress-bar skills-gap__progress-bar"
                                 role="progressbar"
                                 [style.width.%]="10 * dimension.roleBenchmark"
                                 aria-valuenow="25"
                                 aria-valuemin="0"
                                 aria-valuemax="100">
                                <p *ngIf="countGap(dimension) <= 0"
                                   class="skills-gap_value">{{ countGap(dimension) }}</p>
                            </div>
                        </div>
                        <div class="progress skills-gap__progress skills-gap__progress_current">
                            <div class="progress-bar skills-gap__progress-bar skills-gap__progress-bar"
                                 role="progressbar"
                                 [style.width.%]="10 * dimension.memberMark"
                                 aria-valuenow="25"
                                 aria-valuemin="0"
                                 aria-valuemax="100">
                                <p *ngIf="countGap(dimension) > 0"
                                   class="skills-gap_value"><span>+</span><span></span>{{ countGap(dimension) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="skills-gap__legend-wrap col-9 offset-2 d-flex px-3">
                <div class="skills-gap__legend d-flex align-items-center">
                    <div class="skills-gap__legend-item"></div>
                    <div class="skills-gap__legend-title">{{ 'Dashboard.SkillsGap.Benchmark' | translate }}</div>
                </div>
                <div class="skills-gap__legend skills-gap__legend_current d-flex align-items-center">
                    <div class="skills-gap__legend-item"></div>
                    <div class="skills-gap__legend-title">{{ 'Dashboard.SkillsGap.Score' | translate }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <p class="section-label">{{ 'Dashboard.SkillsGap.Section2' | translate }}</p>
        <div class="skills-gap__block skills-gap__next-role style-item">
            <div class="skills-gap__select-wrap">
                <ng-select [items]="roleNames$ | async"
                           bindLabel="name"
                           bindValue="id"
                           [(ngModel)]="secondRoleId"
                           placeholder="Select role"
                           (change)="changeSecondSkillGap()">
                </ng-select>
            </div>
            <aihr-error-message *ngIf="(errors$ | async) && !secondSkillGap"
                                [errors]="errors$ | async"></aihr-error-message>
            <aihr-loading *ngIf="(isLoading$ | async) && !secondSkillGap"></aihr-loading>
            <div *ngIf="secondSkillGap"
                 class="skills-gap__item-wrap">
                <div *ngFor="let dimension of secondSkillGap; let i = index"
                     class="skills-gap__item d-flex ">
                    <div class="col-3 d-flex align-items-center mb-3">
                        <p class="skills-gap__progress-title m-0">{{ i + 1 }}. {{ dimension.dimensionName }}</p>
                    </div>
                    <div class="skills-gap__wrap-progress">
                        <div class="progress skills-gap__progress">
                            <div class="progress-bar skills-gap__progress-bar"
                                 role="progressbar"
                                 [style.width.%]="10 * dimension.roleBenchmark"
                                 aria-valuenow="25"
                                 aria-valuemin="0"
                                 aria-valuemax="100">
                                <p *ngIf="countGap(dimension) <= 0"
                                   class="skills-gap_value">{{ countGap(dimension) }}</p>
                            </div>
                        </div>
                        <div class="progress skills-gap__progress skills-gap__progress_current">
                            <div class="progress-bar skills-gap__progress-bar skills-gap__progress-bar"
                                 role="progressbar"
                                 [style.width.%]="10 * dimension.memberMark"
                                 aria-valuenow="25"
                                 aria-valuemin="0"
                                 aria-valuemax="100">
                                <p *ngIf="countGap(dimension) > 0"
                                   class="skills-gap_value"><span>+</span><span></span>{{ countGap(dimension) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="skills-gap__legend-wrap col-9 offset-2 d-flex px-3">
                <div class="skills-gap__legend d-flex align-items-center">
                    <div class="skills-gap__legend-item"></div>
                    <div class="skills-gap__legend-title">{{ 'Dashboard.SkillsGap.Benchmark' | translate }}</div>
                </div>
                <div class="skills-gap__legend skills-gap__legend_current d-flex align-items-center">
                    <div class="skills-gap__legend-item"></div>
                    <div class="skills-gap__legend-title">{{ 'Dashboard.SkillsGap.Score' | translate }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
