import { createAction, props } from '@ngrx/store';

import { IStatusInfo } from '../../types/statusInfo.interface';
import { ActionTypes } from '../actionTypes';

export const getStatusAction = createAction(
    ActionTypes.GET_STATUS
);

export const getStatusSuccessAction = createAction(
    ActionTypes.GET_STATUS_SUCCESS,
    props<{ status: IStatusInfo | null }>()
);
