<div class="feedback-section style-item position-relative">
    <aihr-error-message *ngIf="(errors$ | async) && !data"
                        [errors]="errors$ | async"></aihr-error-message>
    <aihr-loading *ngIf="(isLoading$ | async) && !data"></aihr-loading>
    <div *ngIf="data"
         class="row m-0 feedback-section__block col-12">
        <div class="feedback-section__question-wrap feedback-section__block-item col-6">
            <p class="feedback-section__question-title">{{ 'Dashboard.Feedback.Stop.Question' | translate }}</p>
            <p class="feedback-section__question m-0">
                {{ 'Dashboard.Feedback.Stop.Message' | translate }}
            </p>
        </div>
        <div class="feedback-section__block-item feedback-section__description-wrap col-6">
            <p *ngFor="let f of data.stopComments">“{{f}}”</p>
        </div>
    </div>
    <div *ngIf="data"
         class="row m-0 feedback-section__block col-12">
        <div class="feedback-section__question-wrap feedback-section__block-item col-6">
            <p class="feedback-section__question-title">{{ 'Dashboard.Feedback.Start.Question' | translate }}</p>
            <p class="feedback-section__question m-0">
                {{ 'Dashboard.Feedback.Start.Message' | translate }}
            </p>
        </div>
        <div class="feedback-section__block-item feedback-section__description-wrap col-6">
            <p *ngFor="let f of data.startComments">“{{f}}”</p>
        </div>
    </div>
    <div *ngIf="data"
         class="row m-0 feedback-section__block col-12">
        <div class="feedback-section__question-wrap feedback-section__block-item col-6">
            <p class="feedback-section__question-title">{{ 'Dashboard.Feedback.Continue.Question' | translate }}</p>
            <p class="feedback-section__question m-0">
                {{ 'Dashboard.Feedback.Continue.Message' | translate }}
            </p>
        </div>
        <div class="feedback-section__block-item feedback-section__description-wrap col-6">
            <p *ngFor="let f of data.continueComments">“{{f}}”</p>
        </div>
    </div>
</div>
