<div #introPlayer data-vimeo-width="505"></div>
<div #introModal class="modal">
    <div class="modal-content">
        <div class="aihr-logo">
            <img src="/assets/images/svg/AIHR_Horizontal_Logo.svg" alt="logo" />
        </div>
        <h2 class="modal-title">{{ 'Dashboard.Introduction.ModalTitle' | translate }}</h2>
        <div class="modal-subtitle">{{ 'Dashboard.Introduction.ModalText' | translate }}</div>
        <div
            class="modal-video"
            #modalPlayer
            data-vimeo-width="900"
            frameborder="0"
            allow="autoplay"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
        ></div>

        <div
            class="btn-close-modal"
            attr.data-title="{{
                !isReadyForClose ? ('Dashboard.Introduction.ButtonTooltip' | translate) : ''
            }}"
        >
            <button
                class="btn-main btn btn-warning"
                type="button"
                [disabled]="!isReadyForClose"
                title="Please watch the full video before continuing"
                (click)="introModal.style.display = 'none'"
            >
                {{
                    isReadyForClose
                        ? ('Dashboard.Introduction.CustomButtonMessage' | translate)
                        : ('Dashboard.Introduction.DisabledButtonMessage' | translate) +
                          (countDown$ | async | date : 'm:ss')
                }}
            </button>
        </div>
    </div>
</div>
