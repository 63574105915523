import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { MIN_SCREEN_SIZE } from '../constants/limits';

@Injectable()
export class ScreenSizeGuard implements CanActivate {
    constructor(private router: Router) {}

    public canActivate() {
        if (window.innerWidth >= MIN_SCREEN_SIZE) {
            return true;
        } else {
            this.router.navigate(['/mobile-unsuported']);
            return false;
        }
    }
}
