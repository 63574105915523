import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '../actionTypes';

export const removeTeamMemberAction = createAction(
    ActionTypes.REMOVE_TEAM_MEMBER,
    props<{ id: number }>()
);

export const removeTeamMemberSuccessAction = createAction(ActionTypes.remove_TEAM_MEMBER_SUCCESS);
