<div class="modal fade"
     id="unsuportedModal"
     tabindex="-1"
     aria-labelledby="unsuportedModalLabel"
     aria-hidden="true"
     data-backdrop="static"
     data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="mobile-pop-up error-block">
                    <div
                         class="error-block__item d-flex align-items-center flex-wrap style-item justify-content-center">
                        <div
                             class="error-block__img d-flex justify-content-lg-start justify-content-sm-center col-md-4 col-sm-12 mb-lg-0  mb-3 p-0 ">
                            <img src="/assets/images/noun-server.webp"
                                 alt="">
                        </div>
                        <div class="error-block__description col-12 p-0">
                            <h1 class="main-title fw-bold p-0">{{ 'Mobile.Header' | translate }}</h1>
                            <p class="title-description fw-semibold p-0">{{ 'Mobile.Message' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
