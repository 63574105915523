import { createAction, props } from '@ngrx/store';

import { IAddColleagueRequest } from '../../types/addColleagueRequest.interface';
import { ActionTypes } from '../actionTypes';

export const addColleaguesAction = createAction(
    ActionTypes.ADD_COLLEAGUES,
    props<{ addRequest: IAddColleagueRequest }>()
);

export const addColleaguesSuccessAction = createAction(ActionTypes.ADD_COLLEAGUES_SUCCESS);
