import { createAction, props } from '@ngrx/store';

import { Ib2bStrengthOrWeaknessRequest } from '../../types/b2bStrengthOrWeakness.interface';
import { Ib2bStrengthOrWeaknessInfo } from '../../types/b2bStrengthOrWeaknessInfo.interface';
import { ActionTypes } from '../actionTypes';

export const getStrengthsAndWeaknessesAction = createAction(
    ActionTypes.GET_B2B_STRENGTH_OR_WEAKNESS,
    props<{ request: Ib2bStrengthOrWeaknessRequest }>()
);

export const getStrengthsAndWeaknessesSuccessAction = createAction(
    ActionTypes.GET_B2B_STRENGTH_OR_WEAKNESS_SUCCESS,
    props<{ strengthsOrWeaknessesInfo: Ib2bStrengthOrWeaknessInfo[] }>()
);
