import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, tap, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

import { createPollingAction, createPollingSuccessAction } from '../actions/createRequest.action';
import { DashboardService } from '../../services/dashboard.service';
import { ErrorHandlerService } from 'src/app/core/services/errorHandler.service';
import { appErrorAction } from 'src/app/store/actions/sync.action';

@Injectable()
export class CreateRequestEffect {
    public createRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createPollingAction),
            switchMap(({ pollingRequest }) => {
                return this.dashboardService.createPollingRequest(pollingRequest).pipe(
                    map(() => {
                        this.dashboardService.subject.next(true);
                        return createPollingSuccessAction();
                    }),
                    catchError((error: Error) => {
                        return of(appErrorAction({ error: this.errorHandler.covertError(error) }));
                    })
                );
            })
        )
    );

    public redirectAfterCreate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(createPollingSuccessAction),
                tap(() => {
                    this.router.navigate(['/polling']);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private dashboardService: DashboardService,
        private router: Router,
        private errorHandler: ErrorHandlerService
    ) {}
}
