import { createAction, props } from '@ngrx/store';

import { ISaveRequestColleague } from '../../types/saveRequestColleague.interface';
import { ISaveRequestMember } from '../../types/saveRequestMember.interface';
import { ActionTypes } from '../actionTypes';

export const saveResultsMemberAction = createAction(
    ActionTypes.SAVE_POLLING_MEMBER,
    props<{ request: ISaveRequestMember }>()
);

export const saveResultsMemberSuccessAction = createAction(ActionTypes.SAVE_POLLING_MEMBER_SUCCESS);

export const saveResultsColleagueAction = createAction(
    ActionTypes.SAVE_POLLING_COLLEAGUE,
    props<{ invite: string; request: ISaveRequestColleague }>()
);

export const saveResultsColleagueSuccessAction = createAction(
    ActionTypes.SAVE_POLLING_COLLEAGUE_SUCCESS
);
