import { createAction, props } from '@ngrx/store';
import { IStatusList } from '../../types/statusList.interface';

import { ActionTypes } from '../actionTypes';

export const getB2bStatusListAction = createAction(ActionTypes.GET_B2B_STATUS_LIST);

export const getB2bStatusListSuccessAction = createAction(
    ActionTypes.GET_B2B_STATUS_LIST_SUCCESS,
    props<{ b2bStatusList: IStatusList[] | null }>()
);
