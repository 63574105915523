import { createAction, props } from '@ngrx/store';
import { IB2BKpiInfo } from '../../types/b2bKpiInfo.interface';

import { ActionTypes } from '../actionTypes';

export const getB2bKPIAction = createAction(ActionTypes.GET_B2B_KPI);

export const getB2bKPISuccessAction = createAction(
    ActionTypes.GET_B2B_KPI_SUCCESS,
    props<{ b2bKpiInfo: IB2BKpiInfo | null }>()
);
