<div
    class="offcanvas sidebar offcanvas-end sidebar-right"
    tabindex="-1"
    id="offcanvas"
    #showForm
    data-bs-keyboard="false"
    data-bs-backdrop="false"
>
    <div class="sidebar">
        <div class="sidebar__header d-grid justify-content-end">
            <button
                type="button"
                class="sidebar__btn"
                aria-label="Close"
                #closeButton
                data-bs-dismiss="offcanvas"
            >
                {{ 'Dashboard.Overlay.Close' | translate }}
            </button>
        </div>
        <aihr-dashboard-overlay [isB2b]="isB2b"></aihr-dashboard-overlay>
    </div>
</div>
<div class="container-fluid" [class.skeleton-overflow]="!status?.isReady">
    <aihr-global-error *ngIf="(errors$ | async)?.isServerAvalible == false"></aihr-global-error>
    <div *ngIf="(errors$ | async)?.isServerAvalible != false" class="container dashboard-page">
        <div *ngIf="!status" class="main-banner main-banner_first">
            <div
                class="row align-items-center flex-lg-nowrap flex-md-nowrap flex-sm-wrap gap-3 justify-content-around"
            >
                <div
                    class="main-banner__icon d-flex justify-content-lg-start justify-content-sm-center justify-content-center col-lg-4 col-md-4 col-sm-12 mb-lg-0 mb-3 p-0"
                >
                    <img src="/assets/images/first-banner-logo.webp" alt="" />
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 p-0">
                    <div class="main-banner__title-wrap">
                        <p class="main-banner__label fw-semibold">
                            <span class="main-banner__label_color fw-bolder">HRI</span>ntelligence
                            360 Assessment
                        </p>
                        <p class="main-banner__title fw-semibold m-0">
                            {{ 'Dashboard.Banner.Start.Message1' | translate }}
                        </p>
                        <p class="main-banner__description fw-normal m-0">
                            {{ 'Dashboard.Banner.Start.Message2' | translate }}.
                        </p>
                    </div>
                    <div
                        class="main-banner__wrap-btn d-flex col-lg-4 col-md-12 mt-lg-4 mt-sm-3 mt-3"
                        *ngIf="isLoaded"
                    >
                        <button
                            class="btn btn-lg btn-main"
                            role="button"
                            (click)="handleStartButton()"
                            *ngIf="!isLoggedIn"
                        >
                            {{ 'Dashboard.Banner.Start.StartNow' | translate }}
                        </button>
                        <button
                            class="btn btn-lg btn-main"
                            *ngIf="isLoggedIn"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvas"
                            role="button"
                        >
                            {{ 'Dashboard.Banner.Start.InviteMore' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="status">
            <div *ngIf="!status.isReady" class="main-banner main-banner-wait container">
                <div class="main-banner-wait__wrap">
                    <div
                        class="row align-items-center flex-lg-nowrap flex-md-wrap justify-content-lg-start justify-content-md-between justify-content-sm-center"
                    >
                        <div
                            class="main-banner__icon d-flex justify-content-lg-start justify-content-sm-center justify-content-center col-lg-5 col-md-4 col-sm-8 mb-lg-0 mb-3 p-0"
                        >
                            <img src="/assets/images/waiting-pana.webp" alt="" />
                        </div>
                        <div class="col-lg-6 col-md-7 col-sm-12 p-0 align-self-start">
                            <div class="main-banner__title-wrap">
                                <p class="main-banner__label fw-semibold">
                                    <span class="main-banner__label_color fw-bolder">HRI</span
                                    >ntelligence 360 Assessment
                                </p>
                                <p class="main-banner__title fw-semibold m-0">
                                    {{ 'Dashboard.Banner.Wait.Message1' | translate }}
                                </p>
                                <p class="main-banner__description fw-normal m-0">
                                    {{ 'Dashboard.Banner.Wait.Message2' | translate }}
                                </p>
                                <div class="main-banner-wait__countdown-wrap d-flex">
                                    <div
                                        class="answered d-flex flex-column text-center"
                                        style="margin-right: 10px"
                                        *ngIf="showTimeLeft"
                                    >
                                        <div
                                            class="answered__quantity d-flex d-justify-content-center"
                                        >
                                            <div style="margin-right: 20px">
                                                <span>{{ difference?.days?.value }}</span>
                                                <p class="answered__title m-0">
                                                    {{ difference?.days?.title }}
                                                </p>
                                            </div>
                                            <div style="margin-right: 20px">
                                                <span>{{ difference.hours.value }}</span>
                                                <p class="answered__title m-0">
                                                    {{ difference?.hours?.title }}
                                                </p>
                                            </div>
                                            <div style="margin-right: 20px">
                                                <span>{{ difference?.minutes?.value }}</span>
                                                <p class="answered__title m-0">
                                                    {{ difference?.minutes?.title }}
                                                </p>
                                            </div>
                                            <div style="margin-right: 20px">
                                                <span>{{ difference?.seconds?.value }}</span>
                                                <p class="answered__title m-0">
                                                    {{ difference?.seconds?.title }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="answered d-flex flex-column text-center ms-10">
                                        <div class="answered__quantity">
                                            <span>{{ status.answeredCount }}</span
                                            >/<span>{{ status.colleagueCount }}</span>
                                        </div>
                                        <p class="answered__title m-0">
                                            {{ 'Dashboard.Banner.Wait.Answered' | translate }}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="main-banner__wrap-btn d-flex justify-content-md-start justify-content-center col-lg- col-md-12 mt-lg-0 mt-sm-3 mt-3"
                                >
                                    <button
                                        class="btn btn-lg btn-main"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvas"
                                        role="button"
                                    >
                                        <img src="/assets/images/svg/plus.svg" alt="" />
                                        {{ 'Dashboard.Banner.Wait.AddInvite' | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                *ngIf="status.isReady && status.answeredCount != status.colleagueCount"
                class="main-banner main-banner__not-result"
            >
                <div class="row align-items-center flex-lg-nowrap flex-md-wrap">
                    <div
                        class="main-banner__icon d-flex justify-content-lg-start justify-content-sm-center justify-content-center col-lg-2 col-md-4 col-sm-12 mb-lg-0 mb-3 p-0"
                    >
                        <img src="/assets/images/main-banner-logo.webp" alt="" />
                    </div>
                    <div class="col-lg-6 col-md-7 col-sm-12 p-0">
                        <div class="main-banner__title-wrap">
                            <p class="main-banner__label fw-semibold">
                                <span class="main-banner__label_color fw-bolder">HRI</span
                                >ntelligence 360 Assessment
                            </p>
                            <p class="main-banner__title fw-semibold m-0">
                                {{ 'Dashboard.Banner.Ready.Message1' | translate }}
                            </p>
                            <p
                                class="main-banner__description fw-normal m-0"
                                [innerHtml]="
                                    'Dashboard.Banner.Ready.Message2'
                                        | translate
                                            : {
                                                  answered: status.answeredCount,
                                                  colleague: status.colleagueCount
                                              }
                                "
                            ></p>
                        </div>
                    </div>
                    <div
                        class="main-banner__wrap-btn d-flex justify-content-lg-end justify-content-md-center justify-content-center col-lg-4 col-md-12 mt-lg-0 mt-sm-3 mt-3"
                    >
                        <div class="answered d-flex flex-column text-center">
                            <div class="answered__quantity">
                                <span>{{ status.answeredCount }}</span
                                >/<span>{{ status.colleagueCount }}</span>
                            </div>
                            <p
                                class="answered__title m-0"
                                [innerHtml]="'Dashboard.Banner.Ready.Answered' | translate"
                            ></p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                *ngIf="status.isReady && status.answeredCount === status.colleagueCount"
                class="main-banner"
            >
                <div class="row align-items-center flex-lg-nowrap flex-md-wrap">
                    <div
                        class="main-banner__icon d-flex justify-content-lg-start justify-content-sm-center justify-content-center col-lg-2 col-md-4 col-sm-12 mb-lg-0 mb-3 p-0"
                    >
                        <img src="/assets/images/main-banner-logo.webp" alt="" />
                    </div>
                    <div class="col-lg-6 col-md-7 col-sm-12 p-0">
                        <div class="main-banner__title-wrap">
                            <p class="main-banner__label fw-semibold">
                                <span class="main-banner__label_color fw-bolder">HRI</span
                                >ntelligence 360 Assessment
                            </p>
                            <p class="main-banner__title fw-semibold m-0">
                                {{ 'Dashboard.Banner.Start.Message1' | translate }}
                            </p>
                            <p class="main-banner__description fw-normal m-0">
                                {{ 'Dashboard.Banner.Start.Message2' | translate }}
                            </p>
                        </div>
                    </div>
                    <div
                        class="main-banner__wrap-btn d-flex justify-content-lg-end justify-content-md-center justify-content-center col-lg-4 col-md-12 mt-lg-0 mt-sm-3 mt-3"
                    >
                        <button
                            class="btn btn-lg btn-main d-none"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvas"
                            role="button"
                        >
                            {{ 'Dashboard.Banner.Start.StartNow' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="status?.isReady" class="d-grid gap-5">
            <div>
                <div class="main-title-wrap">
                    <h1 class="main-title fw-bold">{{ 'Dashboard.Header' | translate }}</h1>
                </div>
                <div class="ankors d-flex flex-wrap">
                    <a (click)="scrollAnchor('summary')" class="btn ankors__item section-label">{{
                        'Dashboard.Summary.Link' | translate
                    }}</a>
                    <a
                        (click)="scrollAnchor('highest&LowestPrompts')"
                        class="btn ankors__item section-label"
                        >{{ 'Dashboard.Highest&LowestPrompts.Header' | translate }}</a
                    >
                    <!-- <a
                        (click)="scrollAnchor('skill-matrix')"
                        class="btn ankors__item section-label"
                        >{{ 'Dashboard.ResultsOverview.Link' | translate }}</a
                    > -->
                    <a
                        (click)="scrollAnchor('blind-analysis')"
                        class="btn ankors__item section-label"
                        >{{ 'Dashboard.BlindSpots.Link' | translate }}</a
                    >
                    <!-- <a
                        (click)="scrollAnchor('skill-gaps')"
                        class="btn ankors__item section-label"
                        >{{ 'Dashboard.SkillsGap.Link' | translate }}</a
                    > -->
                    <a (click)="scrollAnchor('feedback')" class="btn ankors__item section-label">{{
                        'Dashboard.Feedback.Link' | translate
                    }}</a>
                </div>
            </div>
            <div class="section section-share">
                <div class="d-flex align-items-baseline">
                    <h4 class="title-section">{{ 'Dashboard.Share.Header' | translate }}</h4>
                </div>
                <hr class="hr-dashboard" />
                <div class="d-flex align-items-end section-share__wrap-item">
                    <fieldset id="section-share__input-field" class="sidebar__form-item inputEmail">
                        <label class="sidebar__form-label" for="emailManager"
                            >Optionally: Share my results dashboard with my manager</label
                        >
                        <input
                            id="emailManager"
                            type="email"
                            [placeholder]="'Dashboard.Overlay.PlaceHolder.Email' | translate"
                            class="sidebar__form-field"
                            [(ngModel)]="sharedEmail"
                        />
                    </fieldset>
                    <div class="sidebar__wrap-btn mt-4">
                        <button
                            class="btn-main btn btn-warning m-auto"
                            type="button"
                            [disabled]="!sharedEmail"
                            (click)="shareEmail()"
                        >
                            {{ 'Dashboard.Overlay.Invite' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="d-flex title-wrap">
                    <h4 class="title-section">{{ 'Dashboard.Introduction.Header' | translate }}</h4>
                    <hr class="hr-dashboard" />
                    <app-dashboard-intro [status]="status"></app-dashboard-intro>
                </div>
            </div>
            <div class="section">
                <span id="summary"></span>
                <div class="d-flex title-wrap">
                    <h4 class="title-section">{{ 'Dashboard.Summary.Header' | translate }}</h4>
                    <button
                        (click)="isOpenSummaryTooltip = !isOpenSummaryTooltip"
                        class="tooltip-item d-flex align-items-center justify-content-center"
                    >
                        {{ 'Dashboard.TooltipLabel' | translate }}
                    </button>
                    <hr class="hr-dashboard" />
                    <div *ngIf="isOpenSummaryTooltip" class="tooltip-message style-item"
                    >
                        <div (click)="isOpenSummaryTooltip = false" class="tooltip-close"></div>
                        <div class="d-flex flex-column"
                        [innerHtml]="'Dashboard.TooltipDetailsText.Summary' | translate"
                        >
                        </div>
                    </div>
                </div>
                <aihr-dashboard-summary></aihr-dashboard-summary>
            </div>
            <div class="section">
                <span id="highest&LowestPrompts"></span>
                <div class="d-flex title-wrap">
                    <h4 class="title-section">
                        {{ 'Dashboard.Highest&LowestPrompts.Header' | translate }}
                    </h4>
                    <button
                        (click)="
                            isOpenHighestAndLowestPromptsTooltip =
                                !isOpenHighestAndLowestPromptsTooltip
                        "
                        class="tooltip-item d-flex align-items-center justify-content-center"
                    >
                        {{ 'Dashboard.TooltipLabel' | translate }}
                    </button>
                    <hr class="hr-dashboard" />
                    <div
                        *ngIf="isOpenHighestAndLowestPromptsTooltip"
                        class="tooltip-message style-item"
                    >
                        <div
                            (click)="isOpenHighestAndLowestPromptsTooltip = false"
                            class="tooltip-close"
                        ></div>
                        {{ 'Dashboard.TooltipDetailsText.Highest&LowestPrompts' | translate }}
                    </div>
                </div>
                <aihr-dashboard-highest-and-lowest></aihr-dashboard-highest-and-lowest>
            </div>
            <!-- ResultsOverview Section -->
            <!-- <div class="section">
                <h4 class="title-section">{{ 'Dashboard.ResultsOverview.Header' | translate }}</h4>
                <hr class="hr-dashboard"/>
                <aihr-dashboard-skill-matrix></aihr-dashboard-skill-matrix>
            </div> -->
            <div class="section">
                <span id="blind-analysis"></span>
                <div class="d-flex title-wrap">
                    <h4 class="title-section">{{ 'Dashboard.BlindSpots.Header' | translate }}</h4>
                    <button
                        (click)="isOpenBlindSpotsTooltip = !isOpenBlindSpotsTooltip"
                        class="tooltip-item d-flex align-items-center justify-content-center"
                    >
                        {{ 'Dashboard.TooltipLabel' | translate }}
                    </button>
                    <hr class="hr-dashboard" />
                    <div
                        *ngIf="isOpenBlindSpotsTooltip"
                        class="tooltip-message style-item tooltip-message_open"
                    >
                        <div (click)="isOpenBlindSpotsTooltip = false" class="tooltip-close"></div>
                        <div class="d-flex flex-column"
                        [innerHtml]="'Dashboard.TooltipDetailsText.BlindSpots' | translate"
                        >
                        </div>
                    </div>
                </div>
                <aihr-dashboard-blind-spots></aihr-dashboard-blind-spots>
                <span id="skill-gaps"></span>
            </div>
            <!-- SkillsGap Section -->
            <!-- <div class="section">
                <h4 class="title-section">{{ 'Dashboard.SkillsGap.Header' | translate }}</h4>
                <hr class="hr-dashboard" />
                <aihr-dashboard-skill-gaps></aihr-dashboard-skill-gaps>
            </div> -->
            <div class="section">
                <span id="feedback"></span>
                <div class="d-flex title-wrap">
                    <h4 class="title-section">{{ 'Dashboard.Feedback.Header' | translate }}</h4>
                    <button
                        (click)="isOpenFeedbackTooltip = !isOpenFeedbackTooltip"
                        class="tooltip-item d-flex align-items-center justify-content-center"
                    >
                        {{ 'Dashboard.TooltipLabel' | translate }}
                    </button>
                    <hr class="hr-dashboard" />
                    <div *ngIf="isOpenFeedbackTooltip" class="tooltip-message style-item flex-column">
                        <div (click)="isOpenFeedbackTooltip = false" class="tooltip-close"></div>
                        <div class="d-flex flex-column"
                        [innerHtml]="'Dashboard.TooltipDetailsText.Feedback' | translate"
                        >
                        </div>
                    </div>
                </div>
                <aihr-dashboard-feedback></aihr-dashboard-feedback>
            </div>
        </div>
        <div class="skeleton container" *ngIf="!status?.isReady">
            <div class="skeleton_color" style="width: 330px; height: 49px"></div>
            <div class="skeleton__ancors-wrap d-flex flex-row">
                <div class="skeleton_color skeleton__ancors-item"></div>
                <div class="skeleton_color skeleton__ancors-item"></div>
                <div class="skeleton_color skeleton__ancors-item"></div>
                <div class="skeleton_color skeleton__ancors-item"></div>
            </div>
            <div class="skeleton_color skeleton__title-section"></div>
            <div class="skeleton-section d-flex justify-content-between">
                <div class="skeleton-section__block">
                    <div class="skeleton_color" style="width: 170px; height: 19px"></div>
                    <div class="skeleton-section__item d-flex flex-column align-items-end">
                        <div
                            class="skeleton-section__label-wrap d-flex justify-content-between mb-2"
                        >
                            <div
                                class="skeleton_color skeleton-section__label"
                                style="width: 100px"
                            ></div>
                            <div
                                class="skeleton_color skeleton-section__label"
                                style="width: 60px"
                            ></div>
                        </div>
                        <div class="d-flex flex-row" style="width: 100%; height: 100%">
                            <div
                                class="d-flex flex-column skeleton-section__wrap-legend justify-content-between"
                            >
                                <div class="skeleton_color" style="width: 60px; height: 12px"></div>
                                <div class="skeleton_color" style="width: 60px; height: 12px"></div>
                                <div class="skeleton_color" style="width: 60px; height: 12px"></div>
                            </div>
                            <div
                                class="d-flex flex-column justify-content-between skeleton-section__wrap-legend"
                            >
                                <div class="skeleton_color" style="width: 4px; height: 116px"></div>
                                <div class="skeleton_color" style="width: 4px; height: 116px"></div>
                                <div class="skeleton_color" style="width: 4px; height: 116px"></div>
                            </div>
                            <div class="d-flex flex-row align-items-end skeleton-section__wrap-bar">
                                <div
                                    class="d-flex flex-column align-items-center skeleton-section__bar"
                                >
                                    <div
                                        class="skeleton_color"
                                        style="width: 20px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 60px; height: 140px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                </div>
                                <div
                                    class="d-flex flex-column align-items-center skeleton-section__bar"
                                >
                                    <div
                                        class="skeleton_color"
                                        style="width: 20px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 60px; height: 190px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                </div>
                                <div
                                    class="d-flex flex-column align-items-center skeleton-section__bar"
                                >
                                    <div
                                        class="skeleton_color"
                                        style="width: 20px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 60px; height: 240px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                </div>
                                <div
                                    class="d-flex flex-column align-items-center skeleton-section__bar"
                                >
                                    <div
                                        class="skeleton_color"
                                        style="width: 20px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 60px; height: 268px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                </div>
                                <div
                                    class="d-flex flex-column align-items-center skeleton-section__bar"
                                >
                                    <div
                                        class="skeleton_color"
                                        style="width: 20px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 60px; height: 300px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                </div>
                                <div
                                    class="d-flex flex-column align-items-center skeleton-section__bar"
                                >
                                    <div
                                        class="skeleton_color"
                                        style="width: 20px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 60px; height: 230px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                    <div
                                        class="skeleton_color"
                                        style="width: 50px; height: 12px"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="skeleton-section__block">
                    <div class="skeleton_color" style="width: 170px; height: 19px"></div>
                    <div
                        class="skeleton-section__item skeleton-section__item-small d-flex flex-column"
                    >
                        <div class="skeleton_color mb-1" style="width: 100%; height: 11px"></div>
                        <div class="skeleton_color mb-1" style="width: 100%; height: 11px"></div>
                        <div class="skeleton_color mb-5" style="width: 100%; height: 11px"></div>
                        <div class="skeleton_color mb-2" style="width: 100px; height: 11px"></div>
                        <div class="skeleton_color mb-4" style="width: 230px; height: 32px"></div>
                        <div class="skeleton_color mb-2" style="width: 80px; height: 11px"></div>
                        <div class="skeleton_color mb-4" style="width: 210px; height: 32px"></div>
                        <div class="skeleton_color mb-2" style="width: 130px; height: 11px"></div>
                        <div class="skeleton_color mb-4" style="width: 190px; height: 32px"></div>
                        <div class="skeleton_color mb-2" style="width: 80px; height: 11px"></div>
                        <div class="skeleton_color mb-3" style="width: 170px; height: 32px"></div>
                        <div class="d-flex justify-content-between mb-1">
                            <div class="skeleton_color mb-1" style="width: 81px; height: 4px"></div>
                            <div class="skeleton_color mb-1" style="width: 81px; height: 4px"></div>
                            <div class="skeleton_color mb-1" style="width: 81px; height: 4px"></div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="skeleton_color" style="width: 10px; height: 10px"></div>
                            <div class="skeleton_color" style="width: 10px; height: 10px"></div>
                            <div class="skeleton_color" style="width: 10px; height: 10px"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
