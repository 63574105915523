import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { DashboardService } from '../../services/dashboard.service';
import { getColleaguesAction } from '../actions/getColleagues.action';
import { ErrorHandlerService } from 'src/app/core/services/errorHandler.service';
import { appErrorAction } from 'src/app/store/actions/sync.action';
import {
    removeTeamMemberAction,
    removeTeamMemberSuccessAction,
} from '../actions/removeTeamMember.action';
import { getB2bStatusAction } from '../actions/getB2bStatus.action';

@Injectable()
export class RemoveTeamMemberEffect {
    public removeTeamMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(removeTeamMemberAction),
            switchMap(({ id }) => {
                return this.dashboardService.removeTeamMember(id).pipe(
                    map(() => {
                        this.loadColleaguesAfterAddinng$.subscribe();
                        return removeTeamMemberSuccessAction();
                    }),
                    catchError((error: Error) => {
                        return of(appErrorAction({ error: this.errorHandler.covertError(error) }));
                    })
                );
            })
        )
    );

    public loadColleaguesAfterAddinng$ = createEffect(() =>
        this.actions$.pipe(
            ofType(removeTeamMemberSuccessAction),
            switchMap(() => {
                return of(getB2bStatusAction());
            })
        )
    );

    constructor(
        private actions$: Actions,
        private dashboardService: DashboardService,
        private errorHandler: ErrorHandlerService
    ) {}
}
