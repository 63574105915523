import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { getPollingAction } from 'src/app/shared/modules/polling/store/actions/getPolling.action';
import { saveResultsColleagueAction } from 'src/app/shared/modules/polling/store/actions/saveResults.action';
import { IPollingInfo } from 'src/app/shared/modules/polling/types/pollingInfo.interface';
import { IPromptAnswer } from 'src/app/shared/modules/polling/types/promptAnswer.interface';
import { ISaveRequestColleague } from 'src/app/shared/modules/polling/types/saveRequestColleague.interface';
import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import {
    errorSelector,
    isLoadingSelector,
    pollingInfoSelector,
    promptAnswersSelector,
} from 'src/app/shared/modules/polling/store/selectors';
import { PollingService } from 'src/app/shared/modules/polling/services/polling.service';

const MAX_LENGTH = 250;
@Component({
    selector: 'aihr-polling-colleague',
    templateUrl: './polling-colleague.component.html',
    styleUrls: ['./polling-colleague.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class PollingColleagueComponent implements OnInit, OnDestroy {
    public pollingInvite: string | null;
    public dataSubscription: Subscription;
    public data: IPollingInfo | null;
    public nameParam: any;
    public errors$: Observable<IBackendErrors | null>;
    public isLoading$: Observable<boolean>;
    public promptAnswersSubscription: Subscription;
    public promptAnswers: IPromptAnswer[];

    public isAllPromptsAnswered: boolean = false;
    public form: FormGroup;
    progress: number;
    maxLength = MAX_LENGTH;

    constructor(private store: Store, private fb: FormBuilder, private route: ActivatedRoute,
        private pollingService: PollingService) { }

    public ngOnInit(): void {
        this.initializeForm();
        this.initializeValues();
    }

    private initializeValues(): void {
        this.pollingInvite = this.route.snapshot.paramMap.get('invite');
        this.dataSubscription = this.store
            .pipe(select(pollingInfoSelector))
            .subscribe((data: IPollingInfo) => {
                this.nameParam = { firstName: data?.memberFirstName };
                this.data = data;
                if (data) {
                    this.pollingService.memberSubject.next(data);
                }
            });
        this.errors$ = this.store.pipe(select(errorSelector));
        this.isLoading$ = this.store.pipe(select(isLoadingSelector));
        this.promptAnswersSubscription = this.store
            .pipe(select(promptAnswersSelector))
            .subscribe((prompts: IPromptAnswer[]) => {
                this.promptAnswers = prompts;
            });
        this.store.dispatch(
            getPollingAction({
                invite: this.pollingInvite,
            })
        );
    }

    private initializeForm(): void {
        this.form = this.fb.group({
            startComment: [null, [Validators.required, Validators.maxLength(MAX_LENGTH)]],
            stopComment: [null, [Validators.required, Validators.maxLength(MAX_LENGTH)]],
            continueComment: [null, [Validators.required, Validators.maxLength(MAX_LENGTH)]],
        });
    }

    public ngOnDestroy(): void {
        this.promptAnswersSubscription.unsubscribe();
        this.dataSubscription.unsubscribe();
    }

    public showForm(): void {
        this.isAllPromptsAnswered = true;
        this.progress = 100;
    }

    public onSubmit(): void {
        const request: ISaveRequestColleague = this.form.value;
        request.answers = this.promptAnswers;
        this.store.dispatch(
            saveResultsColleagueAction({ invite: this.pollingInvite, request: request })
        );
    }
}
