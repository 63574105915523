import { createAction, props } from '@ngrx/store';
import { ITeamMember } from '../../types/teamMember.interface';

import { ActionTypes } from '../actionTypes';

export const getCurrentTeamListAction = createAction(ActionTypes.GET_TEAM_MEMBERS_CURRENT_LIST);

export const getCurrentTeamListSuccessAction = createAction(
    ActionTypes.GET_TEAM_MEMBERS_CURRENT_LIST_SUCCESS,
    props<{ teamMembersList: ITeamMember[] | null }>()
);
