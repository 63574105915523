import { createAction, props } from '@ngrx/store';

import { IFeedback } from 'src/app/shared/modules/polling/types/feedback.interface';
import { ActionTypes } from '../actionTypes';

export const getFeedbackAction = createAction(
    ActionTypes.GET_FEEDBACK
);

export const getFeedbackSuccessAction = createAction(
    ActionTypes.GET_FEEDBACK_SUCCESS,
    props<{ feedback: IFeedback }>()
);
