import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { interval, Observable, Subject, takeUntil } from 'rxjs';
import { IEntityName } from 'src/app/shared/modules/polling/types/entityName.interface';
import { IBackendErrors } from 'src/app/shared/types/backendErrors.interface';
import { DashboardService } from '../../services/dashboard.service';
import { getB2bKPIAction } from '../../store/actions/getB2bKpi.action';
import { getB2bStatusAction } from '../../store/actions/getB2bStatus.action';
import { getB2bStatusListAction } from '../../store/actions/getB2bStatusList.action';
import { getSummaryAction } from '../../store/actions/getSummaryData.action';
import { removeTeamMemberAction } from '../../store/actions/removeTeamMember.action';

import {
    errorSelector,
    b2bStatusSelector,
    b2bStatusListSelector,
    b2bKPIInfoSelector,
    b2bSpecializationsSelector,
    isLoadingSelector,
} from '../../store/selectors';
import { IB2BKpiInfo } from '../../types/b2bKpiInfo.interface';
import { IB2bStatus } from '../../types/b2bStatus.enum';
import { IManagerStatusInfo } from '../../types/managerStatusInfo';
import { IStatusList } from '../../types/statusList.interface';
import { getB2BSpecializationsAction } from '../../store/actions/getB2BSpecializations.action';
import { B2B_REFRESH_STATUS } from 'src/app/shared/constants/intervals';

@Component({
    selector: 'app-b2b-dashboard',
    templateUrl: './b2b-dashboard.component.html',
    styleUrls: ['./b2b-dashboard.component.css'],
})
export class B2bDashboardComponent implements OnInit, OnDestroy {
    @ViewChild('closeButton', { static: false }) private closeButton: ElementRef | undefined;
    status: IManagerStatusInfo;
    errors$: Observable<IBackendErrors | null>;

    private readonly destroy$ = new Subject();
    private readonly stopRefresh$ = new Subject();
    statusList: IStatusList[];
    showStatusList = false;
    invitedList: { 0: IStatusList[]; 1: IStatusList[]; 2: IStatusList[] };
    b2bStatus = IB2bStatus;
    kpiInfo: IB2BKpiInfo;
    b2bSpecificationIdSubject = new Subject<number>();
    specNames: IEntityName[];
    b2bSpecificationId: number;
    isOpenFilterTooltip = false;
    isOpenHighestAndLowestPromptsTooltip = false;
    isOpenSummaryTooltip = false;
    isOpenKpiTooltip = false;
    isOpenStrengthsWeaknessesTooltip = false;
    isLoading$: Observable<unknown>;
    refreshStatusStarted = false;

    constructor(
        private store: Store,
        private service: DashboardService,
        private modalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.b2bSpecificationIdSubject.pipe(takeUntil(this.destroy$)).subscribe((id: number) => {
            this.service.b2bSpecializationId = id;
            this.service.b2bSpecializationIdChanged.next(true);
            if (this.status) {
                this.handleStatus();
            }
        });

        this.isLoading$ = this.store.pipe(select(isLoadingSelector));

        this.store.pipe(takeUntil(this.destroy$), select(b2bStatusSelector)).subscribe((status) => {
            if (status) {
                this.status = status;
                this.handleStatus();
            }
        });
        this.store
            .pipe(takeUntil(this.destroy$), select(b2bStatusListSelector))
            .subscribe((list) => {
                this.handleStatusList(list);
            });
        this.store.pipe(takeUntil(this.destroy$), select(b2bKPIInfoSelector)).subscribe((info) => {
            this.kpiInfo = info;
        });
        this.store.dispatch(getB2bStatusAction());
        this.service.subject.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.closeButton.nativeElement.click();
            this.store.dispatch(getB2bStatusAction());
        });
        this.store
            .pipe(takeUntil(this.destroy$), select(b2bSpecializationsSelector))
            .subscribe((list) => {
                this.specNames = list;
            });
        this.errors$ = this.store.pipe(select(errorSelector));
    }

    handleStatus() {
        if (this.status?.isReady) {
            this.loadData();
        }
        this.store.dispatch(getB2BSpecializationsAction());
        this.store.dispatch(getB2bStatusListAction());
        this.store.dispatch(getB2bKPIAction());
        if (
            this.status?.membersCount &&
            this.status?.membersCount !== this.status?.completedCount
        ) {
            if (!this.refreshStatusStarted) {
                this.refreshStatusStarted = true;
                {
                    interval(B2B_REFRESH_STATUS)
                        .pipe(takeUntil(this.stopRefresh$))
                        .subscribe(() => this.store.dispatch(getB2bStatusAction()));
                }
            }
        } else {
            this.refreshStatusStarted = false;
            this.stopRefresh$.next(true);
        }
    }

    loadData(): void {
        this.store.dispatch(getSummaryAction({ isB2b: true }));
    }

    handleStatusList(list: IStatusList[]) {
        const invitedList = {
            [IB2bStatus.notStarted]: [],
            [IB2bStatus.started]: [],
            [IB2bStatus.completed]: [],
        };
        list?.forEach((status) => {
            invitedList[status?.status]?.push(status);
        });
        this.invitedList = invitedList;
    }

    scrollAnchor(id: string): void {
        document.getElementById(id)?.scrollIntoView();
    }

    showDeleteMemberDialog(content, id: number): void {
        this.modalService.open(content, { centered: true }).result.then(
            (result) => {
                this.b2bSpecificationId = null;
                this.service.b2bSpecializationId = null;
                this.store.dispatch(removeTeamMemberAction({ id }));
            },
            (reason) => {}
        );
    }

    changeSpecialization() {
        this.b2bSpecificationIdSubject.next(this.b2bSpecificationId);
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
        this.stopRefresh$.next(true);
        this.stopRefresh$.complete();
    }
}
