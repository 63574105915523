import { createAction, props } from '@ngrx/store';

import { ISkillMatrixCompetencyMark } from '../../types/skillMatrixCompetency.interface';
import { ActionTypes } from '../actionTypes';

export const getSkillMatrixAction = createAction(
    ActionTypes.GET_SKILLMATRIX
);

export const getSkillMatrixSuccessAction = createAction(
    ActionTypes.GET_SKILLMATRIX_SUCCESS,
    props<{ marks: ISkillMatrixCompetencyMark[] | null }>()
);
