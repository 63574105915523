<div class="container-fluid ">
    <div class="container thanks-page">
        <div class="row">
            <div class="thanks-page__item style-item d-flex flex-lg-nowrap flex-sm-wrap justify-md-between justify-sm-center text-lg-start text-sm-center align-items-center">
                <div class="col-lg-4 col-sm-12">
                    <img src="/assets/images/svg/thanks-page.svg"
                             alt="icon-thanks">
                </div>
                <div class="col-lg-8 col-sm-12">
                    <h1 class="main-title fw-bold">{{ 'Thanks.Header' | translate }}</h1>
                    <p class="thanks-page__description">{{ 'Thanks.Message1' | translate:nameParam }}</p>
                    <p class="thanks-page__description" [innerHtml]="'Thanks.Message2' | translate:nameParam"></p>
                </div>
            </div>
        </div>
    </div>
</div>
