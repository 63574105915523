<div class="error-block">
    <div class="container">
        <div class="error-block__item d-flex align-items-center flex-wrap style-item justify-content-center">
            <div
                 class="error-block__img d-flex justify-content-lg-start justify-content-sm-center col-md-4 col-sm-12 mb-lg-0  mb-3 p-0 ">
                <img src="/assets/images/noun-server.webp"
                     alt="">
            </div>
            <div class="error-block__description col-lg-6 col-md-7 col-sm-12 p-0">
                <h1 class="main-title fw-bold p-0">{{ 'Errors.Global.Header' | translate }}</h1>
                <p class="title-description fw-semibold p-0">{{ 'Errors.Global.Message' | translate }}</p>
                <div
                     class="main-banner__wrap-btn d-flex justify-content-md-start  justify-content-center col-lg- col-md-12">
                    <button class="btn btn-lg btn-main"
                            role="button"
                            (click)="refresh()">{{ 'Errors.Global.TryAgain' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
