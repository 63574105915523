export enum ActionTypes {
    GET_STATUS = '[Status] Get status',
    GET_STATUS_SUCCESS = '[Status] Get status success',
    GET_B2B_STATUS = '[B2BStatus] Get b2bStatus',
    GET_B2B_STATUS_SUCCESS = '[B2BStatus] Get b2bStatus success',
    GET_B2B_STATUS_LIST = '[B2BStatusList] Get b2bStatusList',
    GET_B2B_STATUS_LIST_SUCCESS = '[B2BStatusList] Get b2bStatusList success',
    GET_B2B_STRENGTH_OR_WEAKNESS = '[B2B_STRENGTH_OR_WEAKNESS] Get B2B_STRENGTH_OR_WEAKNESS',
    GET_B2B_STRENGTH_OR_WEAKNESS_SUCCESS = '[B2B_STRENGTH_OR_WEAKNESS] Get B2B_STRENGTH_OR_WEAKNESS success',

    GET_B2B_KPI = '[B2BKPI] Get b2bKPI',
    GET_B2B_KPI_SUCCESS = '[B2BKPI] Get b2bKPI success',

    GET_SPECIALIZATIONS = '[Specialization] Get specializations',
    GET_SPECIALIZATIONS_SUCCESS = '[Specialization] Get specializations success',
    GET_B2B_SPECIALIZATIONS = '[B2BSpecialization] Get specializations',
    GET_B2B_SPECIALIZATIONS_SUCCESS = '[B2BSpecialization] Get specializations success',

    GET_COLLEAGUES = '[Colleague] Get coleagues',
    GET_COLLEAGUES_SUCCESS = '[Colleague] Get coleagues success',

    CREATE_POLLING = '[Polling] Create polling',
    CREATE_POLLING_SUCCESS = '[Polling] Create polling success',

    ADD_COLLEAGUES = '[Colleague] Add colleagues',
    ADD_COLLEAGUES_SUCCESS = '[Colleague] Add colleagues success',

    GET_SUMMARY = '[Dashboard] Get summary data',
    GET_SUMMARY_SUCCESS = '[Dashboard] Get summary data success',

    GET_SKILLMATRIX = '[Dashboard] Get skill matrix data',
    GET_SKILLMATRIX_SUCCESS = '[Dashboard] Get skill matrix data success',

    GET_BLINDSPOTS = '[Dashboard] Get blind spots analysis data',
    GET_BLINDSPOTS_SUCCESS = '[Dashboard] Get blind spots analysis data success',

    GET_ROLES = '[Role] Get roles',
    GET_ROLES_SUCCESS = '[Role] Get roles success',

    GET_SKILLGAPS = '[Dashboard] Get skill gaps analysis data',
    GET_SKILLGAPS_SUCCESS = '[Dashboard] Get skill gaps analysis data success',

    GET_FEEDBACK = '[Dashboard] Get feedback data',
    GET_FEEDBACK_SUCCESS = '[Dashboard] Get feedback data success',

    ADD_TEAM_MEMBER = '[TEAM] Add team member',
    ADD_TEAM_MEMBER_SUCCESS = '[TEAM] Add team member success',

    REMOVE_TEAM_MEMBER = '[TEAM] remove team member',
    remove_TEAM_MEMBER_SUCCESS = '[TEAM] remove team member success',

    CREATE_TEAM_MEMBER = '[TEAM] Create team member',
    CREATE_TEAM_MEMBER_SUCCESS = '[TEAM] Create team member success',

    GET_TEAM_MEMBERS = '[TEAM_MEMBERS] Create team member',
    GET_TEAM_MEMBER_SUCCESS = '[TEAM_MEMBERS] Create team member success',
    GET_TEAM_MEMBERS_CURRENT_LIST = '[TEAM_MEMBERS_CURRENT_LIST] Create team member',
    GET_TEAM_MEMBERS_CURRENT_LIST_SUCCESS = '[TEAM_MEMBERS_CURRENT_LIST] Create team member success',
}
