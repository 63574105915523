import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { DashboardService } from '../../services/dashboard.service';
import { getSkillGapsAction, getSkillGapsSuccessAction } from '../actions/getSkillGapsData.action';
import { ISkillGapsDimentionMark } from '../../types/skillGapsDimention.interface';
import { ErrorHandlerService } from 'src/app/core/services/errorHandler.service';
import { appErrorAction } from 'src/app/store/actions/sync.action';

@Injectable()
export class GetSkillGapsDataEffect {
    public getSkillGapsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSkillGapsAction),
            mergeMap(({ section, roleId }) => {
                return this.dashboardService.getSkillGapsData(roleId).pipe(
                    map((data: ISkillGapsDimentionMark[]) => {
                        return getSkillGapsSuccessAction({ section, skillGaps: data });
                    }),
                    catchError((error: Error) => {
                        return of(appErrorAction({ error: this.errorHandler.covertError(error) }));
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private dashboardService: DashboardService,
        private errorHandler: ErrorHandlerService
    ) {}
}
