import { createAction, props } from '@ngrx/store';

import { ICreatePolingRequest } from '../../types/createPolingRequest.interface';
import { ActionTypes } from '../actionTypes';

export const createPollingAction = createAction(
    ActionTypes.CREATE_POLLING,
    props<{ pollingRequest: ICreatePolingRequest }>()
);

export const createPollingSuccessAction = createAction(ActionTypes.CREATE_POLLING_SUCCESS);
