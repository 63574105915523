import { createAction, props } from '@ngrx/store';

import { IEntityName } from 'src/app/shared/modules/polling/types/entityName.interface';
import { ActionTypes } from '../actionTypes';

export const getSpecilizationsAction = createAction(ActionTypes.GET_SPECIALIZATIONS);

export const getSpecilizationsSuccessAction = createAction(
    ActionTypes.GET_SPECIALIZATIONS_SUCCESS,
    props<{ names: IEntityName[] | null }>()
);
