import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { IStatusInfo } from '../types/statusInfo.interface';
import { IColleagueInfo } from '../types/colleagueInfo.interface';
import { IEntityName } from 'src/app/shared/modules/polling/types/entityName.interface';
import { ICreatePolingRequest } from '../types/createPolingRequest.interface';
import { IAddColleagueRequest } from '../types/addColleagueRequest.interface';
import { ISummaryRequest } from '../types/summaryRequest.interface';
import { ISkillMatrixCompetencyMark } from '../types/skillMatrixCompetency.interface';
import { IBlindSpotsResponse } from '../types/blindSpotsResponse.interface';
import { ISkillGapsDimentionMark } from '../types/skillGapsDimention.interface';
import { IFeedback } from 'src/app/shared/modules/polling/types/feedback.interface';
import { IAddTeamMemberRequest } from '../types/addTeamMemberRequest.interface';
import { IManagerStatusInfo } from '../types/managerStatusInfo';
import { IStatusList } from '../types/statusList.interface';
import { ITeamMember } from '../types/teamMember.interface';
import { IB2BKpiInfo } from '../types/b2bKpiInfo.interface';
import { Ib2bStrengthOrWeaknessRequest } from '../types/b2bStrengthOrWeakness.interface';
import { Ib2bStrengthOrWeaknessInfo } from '../types/b2bStrengthOrWeaknessInfo.interface';

@Injectable()
export class DashboardService {
    public subject = new Subject();
    public b2bSpecializationIdChanged = new Subject();
    public tokenValue: string;
    public b2bSpecializationId: number;

    constructor(private http: HttpClient) {}

    public getStatusInfo(): Observable<IStatusInfo> {
        let fullUrl = `${environment.apiUrl}PollingRequest/status`;
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<IStatusInfo>(fullUrl);
    }

    public getSpecilizationNames(isB2b = false): Observable<IEntityName[]> {
        let fullUrl = `${environment.apiUrl}EntityName/competencies`;
        if (isB2b) {
            fullUrl = `${environment.apiUrl}B2BDashboard/specializations`;
        }
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<IEntityName[]>(fullUrl);
    }

    public getColleagues(): Observable<IColleagueInfo[]> {
        let fullUrl = `${environment.apiUrl}PollingRequest/colleagues`;
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<IColleagueInfo[]>(fullUrl);
    }

    public createPollingRequest(request: ICreatePolingRequest): Observable<{}> {
        const fullUrl = `${environment.apiUrl}PollingRequest/create`;
        return this.http.post(fullUrl, request);
    }

    public AddColleagueRequest(request: IAddColleagueRequest): Observable<{}> {
        const fullUrl = `${environment.apiUrl}PollingRequest/add-colleagues`;
        return this.http.patch(fullUrl, request);
    }

    public getSummary(isB2b: boolean): Observable<ISummaryRequest> {
        let fullUrl = `${environment.apiUrl}Dashboard/summary`;
        if (isB2b) {
            fullUrl = `${environment.apiUrl}B2BDashboard/summary`;
            if (this.b2bSpecializationId) {
                fullUrl += `?specializationId=${this.b2bSpecializationId}`;
            }
        }
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<ISummaryRequest>(fullUrl);
    }

    public getSkillMatrixData(): Observable<ISkillMatrixCompetencyMark[]> {
        let fullUrl = `${environment.apiUrl}Dashboard/core-competencies`;
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<ISkillMatrixCompetencyMark[]>(fullUrl);
    }

    public getBlindSpotsData(): Observable<IBlindSpotsResponse> {
        let fullUrl = `${environment.apiUrl}Dashboard/blind-spots-analysis`;
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<IBlindSpotsResponse>(fullUrl);
    }

    public getRoles(): Observable<IEntityName[]> {
        let fullUrl = `${environment.apiUrl}EntityName/roles`;
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<IEntityName[]>(fullUrl);
    }

    public getSkillGapsData(roleId: number): Observable<ISkillGapsDimentionMark[]> {
        let fullUrl = `${environment.apiUrl}Dashboard/skill-gaps-analysis/${roleId}`;
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<ISkillGapsDimentionMark[]>(fullUrl);
    }

    public getFeedback(): Observable<IFeedback> {
        let fullUrl = `${environment.apiUrl}Dashboard/feedback`;
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<IFeedback>(fullUrl);
    }

    share(email: string): Observable<any> {
        const fullUrl = `${environment.apiUrl}Share/create?email=${email}`;
        return this.http.get<any>(fullUrl);
    }

    createUpdateTeamMemberRequest(
        request: IAddTeamMemberRequest | IAddTeamMemberRequest[],
        isNew: boolean
    ): Observable<{}> {
        let fullUrl = `${environment.apiUrl}TeamMembers/updateRange`;
        if (isNew) {
            fullUrl = `${environment.apiUrl}TeamMembers/createRange`;
            return this.http.post(fullUrl, request);
        }
        return this.http.patch(fullUrl, request);
    }

    getB2bStatusInfo(): Observable<IManagerStatusInfo> {
        let fullUrl = `${environment.apiUrl}Dashboard/b2b-status`;
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<IManagerStatusInfo>(fullUrl);
    }

    getB2bStatusList(): Observable<IStatusList[]> {
        let fullUrl = `${environment.apiUrl}TeamMembers/statusList`;
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<IStatusList[]>(fullUrl);
    }

    getCurrentTeamList(): Observable<ITeamMember[]> {
        let fullUrl = `${environment.apiUrl}TeamMembers/get-current-list`;
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<ITeamMember[]>(fullUrl);
    }

    removeTeamMember(memberId: number): Observable<null> {
        const fullUrl = `${environment.apiUrl}TeamMembers/delete-member?memberId=${memberId}`;
        return this.http.post<null>(fullUrl, null);
    }

    getB2bKPIInfo(): Observable<IB2BKpiInfo> {
        let fullUrl = `${environment.apiUrl}B2BDashboard/kpi`;
        if (this.b2bSpecializationId) {
            fullUrl += `?specializationId=${this.b2bSpecializationId}`;
        }
        if (this.tokenValue) {
            fullUrl += `?tokenValue=${this.tokenValue}`;
        }
        return this.http.get<IB2BKpiInfo>(fullUrl);
    }

    getStrengthsAndWeaknesses(
        request: Ib2bStrengthOrWeaknessRequest
    ): Observable<Ib2bStrengthOrWeaknessInfo[]> {
        let fullUrl = `${environment.apiUrl}B2BDashboard/strengths-weaknesses?strengthOrWeakness=${request.strengthOrWeakness}&competencyOrDimension=${request.competencyOrDimension}`;
        if (this.b2bSpecializationId) {
            fullUrl += `&specializationId=${this.b2bSpecializationId}`;
        }
        if (this.tokenValue) {
            fullUrl += `&tokenValue=${this.tokenValue}`;
        }
        return this.http.get<Ib2bStrengthOrWeaknessInfo[]>(fullUrl);
    }

    introductionVideoPlayed() {
        const fullUrl = `${environment.apiUrl}Dashboard/set-introduction-video-played-flag?isIntroductionVideoPlayed=true`;
        return this.http.post<null>(fullUrl, null);
    }
}
